<mat-tab-group [(selectedIndex)]="activeIndex" [ngClass]="customClass">
    <mat-tab
        *ngFor="let it of tabItems"
        [label]="it.tabId"
        [disabled]="disabled"
    >
        <ng-template mat-tab-label>
            <div
                class="header-cell"
                [class.active]="isCurrentTabItem(it)"
                [attr.data-tracker-id]="it.dataTrackerId"
                [dxyTooltip]="getTabItemTooltip(it)"
            >
                <span
                    class="header-cell-text"
                    [attr.data-testid]="'tab-' + it.tabId"
                >
                    {{ getTabItemText(it) }}
                </span>
                <span
                    *ngIf="showDataCount(it)"
                    class="dg5-badge header-cell-count"
                    [attr.data-testid]="'tab-count-' + it.tabId"
                >
                    {{ getDataCount(it) | numberFormat }}
                </span>
                @if (it.showNotificationBadge) {
                    <dxy-tag
                        color="danger"
                        variant="secondary"
                        iconGlyphClass="glyph-mandatory"
                    ></dxy-tag>
                }
            </div>
        </ng-template>
    </mat-tab>
</mat-tab-group>
<ng-content></ng-content>
