import {
    deserialize,
    deserializeAs,
    inheritSerialization,
    serialize,
} from 'cerialize';
import {
    BaseServiceParameter,
    BaseServiceResult,
} from '@datagalaxy/data-access';
import { ClientPublicData } from '@datagalaxy/webclient/client/domain';

@inheritSerialization(BaseServiceParameter)
export class SystemLoginParameter extends BaseServiceParameter {
    @serialize public Login?: string;
    @serialize public Password?: string;
    @serialize public LoginToken?: string;
    @serialize public BrowserInfo: any;

    constructor(login?: string, loginToken?: string) {
        super();
        this.Login = login;
        this.LoginToken = loginToken;
    }
}

@inheritSerialization(BaseServiceResult)
export class SystemLoginResult extends BaseServiceResult {
    @deserialize public LoginToken!: string;
    @deserialize public CurrentClientId!: string;
    @deserialize public OwnerClientId!: string;
    @deserialize public OwnerClientName!: string;
    @deserialize public FirstName!: string;
    @deserialize public LastName!: string;
    @deserialize public UserId!: string;
    @deserialize public UserReferenceId!: string;
    @deserialize public UserLanguageCode!: string;
    @deserialize public IsPasswordChangeNeeded!: boolean;
    @deserialize public IsAccountValidationNeeded!: boolean;
    @deserialize public IsErrorUserNotLicensed!: boolean;
    @deserialize public IsErrorUserLicenseExpired!: boolean;
    @deserialize public IsTrial!: boolean;
    @deserialize public IsErrorUserLicenseNotStarted!: boolean;
    @deserialize public LicenseStartsOn!: string;
    @deserialize public LicenseExpiresOn!: string;
    @deserialize public IsSupportUser!: boolean;
    @deserialize public Title!: string;
    @deserialize public Service!: string;
    @deserialize public Role!: string;
    @deserialize public UserEngagementLifeCycle!: string;
    @deserialize public IsInternalDataGalaxy!: boolean;
    @deserialize public AccountValidationExpirationTime!: string;
    @deserialize public Email!: string;
    /** This flag marks that the current login is an External Login (SAML, etc.) */
    @deserialize public IsExternalLogin!: boolean;
    @deserializeAs(ClientPublicData)
    public AuthorizedClients!: ClientPublicData[];
}

@inheritSerialization(BaseServiceParameter)
export class GetExternalAuthenticationMethodParameter extends BaseServiceParameter {
    @serialize public Url: string;

    constructor(url: string) {
        super();
        this.Url = url;
    }
}

@inheritSerialization(BaseServiceResult)
export class GetExternalAuthenticationMethodResult extends BaseServiceResult {
    @deserialize public IsExternal!: boolean;
    @deserialize public AuthenticationUrl!: string;
}

@inheritSerialization(BaseServiceParameter)
export class ExternalSystemLoginParameter extends BaseServiceParameter {
    @serialize public ExternalLoginToken: string;

    constructor(token: string) {
        super();
        this.ExternalLoginToken = token;
    }
}
