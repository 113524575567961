import { InjectionToken } from '@angular/core';

/** Translation configuration, with initial default values.
 * Overwritten by DXY_TRANSLATE_CONFIG provider */
export const defaultTranslationConfig: ITranslationConfig = {
    resources: ['/i18n/'],
    buildRandomKey: '',
    messageFormatPatch: {
        doReplaceShortcutAll: true,
        doLog: false,
        doCheckAllOnError: false,
    },
};

export const DXY_TRANSLATE_CONFIG = new InjectionToken<ITranslationConfig>(
    'dxy-translate-config',
    { providedIn: 'root', factory: () => defaultTranslationConfig },
);

export interface ITranslationConfig {
    resources: string[];
    buildRandomKey: string;
    messageFormatPatch?: IMessageFormatPatchConfig;
    doLog?: boolean;
    onError?: (error: unknown, cause: string | null, type: string) => void;
}

export interface IMessageFormatPatchConfig {
    doReplaceShortcutAll?: boolean;
    doLog?: boolean;
    doCheckAllOnError?: boolean;
}
