/**
 * This file should just be split into multiple domain otherwise it will just grow and grow
 */

/* eslint-disable @typescript-eslint/prefer-namespace-keyword */
export namespace ServerConstants {
    export module ContextGuid {
        export const SystemContextId = '00000000-0000-0000-0000-000000000040';
        export const ClientContextId = '00000000-0000-0000-0000-000000000010';
    }

    export module AttributeConstants {
        export const ModuleNameAllTypes = 'AllTypes';
        export const SystemTagsAttributeKey = 'Domains';
        export const BusinessDomainsAttributeKey = 'ObjectLinks_HasForDomain';
        export const SystemTagsAttributeId =
            '00000000-0000-0000-0000-000000700001';

        //#region Usage Component Attributes
        export const DisplayName = 'DisplayName';
        export const TechnicalName = 'TechnicalName';
        export const Domains = 'Domains';
        export const Description = 'Description';
        export const LongDescription = 'LongDescription';
        export const ComponentType = 'ComponentType';
        export const DataOwners = 'DataOwners';
        export const DataStewards = 'DataStewards';
        //#endregion

        // New Text Attribute Limit for Simple Text Attributes (DisplayName/TechnicalName/SchemaName/Summary/etc)
        export const AttributeTextLimit = 512;
    }

    export module Search {
        export const EntityTypeFilterKey = '_EntityType';
        export const SearchTermFilterKey = '_SearchTerm';

        /** Default sort key if none given */
        export const ScoreSortKey = '_score';
    }

    export module TypeName {
        export const Organization = 'Organization';
        export const Project = 'Project';
        export const Model = 'Model';
        export const DataProcessing = 'DataProcessing';
        export const SoftwareElement = 'SoftwareElement';
        export const Column = 'Column';
        export const Table = 'Table';
        // export const AnnotationElement = "AnnotationElement";
        // export const ForeignKeyElement = "ForeignKeyElement";
        // export const TableElement = "TableElement";
        export const Property = 'Property';
        export const PrimaryKey = 'PrimaryKey';
        export const ForeignKey = 'ForeignKey';
        //export const DataTypeMapping = 'DataTypeMapping';
        //export const Organization = ServerType[ServerType.Organization];
        //export const Project = ServerType[ServerType.Project];
        //export const Model = ServerType[ServerType.Model];
        //export const Glossary = ServerType[ServerType.Glossary];
        //export const DataProcessingContainer = ServerType[ServerType.DataProcessingContainer];
        //export const Column = ServerType[ServerType.Column];
        //export const Table = ServerType[ServerType.Table];
        //export const AnnotationElement = ServerType[ServerType.AnnotationElement];
        //export const ForeignKeyElement = ServerType[ServerType.ForeignKeyElement];
        //export const TableElement = ServerType[ServerType.TableElement];
        //export const Property = ServerType[ServerType.Property];
        //export const PrimaryKey = ServerType[ServerType.PrimaryKey];
        //export const ForeignKey = ServerType[ServerType.ForeignKey];
        //export const ModelDiagram = ServerType[ServerType.ModelDiagram];
        export const ObjectSocialContainer = 'ObjectSocialContainer';
        export const ObjectCommentary = 'ObjectCommentary';
        export const ObjectTask = 'ObjectTask';
        export const UserSocialContainer = 'UserSocialContainer';
        export const ObjectUserOpinion = 'ObjectUserOpinion';
        export const Screen = 'Screen';
    }
    export module PropertyName {
        export const DisplayName = 'DisplayName';
        export const TechnicalName = 'TechnicalName';
        export const Description = 'Description';
        export const EntityStatus = 'EntityStatus';
        export const Domains = 'Domains';
        export const DataQuality = 'QualityStatus';
        export const Technology = 'TechnologyCode';
        export const Urn = 'Urn';
        export const Module = 'Module';
        export const IsMandatory = 'IsMandatory';
        export const IsPrimaryKey = 'IsPrimaryKey';
        export const IsForeignKey = 'IsForeignKey';
        export const IsForeignKeyMandatory = 'IsForeignKeyMandatory';
        export const IsFunctionalOnly = 'IsFunctionalOnly';
        export const IsActive = 'IsActive';
        export const Columns = 'Columns';
        export const LegacySubTypeAttributeKey = 'Type';
        export const IsNameSyncEnabled = 'IsNameSyncEnabled';
        export const Precision = 'Precision';
        export const Size = 'Size';
        export const DataTypeRef = 'DataTypeRef';
        export const Title = 'Title';
        export const IsLiked = 'IsLiked';
        export const Commentaries = 'Commentaries';
        export const Tasks = 'Tasks';
        export const TaskAssignedToUser = 'AssignedToUserId';
        export const TaskStatus = 'Status';
        export const TaskResponse = 'Response';
        export const TaskType = 'TaskType';
        export const Layout = 'Layout';
        export const GdprIsPersonalData = 'GdprIsPersonalData';
        export const GdprIsSensitiveData = 'GdprIsSensitiveData';
        export const GdprContainsPersonalData = 'GdprContainsPersonalData';
        export const GdprContainsSensitiveData = 'GdprContainsSensitiveData';
        export const Code = 'Code';
        export const ObjectLinkHasInput = 'ObjectLinks_HasInput';
        export const ObjectLinkHasOutput = 'ObjectLinks_HasOutput';
        export const DpiType = 'ItemType';
        export const PersonalDataClass = 'PersonalDataClass';
        export const ExternalTechnologyType = 'ExternalTechnologyType';
        export const SchemaName = 'SchemaName';
        export const PrimaryKeyTechnicalName = 'PrimaryKeyTechnicalName';
        export const GdprMainPurpose = 'GdprMainPurpose';
        export const GdprSecondaryPurpose = 'GdprSecondaryPurpose';
        export const GdprUsageDuration = 'GdprUsageDuration';
        export const GdprRiskLevel = 'GdprRiskLevel';
        export const GdprPersonalDataType = 'GdprPersonalDataType';
        export const GdprPhysicalPersonType = 'GdprPhysicalPersonType';
        export const GdprSensitiveDataType = 'GdprSensitiveDataType';
        export const LocalSynonymsNames = 'LocalSynonymsNames';

        // Used for the Attribute-tree exception of Table/Field (duplicate of AttributeKey)
        export const TechnicalCommentsRaw = 'TechnicalCommentsRaw';

        /**
         *  Logical/Physical Hierarchy-related attributes
         * */

        /**
         *  Used as the Descendant Filter (to load any data that has specific id(s) as Parent
         * */
        export const Parents = 'Parents';

        /**
         *  Used mainly when in treeview mode to specify the local parent when loading a tree node's children
         * */
        export const LogicalParentId = 'LogicalParentId';

        /**
         *  Used as the Parent attribute when in a object details mode (when the user selects the parent), or as
         *  the attribute when selecting the parent in the Entity Creation Modal / Move Modal
         * */
        export const LogicalParentData = 'LogicalParentData';

        export const LogicalPath = 'LogicalPath';
        export const LogicalPathString = 'LogicalPathString';
        export const LogicalChildrenCount = 'LogicalChildrenCount';
        export const LogicalAllLevelChildrenCount =
            'LogicalAllLevelChildrenCount';

        /**
         *  Special Attribute used in order to add a specific Direct Parent Filter (as the LogicalParentId is used in
         *
         * */
        export const FilterDirectParentId = 'FilterDirectParentId';

        /**
         *  Special Attribute used in order to add a specific Parent filter : Searching on this attribute will return the parents of given objects Ids
         *
         * */
        export const FilterDescendentId = 'FilterDescendentId';

        export const DataFillPercentage = 'DataFillPercentage';
        export const RecommendedDataFillPercentage =
            'RecommendedDataFillPercentage';

        export const IsGdpr = 'IsGdpr';

        // Only Exists to enable Filtering on Raw part of LongDescription
        export const LongDescriptionRaw = 'LongDescriptionRaw';

        export const DataOwners = 'DataOwners';
        export const DataOwnersObjectValues = 'DataOwnersObjectValues';
        export const DataStewards = 'DataStewards';
        export const CdoUsers = 'CdoUsers';
        export const CisoUsers = 'CisoUsers';
        export const DpoUsers = 'DpoUsers';
        export const ExpertUsers = 'ExpertUsers';

        export const CreationTime = 'CreationTime';
        export const LastModificationTime = 'LastModificationTime';

        export const EntityLinkCount = 'EntityLinkCount';

        export const Type = 'Type';
        export const FullDisplayName = 'FullDisplayName';
        export const FullTechnicalName = 'FullTechnicalName';
        export const LinkShortcutDimensionIds = 'LinkShortcutDimensionIds';
        export const LinkShortcutDomainIds = 'LinkShortcutDomainIds';
        export const ImplementationLinkCount = 'ImplementationLinkCount';
        export const LinkShortcutReferenceDataId =
            'LinkShortcutReferenceDataId';
        export const LinkShortcutUniverseId = 'LinkShortcutUniverseId';
        export const LinkShortcutUsageIds = 'LinkShortcutUsageIds';
        export const LinkShortcutRecordingSystemIds =
            'LinkShortcutRecordingSystemIds';
        export const EntityType = 'EntityType';
        export const TableDisplayName = 'TableDisplayName';
        export const TableTechnicalName = 'TableTechnicalName';
        export const DataTypeDisplayName = 'DataTypeDisplayName';
        export const SoftwareTotalLinkCount = 'SoftwareTotalLinkCount';
        export const EntityDiagramCount = 'EntityDiagramCount';
        export const TableColumnCount = 'TableColumnCount';
        export const ContainerContainerCount = 'ContainerContainerCount';
        export const ContainerTableCount = 'ContainerTableCount';
        export const ModelPrimaryKeyCount = 'ModelPrimaryKeyCount';
        export const ModelForeignKeyCount = 'ModelForeignKeyCount';
        export const CreationUserId = 'CreationUserId';
        export const LastModificationUserId = 'LastModificationUserId';
        export const LogicalPathParentIds = 'LogicalPathParentIds';
        export const Order = 'Order';
        export const PkOrder = 'PkOrder';
        export const DataTypeIsPrecisionRequired =
            'DataTypeIsPrecisionRequired';
        export const SizeString = 'SizeString';
        export const DataTypeIsSizeRequired = 'DataTypeIsSizeRequired';
        export const ModelColumnCount = 'ModelColumnCount';
        export const ModelDisplayName = 'ModelDisplayName';
        export const TableDescription = 'TableDescription';
        export const TableType = 'TableType';
        export const DataStewardsObjectValues = 'DataStewardsObjectValues';
        export const DomainsObjectValues = 'DomainsObjectValues';
        export const AllLinkedData = 'AllLinkedData';
        export const IsWatchedByCurrentUser = 'IsWatchedByCurrentUser';
    }
    export module ScreenCategory {
        export const HeaderSpecialKey = 'DG_SystemHeaderCategory';
        export const SynonymsSpecialKey = 'DG_SystemSynonymsCategory';
    }
    export module Diagram {
        export const PublishingStatus = 'PublishingStatus';
        export const VisualData = 'VisualData';
        export const DiagramKind = 'DiagramKind';
        /** For output to backend only, for diagram creation */
        export const SourceIds = 'SourceIds';
        /** List of reference id of entities used in a diagram.
         * Note: When used in IncludedAttributesFilter, the back-end will also search for diagrams that depends on the searched entities that are a catalog source */
        export const DiagramEntityIds = 'DiagramEntityIds';
        /** For output to backend, to be used in IncludedAttributesFilter. For input from backend, use ObjectLinks_DiagramHasSource_HData */
        export const ObjectLinks_DiagramHasSource =
            'ObjectLinks_DiagramHasSource';
        /** From backend only (response for ObjectLinks_DiagramHasSource). Format is IHierarchicalData[] */
        export const ObjectLinks_DiagramHasSource_HData =
            'ObjectLinks_DiagramHasSource_HData';
    }
}
