import {
    BaseMasterData,
    IHas$type,
    IHasReferenceId,
    IIsData,
    ITypeMetadata,
} from '../master-data/master-data';
import { deserializeAs, inheritSerialization } from 'cerialize';
import { ServerType } from '@datagalaxy/dg-object-model';
import { CoreUtil } from '@datagalaxy/core-util';
import { CDataType, CDataTypeRef, DataTypeRef, IDataType } from '../data-type';
import { CModelRef } from '../model';
import { asRefs, CRefData, IRef, subRef } from '../ref';

/** Configuration of DDL data types for a specific model */
export interface IModelSettings extends IHasReferenceId {
    /** Custom data types used only in this model */
    ModelTypes: IRef<IDataType>[];

    /** System-wide or client-company data types that are excluded in this configuration */
    ExcludedSystemTypes: IRef<IDataType>[];
}

@inheritSerialization(BaseMasterData)
export class ModelSettings extends BaseMasterData implements IModelSettings {
    static readonly tmd: ITypeMetadata = {
        parentPropertyName: 'SettingsRef',
        childPropertyName: 'ModelRef',
    };
    static readonly keys: (keyof IModelSettings)[] = [
        'ModelTypes',
        'ExcludedSystemTypes',
    ];

    @deserializeAs(DataTypeRef.fromIds, 'ModelTypeIds')
    public ModelTypes: DataTypeRef[] = [];

    @deserializeAs(DataTypeRef.fromIds, 'ExcludedSystemTypeIds')
    public ExcludedSystemTypes: DataTypeRef[] = [];
    //#endregion

    public readonly ServerType = ServerType.ModelSettings;

    static from(o: IModelSettings & Partial<IHas$type>) {
        return CoreUtil.buildPartial(
            ModelSettings,
            o,
            ModelSettings.keys,
            o?.ReferenceId,
            o?.$type,
        );
    }

    constructor(id?: string, $type?: string) {
        super(id, $type);
    }
}

/** LoadDataResult ModelSettings legacy generic format adapter */
@inheritSerialization(CRefData)
export class CModelSettings
    extends CRefData<ModelSettings>
    implements IModelSettings, IIsData
{
    readonly ctorType = ModelSettings;
    readonly serverType = ServerType.ModelSettings;
    @deserializeAs(subRef(CModelRef), 'ModelRef') private cModelRef!: CModelRef;
    @deserializeAs(subRef(CDataType), 'ModelTypes')
    private cModelTypes!: CDataType[];
    @deserializeAs(subRef(CDataTypeRef), 'ExcludedSystemTypes')
    private cExcludedSystemTypeRefs!: CDataTypeRef[];

    //#region IModelSettings
    get ModelRef() {
        return this.cModelRef?.asRef();
    }

    get ModelTypes() {
        return asRefs(this.cModelTypes);
    }

    get ExcludedSystemTypes() {
        return asRefs(this.cExcludedSystemTypeRefs);
    }

    //#endregion
    asData() {
        return ModelSettings.from(this);
    }

    protected getProducers() {
        return [...this.cModelTypes];
    }
}
