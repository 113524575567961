/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import {
    SystemLoginParameter,
    SystemLoginResult,
} from '@datagalaxy/webclient/auth/data-access';
import { ClientLoginResult } from '@datagalaxy/webclient/client/data-access';
import {
    CommunicationSetting,
    CurrentUserService,
} from '@datagalaxy/webclient/user/data-access';
import { ViewType } from '../shared/util/app-types/ViewType';
import { IWidgetConfigDb } from '../widgets-dashboard/WidgetUtil';
import { getContextId, getReferenceId } from '@datagalaxy/utils';
import { IClientUISettings } from '../shared/util/app-types/login-data.types';
import { CollectionsHelper } from '@datagalaxy/core-util';
import { AppConfigService } from '@datagalaxy/webclient/config';
import { LicenseLevel } from '@datagalaxy/webclient/license/domain';
import { ClientPublicData } from '@datagalaxy/webclient/client/domain';

@Injectable()
export class AuthV2TempAppDataService {
    public get isLoginDataLoaded(): boolean {
        return true;
    }
    public get isAuthenticated(): boolean {
        return true;
    }
    public get isSystemAuthenticated(): boolean {
        return true;
    }
    public get isLoginFailed(): boolean {
        return false;
    }
    public get clientId(): string {
        return this.clientInfo?.ClientId;
    }
    public get clientName(): string {
        return this.clientInfo?.FriendlyName;
    }
    public get clientDescription(): string {
        return this.clientInfo?.Description;
    }
    public get clientDefaultViewType() {
        return ViewType[this.clientUiSettings?.defaultViewType];
    }

    public viewType: ViewType;

    public get clientGoldenSettingsUserLocalId(): string {
        return this.clientUiSettings?.goldenSettingsUserLocalId;
    }
    public get clientWidgetConfigs(): IWidgetConfigDb[] {
        return this.clientUiSettings?.widgets;
    }
    public get hasAvailableClients(): boolean {
        return !!this.userInfo?.AuthorizedClients?.length;
    }
    public get currentUserId(): string {
        return this.userInfo.UserId;
    }
    public get currentUserReferenceId() {
        return getReferenceId(this.s.clientId, this.userInfo.UserId);
    }

    public get currentUserInitials() {
        return this.userInfo.FirstName?.[0] + this.userInfo.LastName?.[0];
    }

    public get currentUserFirstName(): string {
        return this.userInfo.FirstName;
    }
    public get currentUserLastName(): string {
        return this.userInfo.LastName;
    }
    public get currentUserFullName(): string {
        return this.userInfo.FullName;
    }
    public get isSupportUser(): boolean {
        return this.userInfo.IsSupportUser;
    }
    public get currentUserEmail(): string {
        return this.userInfo.Email;
    }
    public get userSessionTimeoutMinutes(): number {
        return this.clientInfo?.UserSessionTimeoutMinutes;
    }
    public get currentUserLogin(): string {
        return this.userInfo.Email;
    }
    public get currentUserService(): string {
        return this.userInfo?.Service;
    }
    public get currentUserTitle(): string {
        return this.userInfo?.Title;
    }
    public get currentUserRole(): string {
        return this.userInfo?.Role;
    }
    public get currentUserLicenseLevelString(): string {
        return this.userInfo?.SecurityData?.LicenseLevel;
    }
    public get currentUserLicenseLevel() {
        const ll = this.userInfo?.SecurityData?.LicenseLevel;
        if (!ll) {
            return LicenseLevel.Reader;
        }
        return LicenseLevel[this.userInfo.SecurityData.LicenseLevel];
    }
    public get currentUserLicenseStartsOn(): string {
        return this.userInfo.LicenseStartsOn;
    }
    public get currentUserLicenseExpiresOn(): string {
        return this.userInfo.LicenseExpiresOn;
    }
    public get currentUserIsSupport(): boolean {
        return this.userInfo.IsSupportUser;
    }
    public get currentUserFirstConnectionOn(): string {
        return this.clientInfo.FirstConnectionTime;
    }
    public get currentUserLastConnectionOn(): string {
        return this.clientInfo.LastConnectionTime;
    }
    public get defaultSpaceUid(): string {
        return this.clientInfo.DefaultSpaceUid;
    }
    public get userSessionId(): string {
        return '';
    }
    public get accessToken(): string {
        return '';
    }
    public get hasUserSessionId(): boolean {
        return true;
    }
    public get useAutoLogin(): boolean {
        return false;
    }
    public get canCreateEntities(): boolean {
        return this.userInfo.SecurityData.CanCreateEntities;
    }
    public get canImportEntities(): boolean {
        return this.userInfo.SecurityData.CanImportEntities;
    }
    public get isTrialClient(): boolean {
        return this.userInfo.IsTrial;
    }
    public get isLoginInProgress(): boolean {
        return false;
    }

    private get clientInfo() {
        return this.s.clientInfo;
    }
    private get userInfo() {
        return this.s.userInfo;
    }

    private get clientUiSettings(): IClientUISettings | undefined {
        if (!this.clientInfo?.UiSettingsJson) {
            return undefined;
        }
        return JSON.parse(this.clientInfo.UiSettingsJson) as IClientUISettings;
    }

    constructor(
        private s: CurrentUserService,
        private appConfigService: AppConfigService,
    ) {}

    public init(_: boolean): void {
        this.viewType = this.clientDefaultViewType ?? ViewType.Technical;
    }
    public setCurrentUserInfo(firstName: string, lastName: string): void {
        this.s.rename({ firstName, lastName });
    }
    /** returns undefined if DefaultSpaceUid is not defined */
    public getLoginDataDefaultSpaceReferenceId() {
        const defaultSpaceUid = this.defaultSpaceUid;
        return defaultSpaceUid
            ? getReferenceId(defaultSpaceUid, defaultSpaceUid)
            : undefined;
    }
    public getDefaultSpaceReferenceId() {
        const defaultSpaceUid = this.defaultSpaceUid;
        return (
            defaultSpaceUid && getReferenceId(defaultSpaceUid, defaultSpaceUid)
        );
    }
    public setDefaultSpaceByReferenceId(refId: string) {
        this.s.updateClientInfo({
            DefaultSpaceUid: getContextId(refId),
        });
    }
    public getAllClients(predicate?: (c: ClientPublicData) => boolean) {
        const clients = this.userInfo.AuthorizedClients.map(
            (c) =>
                ({
                    ...c,
                }) as ClientPublicData,
        );
        return CollectionsHelper.filter(clients, predicate, true, true);
    }

    // Todo auth : disabled cookies
    public setFromCookieLogin(_: any): void {}
    public setLoggedout(): void {}
    public setLoginInProgress(_value: boolean): void {}
    public clearLogginFailed(): void {}
    public clearUserSessionId(): void {}
    public updateClientData(
        friendlyName: string,
        description: string,
        clientUISettingsJSON: string,
    ): void {
        this.s.updateClientInfo({
            FriendlyName: friendlyName,
            Description: description,
            UiSettingsJson: clientUISettingsJSON,
        });
    }
    public setUserSessionTimeoutMinutes(value: number): void {
        this.s.updateClientInfo({
            UserSessionTimeoutMinutes: value,
        });
    }

    // Todo auth realtime login
    public getRealTimeLoginData(_origin: string): {
        userFullName: string;
        userId: string;
        currentClientName: string;
        currentClientId: string;
        userSessionId: string;
        origin: string;
    } {
        throw new Error('Method not implemented.');
    }
    /** @deprecated */
    public getLoginToken(): string {
        return '';
    }

    public setSystemAuthenticated(): void {}
    public getLoginAndToken() {
        return undefined;
    }
    public initIfNotLoaded() {}
    public setUseAutoLogin(_: boolean): void {}
    public setCurrentUserLogin(_: string): void {}
    public setLoginFailed(_: boolean): void {}
    // To delete
    public hasAuthorizedClientId(_: string): boolean {
        return true;
    }
    // To delete
    public getFirstAuthorizedClientId(): string {
        return '';
    }
    public setIsClientAuthenticated(_: boolean): void {}
    public setConnectionInfos(_: string, _clr: ClientLoginResult): void {}
    public getCookieLoginData(): any {
        return undefined;
    }
    public setIsSingleWorkspaceClient(_: boolean): void {}
    public setForMonitoring(_data: {
        isClientAdmin: boolean;
        isOnlineConnectorEnabled: boolean;
        communicationSettings: CommunicationSetting[];
    }): void {
        return;
    }
    public setForSystemLoginSuccess(_slr: SystemLoginResult): void {}
    public getSystemLoginParameter(): SystemLoginParameter {
        return undefined;
    }
    public isAuthenticatedAndHasToken(): boolean {
        return false;
    }
    public getLoginDataForAnalyticsAccount(): {
        clientOfferName: string;
        clientOfferPlan: string;
        isSingleWsClient: boolean;
        category: string;
        ClientEngagementLifeCycle: string;
    } {
        return undefined;
    }
    public getLoginDataForAnalytics(): {
        privateData: {
            isAuthenticated: boolean;
            userId: string;
            currentClientName: string;
            currentClientId: string;
        };
        publicData: {
            firstname: string;
            name: string;
            fullname: string;
            email: string;
            language: string;
            title: string;
            service: string;
            role: string;
            clientid: string;
            firstconnection: string;
            lastconnection: string;
            isinternal: boolean;
            isexternal: boolean;
            numberofclient: number;
            category: string;
            Status: string;
            UserEngagementLifeCycle: string;
            ClientEngagementLifeCycle: string;
            POVStartTime: string;
            POVEndTime: string;
            TacitAgreementDate: string;
            EndOfContractNotificationDate: string;
            licenselevel: string;
            isSingleWsClient: boolean;
            groupId: string;
            isUserClientAdmin: boolean;
            isOnlineConnectorEnabled: boolean;
            clientOfferName: string;
            clientOfferPlan: string;
        };
    } {
        return undefined;
    }
    public getCommunicationSettingsObject(
        ..._communicationSettings: CommunicationSetting[]
    ): { [key: string]: boolean } {
        return undefined;
    }
    public setClientWidgets(widgets: IWidgetConfigDb[]): void {
        const settings = { ...this.clientUiSettings };
        settings.widgets = widgets;
        this.s.updateClientInfo({
            UiSettingsJson: JSON.stringify(settings),
        });
    }
    public getClientUISettingsJSON(): string {
        return this.clientInfo.UiSettingsJson;
    }

    public get debug(): boolean {
        return false;
    }
    public set debug(_: boolean) {}
}
