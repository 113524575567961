import {
    Directive,
    ElementRef,
    HostBinding,
    inject,
    input,
    OnChanges,
    OnDestroy,
    TemplateRef,
} from '@angular/core';
import { TooltipPosition } from '../tooltip/tooltip-position';
import { TooltipDestroyRef, TooltipService } from '../tooltip/tooltip.service';

@Directive({
    selector: '[dxyTooltip]',
    standalone: true,
})
export class TooltipDirective implements OnDestroy, OnChanges {
    content = input<string | TemplateRef<unknown>>('', { alias: 'dxyTooltip' });
    position = input<TooltipPosition>('above', { alias: 'dxyTooltipPosition' });
    disabled = input(false, { alias: 'dxyTooltipDisabled' });
    /**
     *  Tooltip is hidden when mouse leaves the target element, in cases where
     *  tooltip needs to be interacted with, set autoHide to false, to change
     *  the default behavior.
     */
    autoHide = input(true, { alias: 'dxyTooltipAutoHide' });

    @HostBinding('class')
    get tooltipTriggerClass() {
        return 'dxy-tooltip-trigger';
    }

    @HostBinding('class.dxy-tooltip-disabled')
    get isDisabled() {
        return this.disabled();
    }

    private elementRef = inject(ElementRef<HTMLElement>);
    private tooltipService = inject(TooltipService);

    private tooltipDestroyRef?: TooltipDestroyRef;

    ngOnChanges() {
        const element = this.elementRef.nativeElement;

        this.tooltipDestroyRef?.();

        if (this.disabled()) {
            return;
        }

        this.tooltipDestroyRef = this.tooltipService.setTooltip(
            element,
            this.content(),
            {
                position: this.position(),
                autoHide: this.autoHide(),
            },
        );
    }

    ngOnDestroy() {
        this.tooltipDestroyRef?.();
    }
}
