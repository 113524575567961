import * as moment from 'moment';
import { Moment } from 'moment';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    NgZone,
    OnInit,
    Optional,
    Output,
    Self,
    ViewChild,
} from '@angular/core';
import {
    MatLegacyInput as MatInput,
    MatLegacyInputModule,
} from '@angular/material/legacy-input';
import { FormsModule, NgControl } from '@angular/forms';
import {
    MatDatepickerModule,
    MatDatepickerToggle,
} from '@angular/material/datepicker';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { areSameMomentDateRange, IMomentDateRange } from './IMomentDateRange';
import { DateTimeUtil } from '@datagalaxy/core-util';
import { KeyboardUtil } from '@datagalaxy/utils';
import { DxyBaseFocusableFieldComponent } from '@datagalaxy/ui/fields';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import {
    EllipsisTooltipDirective,
    TooltipDirective,
} from '@datagalaxy/ui/tooltip';
import { NgIf } from '@angular/common';

/*
    NOTE: Proper change detection is ensured by
    replacing the model instead of mutating it
*/

/**
 * NOTE: time input is not implemented yet
 */
@Component({
    selector: 'dxy-field-date',
    templateUrl: 'field-date.component.html',
    styleUrls: ['field-date.component.scss'],
    providers: [
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE],
        },
    ],
    standalone: true,
    imports: [
        NgIf,
        EllipsisTooltipDirective,
        MatLegacyFormFieldModule,
        MatLegacyInputModule,
        FormsModule,
        MatDatepickerModule,
        MatIconModule,
        TranslateModule,
        TooltipDirective,
    ],
})
export class DxyFieldDateComponent
    extends DxyBaseFocusableFieldComponent<Moment | IMomentDateRange>
    implements OnInit
{
    /** Checked at init only. Language code to use in the datepicker and to format date and time */
    @Input() locale: string;
    /** Checked at init only. True to use a date range instead of a date. */
    @Input() useRange: boolean;
    /** Checked at init only. True to display and input time along with date(s) */
    @Input() withTime: boolean;
    /** Checked at init only. True to use the custom DG format - See DatetimeUtil.ts */
    @Input() useDgFormat: boolean;
    /** Checked at init only. True to show the button for clearing the date */
    @Input() isClearable: boolean;
    /** Applied to date and range */
    @Input() minDate: Moment;
    /** Applied to date and range */
    @Input() maxDate: Moment;
    /** Text to be displayed when not using range and the date is empty */
    @Input() placeholderDate: string;
    /** Text to be displayed when using range and the start date is empty */
    @Input() placeholderStart: string;
    /** Text to be displayed when using range and the end date is empty */
    @Input() placeholderEnd: string;

    /** To use when not using a date range */
    @Input() set date(value: Moment) {
        super.setValue(value);
    }
    get date() {
        return this._value as Moment;
    }
    @Output() dateChange = new EventEmitter<Moment>();

    /** To use when using a date range */
    @Input() set range(value: IMomentDateRange) {
        super.setValue(value);
    }
    get range() {
        return this._value as IMomentDateRange;
    }
    @Output() rangeChange = new EventEmitter<IMomentDateRange>();

    /** Emits when the picker panel is opened or closed, a value of *true* meaning opened */
    @Output() readonly openClose = new EventEmitter<boolean>();

    //#region API
    public get hasOpenPanel() {
        return this.picking;
    }
    public get isClearBtnVisible() {
        return this.isClearable && this.date;
    }
    //#endregion

    public get label() {
        return this.getLabel(this.translate);
    }
    public get labelTooltip() {
        return this.getLabelTooltip(this.translate);
    }
    public get errorMessage() {
        return this.getErrorMessage(this.translate);
    }
    public get valueText() {
        if (!this.useRange) {
            return this.getText(this.date);
        }
        const start = this.getText(this.start);
        const end = this.getText(this.end);
        return `${start} - ${end}`;
    }

    public get start() {
        return this.range?.start;
    }
    public set start(start: Moment) {
        this.range = { start, end: this.end };
    }
    public get end() {
        return this.range?.end;
    }
    public set end(end: Moment) {
        this.range = { start: this.start, end };
    }

    @ViewChild('fieldControl') protected fieldControl: MatInput;

    @ViewChild('dateInputRef')
    private dateInputRef: ElementRef<HTMLInputElement>;
    @ViewChild('startInputRef')
    private startInputRef: ElementRef<HTMLInputElement>;
    @ViewChild('endInputRef') private endInputRef: ElementRef<HTMLInputElement>;
    @ViewChild('pickerToggle') private pickerToggle: MatDatepickerToggle<
        Moment | IMomentDateRange
    >;

    private displayFormat: string;
    private isInitDone = false;
    private picking: boolean;

    constructor(
        private adapter: DateAdapter<Moment>,
        private translate: TranslateService,
        elementRef: ElementRef<HTMLElement>,
        ngZone: NgZone,
        @Optional() @Self() ngControl: NgControl,
    ) {
        super(elementRef, ngZone, ngControl);
    }

    ngOnInit() {
        super.ngOnInit();
        this.locale ??= moment.locale();
        this.adapter.setLocale(this.locale);

        this.displayFormat = DateTimeUtil.getFormatForDisplay(
            this.withTime,
            this.locale,
            this.useDgFormat,
        );

        if (this.useRange) {
            this.placeholderStart ??= '';
            this.placeholderEnd ??= '';
            if (!this.withTime) {
                DateTimeUtil.trimTime(this.start, true);
                DateTimeUtil.trimTime(this.end, true);
            }
        } else {
            this.placeholderDate ??= '';
            if (!this.withTime) {
                DateTimeUtil.trimTime(this.date, true);
            }
        }

        this.debug &&
            this.log('ngOnInit', {
                useRange: this.useRange,
                withTime: this.withTime,
                useDgFormat: this.useDgFormat,
                locale: this.locale,
                date: this.date,
                range: this.range,
                displayFormat: this.displayFormat,
            });

        this.isInitDone = true;
    }

    public clear(event: Event) {
        event.stopPropagation();
        if (this.useRange) {
            this.range = null;
        } else {
            this.date = null;
        }
    }

    public doFocus() {
        const ref = this.useRange ? this.startInputRef : this.dateInputRef;
        ref.nativeElement.focus();
    }
    public doBlur() {
        const ref = this.useRange ? this.startInputRef : this.dateInputRef;
        ref.nativeElement.blur();
    }

    public onPickerToggleKeyDown(event: KeyboardEvent) {
        this.picking =
            KeyboardUtil.isSpaceBarKey(event) || KeyboardUtil.isEnterKey(event);
    }

    public onPickerOpenedClosed(isOpen: boolean) {
        this.openClose.emit((this.picking = isOpen));
    }

    protected preventBlur(event: FocusEvent, isClickingInside: boolean) {
        this.log('preventBlur', isClickingInside, this.picking, event);
        if (isClickingInside || this.picking) {
            return true;
        }
        const element = event.relatedTarget as Element;
        if (element == this.pickerToggle._button._elementRef.nativeElement) {
            return true;
        }
        const classList = element?.classList;
        if (!classList) {
            return;
        }
        return (
            classList.contains('mat-datepicker-input') ||
            classList.contains('mat-calendar-body-cell')
        );
    }

    protected preventValueChange(
        newValue: Moment | IMomentDateRange,
        currentValue: Moment | IMomentDateRange,
    ) {
        return this.useRange
            ? areSameMomentDateRange(
                  newValue as IMomentDateRange,
                  currentValue as IMomentDateRange,
                  this.withTime,
              )
            : this.isSameDate(newValue as Moment, currentValue as Moment);
    }

    protected onValueChanged(value: Moment | IMomentDateRange) {
        super.onValueChanged(value);
        if (!this.isInitDone) {
            return;
        }
        this.log('onValueChange', value);
        if (this.useRange) {
            this.rangeChange.emit(value as IMomentDateRange);
        } else {
            const momentValue = value as Moment;
            this.validateDateInInterval(momentValue);
            if (this.isDateInInterval(momentValue))
                this.dateChange.emit(momentValue);
        }
    }

    private validateDateInInterval(value: Moment) {
        this.errorMessageText = '';
        if (!value) return;

        if (this.minDate?.isAfter(value)) {
            this.errorMessageText = this.translate.instant(
                'CoreUI.Date.minDateError',
                { minDate: this.getText(this.minDate) },
            );
        }
        if (this.maxDate?.isBefore(value)) {
            this.errorMessageText = this.translate.instant(
                'CoreUI.Date.maxDateError',
                { maxDate: this.getText(this.maxDate) },
            );
        }
    }

    private isDateInInterval(value: Moment) {
        return !(this.minDate?.isAfter(value) || this.maxDate?.isBefore(value));
    }

    private isSameDate(a: Moment, b: Moment) {
        return DateTimeUtil.areSameMoment(a, b, this.withTime);
    }

    private getText(m: Moment) {
        return DateTimeUtil.format(m, this.displayFormat);
    }
}

// export const DATE_FR_FORMAT = {
//   parse: {
//     dateInput: 'DD/MM/YYYY',
//   },
//   display: {
//     dateInput: 'DD/MM/YYYY',
//     monthYearLabel: 'MMMM YYYY',
//     dateA11yLabel: 'LL',
//     monthYearA11yLabel: 'MMMM YYYY',
//   },
// }
