<div *ngIf="isReadonly" class="read-only-field">
    <label [dxyEllipsisTooltip]="labelTooltip">
        {{ label }}
    </label>
    <span *ngIf="hintBeforeControl" class="mat-hint">{{ hint }}</span>
    <p *ngIf="readOnlyOption as o" class="value-container" [ngClass]="o.class">
        <ng-container *ngTemplateOutlet="o.templateRefLabel"></ng-container>
        <span
            *ngIf="o.glyphClass"
            class="glyph"
            [ngClass]="o.glyphClass"
        ></span>
        <span *ngIf="o.label"> {{ o.label }} </span>
        <span *ngIf="o.labelKey" [translate]="o.labelKey"></span>
    </p>
    <div class="read-only-separator"></div>
    <span *ngIf="!hintBeforeControl" class="mat-hint">{{ hint }}</span>
</div>
<mat-form-field
    *ngIf="!isReadonly"
    floatLabel="always"
    appearance="standard"
    [class.dg5-compact]="compact"
>
    <mat-label [dxyEllipsisTooltip]="labelTooltip">{{ label }}</mat-label>
    <span *ngIf="hintBeforeControl" class="mat-hint">{{ hint }}</span>
    <dxy-radio-field-control
        #fieldControl
        [(ngModel)]="value"
        [ngModelOptions]="modelOptionsForUpdateOn"
        [options]="options"
        [errorStateMatcher]="errorStateMatcher"
        [disabled]="disabled"
        [required]="required"
    ></dxy-radio-field-control>
    <mat-error>{{ errorMessage }}</mat-error>
</mat-form-field>
<span *ngIf="!hintBeforeControl" class="mat-hint">{{ hint }}</span>
