import { Injectable } from '@angular/core';
import { ViewType } from '../shared/util/app-types/ViewType';
import { ServerType } from '@datagalaxy/dg-object-model';
import { IHasTechnicalName } from '@datagalaxy/data-access';
import { ClientStorageService } from './clientStorage.service';
import { BehaviorSubject } from 'rxjs';
import { userSettingsValues } from '@datagalaxy/webclient/user/domain';
import { ServerConstants } from '@datagalaxy/shared/server/domain';
import { UserSettingsService } from '@datagalaxy/webclient/user/data-access';

/**
 * #Archi-service-leaf: Must not reference any app service
 */
@Injectable({ providedIn: 'root' })
export class ViewTypeService {
    private viewTypeSubject = new BehaviorSubject<ViewType>(null);

    /** current viewType */
    public get viewType(): ViewType {
        return this.viewTypeSubject.value || ViewType.Technical;
    }
    public set viewType(type: ViewType) {
        this.viewTypeSubject.next(type);
    }

    /** true if the current viewType is *Technical* */
    public get isTechnicalView() {
        return this.viewType === ViewType.Technical;
    }

    /** true if the current viewType is *Functional* */
    public get isFunctionalView() {
        return this.viewType === ViewType.Functional;
    }

    public get viewType$() {
        return this.viewTypeSubject.asObservable();
    }

    constructor(
        private userSettingsService: UserSettingsService,
        private clientStorage: ClientStorageService,
    ) {}

    public getTechnicalOrDisplayName(obj: IHasTechnicalName) {
        return (
            obj &&
            (this.isTechnicalView && obj.TechnicalName
                ? obj.TechnicalName
                : obj.DisplayName)
        );
    }

    public getViewAttributeKey() {
        return this.isTechnicalView
            ? ServerConstants.PropertyName.TechnicalName
            : ServerConstants.PropertyName.DisplayName;
    }

    public getPrimaryNameAttribute(
        serverType: ServerType,
        viewType = this.viewType,
    ) {
        return viewType == ViewType.Functional ||
            serverType == ServerType.Property
            ? ServerConstants.PropertyName.DisplayName
            : ServerConstants.PropertyName.TechnicalName;
    }

    public async loadPreference() {
        const legacyCookieKey = 'userViewType';
        const legacyCookieValue: string =
            this.clientStorage.getCookieKey(legacyCookieKey);
        const viewType = await this.fetchViewType();

        // In case the user creation is anterior to the move of viewType preference
        // from cookie to userSettingValue in DB, migrate the preference accordingly
        if (!viewType && legacyCookieValue) {
            // Remove the legacy preference property from cookie
            this.clientStorage.dropCookieKey(legacyCookieKey);
            // Move preference to userSettingValue
            void this.setViewType(ViewType[legacyCookieValue]);
            return;
        }

        // Value is different from undefined, null and 0 (ViewType.undefined)
        if (viewType) {
            this.viewType = viewType;
        }
    }

    public async setViewType(viewType: ViewType) {
        this.viewType = viewType;
        await this.userSettingsService.setUserSettingValue(
            userSettingsValues.display.category,
            userSettingsValues.display.routes.viewType,
            `${viewType}`,
        );
    }

    private async fetchViewType(): Promise<ViewType> {
        const res = await this.userSettingsService.getUserSettingValue(
            userSettingsValues.display.category,
            userSettingsValues.display.routes.viewType,
        );
        const viewType = parseInt(res.Value) as ViewType;
        return !isNaN(viewType) ? viewType : ViewType.Undefined;
    }
}
