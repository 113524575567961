import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { DxyBaseFilterComponent } from '../base-filter/base-filter.component';
import {
    IValueListFilterConfig,
    IValueListFilterData,
    ValueListFilterOperator,
} from './value-list-filter.types';
import { ValueListFilterUtils } from './value-list-filter.utils';
import { CollectionsHelper, StringUtil } from '@datagalaxy/core-util';
import {
    DxyMultiselectListComponent,
    DxyOptionItemComponent,
    IListOptionItem,
    IMultiSelectData,
} from '../../components';
import { IOptionAdapter } from '../../field-select.types';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { IListOption, ListOptionUtil } from '../../IListOption';
import { withLoading } from '../../base';
import { SpinnerComponent } from '@datagalaxy/ui/spinner';
import {
    CdkFixedSizeVirtualScroll,
    CdkVirtualForOf,
    CdkVirtualScrollViewport,
} from '@angular/cdk/scrolling';
import { SearchInputComponent } from '@datagalaxy/ui/search';
import { FormsModule } from '@angular/forms';
import { DxyFieldSelectComponent } from '../../fields/field-select/field-select.component';
import {
    EllipsisTooltipDirective,
    TooltipDirective,
} from '@datagalaxy/ui/tooltip';
import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { DxyFilterButtonComponent } from '../filter-button/filter-button.component';

/**
 * ## Role
 * Display a value list filter button
 */
@Component({
    selector: 'dxy-value-list-filter',
    templateUrl: './value-list-filter.component.html',
    styleUrls: ['./value-list-filter.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        DxyFilterButtonComponent,
        NgIf,
        EllipsisTooltipDirective,
        NgClass,
        TranslateModule,
        DxyMultiselectListComponent,
        DxyFieldSelectComponent,
        FormsModule,
        SearchInputComponent,
        CdkVirtualScrollViewport,
        CdkFixedSizeVirtualScroll,
        CdkVirtualForOf,
        DxyOptionItemComponent,
        SpinnerComponent,
        AsyncPipe,
        TooltipDirective,
    ],
})
export class DxyValueListFilterComponent<TOption>
    extends DxyBaseFilterComponent<
        IValueListFilterData<TOption>,
        ValueListFilterOperator
    >
    implements OnInit, OnChanges
{
    @Input() items: TOption[];
    @Input() adapter?: IOptionAdapter<TOption>;
    @Input() config?: IValueListFilterConfig;
    @Input() defaultIconClass?: string;
    @Input() forceSingleSelection?: boolean;
    @Input() menuHasDynamicHeight?: boolean;

    @ViewChild('customTextTemplate')
    customTextTemplate: ElementRef<HTMLElement>;
    @ViewChild(DxyMultiselectListComponent)
    multiselectList: DxyMultiselectListComponent;

    protected multiSelectData: IMultiSelectData<TOption>;
    protected options: IListOptionItem<TOption>[];
    protected menuOpen: boolean;
    protected rowHeightInPx = 40;

    protected get multiselectAdapter() {
        return this.multiSelectData.adapter;
    }

    protected get isResolved() {
        return ValueListFilterUtils.isResolved(this.filter);
    }

    protected get isValueLessOperator() {
        return ValueListFilterUtils.isValueLessOperator(this.operator);
    }

    protected get isFirstValueRestricted() {
        return this.filter?.isRestricted?.(this.firstValue);
    }

    protected get isSecondValueRestricted() {
        return this.filter?.isRestricted?.(this.secondValue);
    }

    protected get isEmptyOperator() {
        return this.operator === ValueListFilterOperator.FieldIsEmpty;
    }

    protected get hasCustomTemplate() {
        return !!this.customTextTemplate?.nativeElement?.children?.length;
    }

    protected get isStruckOutText() {
        return this.operator === ValueListFilterOperator.ListExcludes;
    }

    protected get showSingleSelect() {
        return (
            (this.operator === ValueListFilterOperator.Equals &&
                !this.noOperator) ||
            (this.forceSingleSelection && !this.noOperator)
        );
    }

    protected get showOptionSelect() {
        return (
            (this.operator === ValueListFilterOperator.Equals &&
                this.noOperator) ||
            (this.forceSingleSelection && this.noOperator)
        );
    }

    protected get scrollItemHeight() {
        const nbMaxItems = 10;
        const maxHeight = nbMaxItems * this.rowHeightInPx;
        const optionsCount = this.options?.length ?? 0;
        return optionsCount >= nbMaxItems
            ? maxHeight
            : optionsCount * this.rowHeightInPx;
    }

    protected get isMultipleValues() {
        return (
            !this.showSingleSelect &&
            !this.showOptionSelect &&
            !this.isValueLessOperator &&
            !this.forceSingleSelection
        );
    }

    protected get singleValue() {
        return this.filter.values?.[0];
    }

    protected get hasOneValue() {
        return this.filter?.values?.length === 1;
    }

    protected get hasTwoValues() {
        return this.filter?.values?.length === 2;
    }

    protected get hasMultipleValues() {
        return this.filter?.values?.length > 2;
    }

    protected get firstValueText() {
        return this.getText(this.firstValue);
    }

    protected get firstValueGlyph() {
        return this.adapter?.getGlyphClass?.(this.firstValue);
    }

    protected get secondValueText() {
        return this.getText(this.secondValue);
    }

    protected get secondValueGlyph() {
        return this.adapter?.getGlyphClass?.(this.secondValue);
    }

    protected get summaryValue() {
        return `${this.filter.values.length} ${this.translate.instant(
            'CoreUI.Filter.Operators.Global.ValuesLbl',
        )}`;
    }

    protected get orOperator() {
        return ValueListFilterOperator.ListContains;
    }

    protected get andOperator() {
        return ValueListFilterOperator.ListMatchAll;
    }

    protected get iconClass() {
        return (
            (this.filter?.values?.length &&
                this.hasOneValue &&
                this.adapter?.getGlyphClass?.(this.filter.values[0])) ||
            this.defaultIconClass ||
            'glyph-valuelist'
        );
    }

    protected get iconUrl() {
        return (
            this.filter?.values?.length &&
            this.hasOneValue &&
            this.adapter?.getIconUrl?.(this.filter.values[0])
        );
    }

    protected get valuesSeparatorKey() {
        const operator = this.operator;
        if (operator == ValueListFilterOperator.ListContains) {
            return 'CoreUI.Filter.Operators.Global.OrSeparator';
        }
        if (
            operator == ValueListFilterOperator.ListMatchAll ||
            operator == ValueListFilterOperator.ListExcludes
        ) {
            return 'CoreUI.Filter.Operators.Global.AndSeparator';
        }
        return '';
    }

    protected get isSearchEnabled() {
        return this.config?.hasSearch === undefined || this.config?.hasSearch;
    }

    private get firstValue() {
        return this.filter?.values?.[0];
    }

    private get secondValue() {
        return this.filter?.values?.[1];
    }

    constructor(
        private cd: ChangeDetectorRef,
        private translate: TranslateService,
    ) {
        super();
    }

    ngOnInit() {
        this.init();
    }

    ngOnChanges(changes: SimpleChanges) {
        super.onChanges(changes, ['items', 'adapter', 'config', 'filter'], () =>
            this.init(),
        );
    }

    //#region API
    public async triggerSearch() {
        await this.multiselectList?.triggerSearch();
    }

    public refreshUi() {
        this.multiselectList?.refreshUi();
    }

    //#endregion API

    protected onOpenCloseMenu(isOpen: boolean) {
        this.onOpenClose.emit(isOpen);
        this.menuOpen = isOpen;
        this.cd.detectChanges();

        this.multiselectList?.refreshUi();
    }

    protected onOperatorChange(operator: ValueListFilterOperator) {
        this.log('onOperatorChange', operator);
        this.filter.operator = operator;
        this.onFilterChange();
    }

    protected onValueChange(option: TOption) {
        this.filter.values = [option];
        this.onFilterChange();
    }

    protected async onOptionSearch(searchTerm: string) {
        const onSearch = this.config?.onSearch;

        if (onSearch) {
            await this.performOnSeach(searchTerm, onSearch);
        } else {
            this.buildOptions();
            this.options = StringUtil.filterSearched(
                searchTerm,
                this.options,
                (opt) => ListOptionUtil.getText(opt, this.translate),
            );
        }
    }

    @withLoading()
    private async performOnSeach(
        searchTerm: string,
        onSearch: (searchTerm: string) => Promise<TOption[]>,
    ) {
        this.items = await onSearch(searchTerm);
        this.buildOptions();
    }

    protected getOperatorTranslateKey(operator: ValueListFilterOperator) {
        return ValueListFilterUtils.getOperatorTranslateKey(operator);
    }

    private init() {
        if (!this.filter) {
            this.filter = {};
        }
        if (!this.operator) {
            this.operator = ValueListFilterOperator.ListContains;
        }
        this.operators ??=
            CollectionsHelper.getEnumValues<ValueListFilterOperator>(
                ValueListFilterOperator,
            ).filter(
                (o) =>
                    !this.excludedOperators?.includes(o) &&
                    o !== ValueListFilterOperator.Unknown,
            );

        this.buildMultiSelectData();
        this.buildOptions();
    }

    private buildMultiSelectData() {
        const sortOptions = this.config?.sortOptions;
        const onSearch = this.config?.onSearch;
        this.multiSelectData = {
            items: this.items,
            selectedItems: this.filter.values,
            searchParams: {
                enabled: this.config?.hasSearch,
                threshold: onSearch ? false : undefined,
            },
            hasSelectAll: this.config?.hasSelectAll,
            adapter: this.adapter,
            dataType: this.config?.dataType,
            onSearch,
            sortOptions,
            onSelectionChange: (selectedItems) => {
                this.filter.values = selectedItems;
                this.onFilterChange();
            },
        };
    }

    private buildOptions() {
        this.options = this.items?.map((item) => {
            return {
                data: item,
                glyphClass: this.multiselectAdapter?.getGlyphClass?.(item),
                iconUrl: this.multiselectAdapter?.getIconUrl?.(item),
                labelText: this.multiselectAdapter?.getText?.(item),
                labelKey: this.multiselectAdapter?.getTextKey?.(item),
                renderData: this.multiselectAdapter?.getRenderData?.(item),
                optionClass: `mat-menu-item ${
                    this.multiselectAdapter?.getClass?.(item) ?? ''
                }`,
                glyphColor: this.multiselectAdapter?.getTagColor?.(item),
                tagColor: this.multiselectAdapter?.getTagColor?.(item),
            };
        });
    }

    private getText(value: TOption) {
        const adapter = this.adapter;

        if (!adapter) {
            return value;
        }

        const getText = adapter.getText;
        if (getText) {
            return getText(value);
        }

        const getKey = adapter.getTextKey;
        if (getKey) {
            return this.translate.instant(getKey(value));
        }

        return null;
    }

    protected onOptionSelected(option: IListOption<TOption>) {
        const dataSelected = option.data;
        this.filter.values = dataSelected ? [dataSelected] : [];
        this.filterButton.menuTrigger.closeMenu();
        this.onFilterChange();
    }

    private onFilterChange() {
        this.log('onFilterChange', this.filter);

        if (this.showSingleSelect && this.filter.values.length > 1) {
            this.filter.values = this.filter.values.slice(0, 1);
            this.buildMultiSelectData();
        }

        this.cd.detectChanges();
        this.filterChange.emit(this.filter);
    }
}
