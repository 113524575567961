@if (colorSpot()) {
    <span class="tag-spot"></span>
}

@if (iconGlyphClass()) {
    <i [ngClass]="iconGlyphClass()"></i>
}

<span class="tag-text" [class.hidden]="!hasContent()" #tagText>
    <ng-content></ng-content>
</span>
