import { NgClass } from '@angular/common';
import {
    AfterContentChecked,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    HostBinding,
    input,
    signal,
    viewChild,
} from '@angular/core';
import { TagColor } from '../common/tag-color';
import { TagVariant } from '../common/tag-variant';

@Component({
    selector: 'dxy-tag',
    standalone: true,
    templateUrl: './tag.component.html',
    imports: [NgClass],
    styleUrl: './tag.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagComponent implements AfterContentChecked {
    color = input<TagColor>('default');
    variant = input<TagVariant>('secondary');
    colorSpot = input(false);
    size = input<'small' | 'medium'>('medium');
    iconGlyphClass = input<string>();

    protected hasContent = signal(false);
    private tagText = viewChild<ElementRef<HTMLElement>>('tagText');

    @HostBinding('class') get classes() {
        return `${this.color()} ${this.size()} ${this.variant()}`;
    }

    ngAfterContentChecked(): void {
        this.hasContent.set(!!this.tagText()?.nativeElement.textContent);
    }
}
