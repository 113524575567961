import { inject, Injectable, Injector } from '@angular/core';
import { BrowserInfoUtil } from '../shared/util/BrowserInfoUtil';
import { ClientContextUtil } from '../shared/util/ClientContextUtil';
import { ExceptionNotificationBuilder } from './monitoring/exception-notification-builder';
import {
    ClientErrorParameter,
    MonitoringApiService,
} from '@datagalaxy/shared/monitoring/data-access';
import { CurrentUserService } from '@datagalaxy/webclient/user/data-access';

@Injectable({ providedIn: 'root' })
export class ClientErrorService {
    private injector = inject(Injector);
    private currentUser = inject(CurrentUserService);

    /**
     * #Archi-Error-Cleanup (cbo) Clean up this method and all it's callers to really master how it is used
     * and we correctly type all the arguments (JIRA: DG-2689)
     */
    public async notify(exception: Error, cause: string, errorType: string) {
        const browserInfo = BrowserInfoUtil.getBrowserInfo();
        const clientContext = ClientContextUtil.getClientContext(this.injector);
        const exceptionNotificationBuilder = new ExceptionNotificationBuilder({
            exception,
            cause,
            errorType,
            browserInfo: browserInfo,
        });

        if (!exceptionNotificationBuilder.shouldSend) {
            return;
        }

        const clientError = new ClientErrorParameter();
        const monitoringApiService = this.injector.get(MonitoringApiService);
        clientError.ErrorData = exceptionNotificationBuilder.data;
        clientError.ClientContext = clientContext;
        clientError.UserSessionId = this.currentUser.id;
        await monitoringApiService.logError(clientError);
    }
}
