import { Injectable } from '@angular/core';
import { filter, fromEvent, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class KeyboardEventService {
    private keydown$ = fromEvent<KeyboardEvent>(document, 'keydown');

    onKey(
        key: string,
        options?: { ctrl?: boolean; shift?: boolean; alt?: boolean },
    ): Observable<KeyboardEvent> {
        return this.keydown$.pipe(
            filter(
                (event) =>
                    event.key.toLowerCase() === key.toLowerCase() &&
                    (options?.ctrl === undefined ||
                        event.ctrlKey === options.ctrl) &&
                    (options?.shift === undefined ||
                        event.shiftKey === options.shift) &&
                    (options?.alt === undefined ||
                        event.altKey === options.alt),
            ),
        );
    }

    onEscape(): Observable<KeyboardEvent> {
        return this.onKey('Escape');
    }
}
