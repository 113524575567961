import {
    APP_INITIALIZER,
    EnvironmentProviders,
    importProvidersFrom,
    makeEnvironmentProviders,
    Provider,
} from '@angular/core';
import {
    TranslateCompiler,
    TranslateLoader,
    TranslateModule,
    TranslateParser,
    TranslateService,
} from '@ngx-translate/core';
import { LANGUAGE_OPTIONS, LanguageService } from '../language';
import * as moment from 'moment';
import { HttpBackend } from '@angular/common/http';
import { DxyTranslateParser } from './translate-parser';
import { DxyTranslateMessageFormatCompiler } from './translate-message-format-compiler';
import { DXY_TRANSLATE_CONFIG, ITranslationConfig } from './translate.types';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';

export interface TranslationOptions {
    languages?: string[];
    defaultLanguage?: string;
}

function translateHttpLoaderFactory(
    http: HttpBackend,
    config: ITranslationConfig,
) {
    const hash = config.buildRandomKey ? `?v=${config.buildRandomKey}` : '';
    const resources = config.resources.map((prefix) => ({
        prefix,
        suffix: `.json${hash}`,
    }));
    return new MultiTranslateHttpLoader(http, resources);
}

const ngxTranslateProviders: (Provider | EnvironmentProviders)[] = [
    importProvidersFrom(
        TranslateModule.forRoot({
            extend: true,
        }),
    ),
    {
        provide: TranslateLoader,
        deps: [HttpBackend, DXY_TRANSLATE_CONFIG],
        useFactory: translateHttpLoaderFactory,
    },
    {
        provide: TranslateCompiler,
        useClass: DxyTranslateMessageFormatCompiler,
    },
    { provide: TranslateParser, useClass: DxyTranslateParser },
];

export function provideTranslation(options: TranslationOptions) {
    return makeEnvironmentProviders([
        {
            provide: LANGUAGE_OPTIONS,
            deps: [TranslateService],
            useFactory: (translate: TranslateService) => ({
                locales: options.languages,
                defaultLanguage: options.defaultLanguage,
                onChange: (lang: string) => {
                    translate.use(lang);
                    moment.locale(lang);
                },
            }),
        },
        {
            provide: APP_INITIALIZER,
            deps: [LanguageService],
            multi: true,
            useFactory: (languageService: LanguageService) => () =>
                languageService.init(),
        },
        ...ngxTranslateProviders,
    ]);
}
