import { Injectable } from '@angular/core';
import { CollectionsHelper, CoreUtil } from '@datagalaxy/core-util';
import { IIdr, ServerType } from '@datagalaxy/dg-object-model';
import { BaseMasterData, ITypeMetadata } from './master-data';
import {
    Model,
    SaveDataOperation,
    SaveDataParameter,
    SaveDataParamItem,
    SaveDataResult,
    SaveDataResultDeletedReferenceItem,
    SaveDataResultItem,
    SaveDataResultUpdatedPropertyItem,
    SavingPair,
    SavingPairItem,
} from '../model';
import { ModelerApiService } from '../modeler-api.service';
import { JsonTypeData } from '@datagalaxy/data-access';
import { Table } from '../table';
import { Column } from '../column';
import { ForeignKey } from '../foreign-key';
import { PrimaryKey } from '../primary-key';
import { DataTypeSettings } from '../data-type-settings';
import { ModelSettings } from '../model-settings';
import {
    IDeserializedData,
    MasterDataSerializer,
} from './master-data-serializer';
import { emptyRef, Ref } from '../ref';
import { LogService } from './log.service';

export type TRefOrRefs = Ref<BaseMasterData> | Ref<BaseMasterData>[];

/** ## Role
 * In-memory repository for modeler objects and their references:
 *  - Model content: Model, Table, Column, PrimaryKey, ForeignKey,
 *  - Data-type settings: ModelSettings, DataTypeSettings, DataType */
@Injectable({ providedIn: 'root' })
export class MasterDataService extends LogService {
    static readonly debugApplySaveResult = false;

    private readonly masterData = new Map<
        ServerType,
        Map<string, BaseMasterData>
    >();
    private readonly typeMetadata = new Map<ServerType, ITypeMetadata>([
        [ServerType.DataTypeSettings, DataTypeSettings.tmd],
        [ServerType.ModelSettings, ModelSettings.tmd],
        [ServerType.Model, Model.tmd],
        [ServerType.Table, Table.tmd],
        [ServerType.Column, Column.tmd],
        [ServerType.ForeignKey, ForeignKey.tmd],
        [ServerType.PrimaryKey, PrimaryKey.tmd],
    ]);
    private jsonTypes!: Map<ServerType, string>;

    constructor(private modelerApiService: ModelerApiService) {
        super();
        Ref.setResolver((r) => this.getMasterObject(r));
        MasterDataSerializer.onDeserialized.subscribe((data) =>
            this.processDeserialized(data),
        );
    }

    //#region init

    //called by loginService
    public clear(clearSystemData: boolean) {
        if (clearSystemData) {
            this.masterData.clear();
        } else {
            for (const map of this.masterData.values()) {
                for (const obj of map.values()) {
                    if (!obj.isSystem) {
                        map.delete(obj.ReferenceId);
                    }
                }
            }
        }
        this.log('clear-out', clearSystemData, this.masterData);
    }

    //called by loginService
    public async setJsonTypes(jsonTypes: JsonTypeData[]) {
        this.jsonTypes = new Map(
            jsonTypes?.map((d) => [
                ServerType[d.ApplicationTypeName as keyof typeof ServerType],
                d.JsonTypeName,
            ]),
        );
    }

    //#endregion - init

    //#region saveData

    //#region SaveDataParamItem

    public forDeleteDataFromPrimaryOwner(
        deletedData: BaseMasterData,
        parentIdr: IIdr,
    ) {
        const parentPropertyName =
            this.typeMetadata.get(deletedData.ServerType)?.parentPropertyName ||
            '';
        return SaveDataParamItem.forDeleteData(
            deletedData,
            parentIdr,
            parentPropertyName,
            false,
        );
    }

    public forAddNewData(parentIdr: IIdr, newData: BaseMasterData) {
        const parentPropertyName =
            this.typeMetadata.get(newData.ServerType)?.parentPropertyName || '';
        return this.forAddNewDataManualParent(
            parentIdr,
            newData,
            parentPropertyName,
        );
    }

    public forAddNewDataManualParent(
        parentIdr: IIdr,
        newData: BaseMasterData,
        parentPropertyName: string,
    ) {
        const $type = this.jsonTypes?.get(newData.ServerType) || '';
        return SaveDataParamItem.forAddNewData(
            parentIdr,
            newData,
            parentPropertyName,
            $type,
        );
    }

    //#endregion SaveDataParamItem

    public applyDeleteColumn(column: Column) {
        const sdi = this.forDeleteDataFromPrimaryOwner(column, column.TableRef);

        const sdri = new SaveDataResultItem();
        sdri.IsSuccess = true;
        sdri.DataReferenceId = emptyRef;
        sdri.ParentDataReferenceId = sdi.ParentReferenceId;
        sdri.DeletedReferences = [];

        const sdr = new SaveDataResult();
        sdr.IsSuccess = true;
        sdr.Items = [sdri];

        this.applySaveResult(
            new SavingPair(new SaveDataParameter([sdi]), sdr),
            MasterDataService.debugApplySaveResult,
        );

        return { success: true };
    }

    public async saveData(logId: string, ...sdis: SaveDataParamItem[]) {
        const sdp = new SaveDataParameter(sdis);
        if (!sdp.Items?.length) {
            return { success: false };
        }
        this.log('saveData', logId, sdp);
        const sdr = await this.modelerApiService.saveData(sdp);
        this.log('saveData-apiResult', sdr);
        if (!sdr) {
            return { success: false };
        }
        const updatedIdrs = this.applySaveResult(
            new SavingPair(sdp, sdr),
            MasterDataService.debugApplySaveResult,
        );
        return { success: sdr.IsSuccess, updatedIdrs };
    }

    private applySaveResult(sp: SavingPair, debug = false) {
        const updatedIdrs: IIdr[] = [];
        if (!sp.IsSuccess) {
            return updatedIdrs;
        }
        for (const spi of sp.getPairItems()) {
            const applied = this.applySaveResultItem(spi, updatedIdrs, debug);
            this.log(
                'applySaveResult-item',
                spi,
                SaveDataOperation[spi.dataOperation],
                applied,
            );
        }
        if (debug) {
            this.log('applySaveResult-updatedIdrs', updatedIdrs);
        }
        return updatedIdrs;
    }

    private applySaveResultItem(
        spi: SavingPairItem,
        updatedIdrs: IIdr[],
        debug = false,
    ) {
        const { sdpi, sdri, isSuccess, dataOperation: op } = spi;
        if (!isSuccess) {
            return false;
        }
        const dataIdr = sdpi.dataAsIdr();

        const SDO = SaveDataOperation;
        const saveObject = this.getMasterObjectFromIdr(dataIdr);
        if (debug) {
            this.log('applySaveResultItem', spi, SDO[op], dataIdr, saveObject);
        }

        if (!saveObject && (op == SDO.UpdateData || op == SDO.UpdateProperty)) {
            // Normal case Organization, if object is not in current application's scope
            // (for instance, updating Organization DisplayName if working on different Org)
            if (sdpi.DataTypeName != 'Organization') {
                this.warn(
                    `applySaveResult: [${SDO[op]}], Object not found (server object): [${sdpi.DataTypeName}] ${sdpi.DataReferenceId}`,
                );
            }
            return false;
        }

        if (op == SDO.AddNewData) {
            if (!this.getMasterObjectByIdOnly(sdpi.DataReferenceId)) {
                this.addMasterObject(sdpi.DataContent, debug);
            }
            this.addReferenceDataToDataModelGeneric(sdpi, debug);
        } else if (op == SDO.AddDataReference) {
            this.addReferenceDataToDataModelGeneric(sdpi, debug);
        } else if (op == SDO.DeleteData || op == SDO.DeleteDataReference) {
            if (sdri.DeletedReferences?.length) {
                //  Start by handling dependencies
                this.handleDeletedReferences(
                    sdri.DeletedReferences,
                    updatedIdrs,
                    debug,
                );
            }
            const parent = this.getMasterObjectFromIdr(sdpi.parentAsIdr());
            this.deleteReferenceDataFromParentData(
                parent,
                sdpi.ParentPropertyName,
                sdpi.DataReferenceId,
                debug,
            );

            if (op == SDO.DeleteData) {
                this.deleteMasterObject(dataIdr);
            }
        } else if (op == SDO.UpdateProperty && saveObject) {
            this.updatePropertyValue(
                saveObject,
                sdpi.PropertyName,
                sdpi.PropertyValue,
                debug,
            );
        }

        this.handleUpdatedProperties(
            sdri.UpdatedProperties,
            updatedIdrs,
            debug,
        );

        this.addReferenceIfValid(updatedIdrs, dataIdr, debug);
        this.addReferenceIfValid(updatedIdrs, sdpi.parentAsIdr(), debug);

        return true;
    }

    /** avoid (perf) */ // only for savedata. don't know if necessary (could'nt use ServerType from parameter item data ?)
    private getMasterObjectByIdOnly(referenceId: string) {
        if (!referenceId) {
            return null;
        }
        for (const map of this.masterData.values()) {
            const obj = map.get(referenceId);
            if (obj) {
                return obj;
            }
        }
        return null;
    }

    private deleteReferenceDataFromParentData(
        parentData: BaseMasterData | null,
        parentPropertyName: string,
        dataId: string,
        debug = false,
    ) {
        if (!parentData) {
            return;
        }
        const parentPropVal = parentData[
            parentPropertyName as keyof TRefOrRefs
        ] as TRefOrRefs;
        if (Array.isArray(parentPropVal)) {
            const index = CollectionsHelper.findLastIndex(
                parentPropVal,
                (r) => r.$id === dataId,
            );
            if (index != -1) {
                parentPropVal.splice(index, 1);
            }
        } else if (parentPropVal) {
            parentPropVal.clear();
        }
        if (debug) {
            this.log(
                'deleteReferenceDataFromParentData',
                parentData,
                parentPropertyName,
                parentPropVal,
            );
        }
    }

    private addReferenceDataToDataModelGeneric(
        sdpi: SaveDataParamItem,
        debug = false,
    ) {
        const idr = sdpi.dataAsIdr();
        const obj = this.getMasterObjectFromIdr(idr) as any;
        if (!obj) {
            if (debug) {
                this.log('addReferenceDataToDataModelGeneric', sdpi, idr);
            }
            return;
        }
        const ref = Ref.from(idr);
        const key = sdpi.ParentPropertyName;
        const val = obj[key as keyof TRefOrRefs];
        if (Array.isArray(val)) {
            Ref.addIfNotExists(val, ref);
        } else {
            obj[key] = ref;
        }
        if (debug) {
            this.log(
                'addReferenceDataToDataModelGeneric',
                sdpi,
                idr,
                obj,
                ref,
                sdpi.ParentPropertyName,
            );
        }
    }

    private handleDeletedReferences(
        deletedReferences: SaveDataResultDeletedReferenceItem[],
        updatedIdrs: IIdr[],
        debug = false,
    ) {
        if (debug) {
            this.log('handleDeletedReferences', deletedReferences);
        }
        for (const item of deletedReferences) {
            const parentData = this.getMasterObjectFromIdr(item.parentAsIdr());
            if (!parentData) {
                continue;
            }
            this.deleteReferenceDataFromParentData(
                parentData,
                item.ParentPropertyName,
                item.DataReferenceId,
                debug,
            );
            if (item.IsObjectDeleted) {
                this.deleteMasterObject(item.dataAsIdr());
            }
            this.addReferenceIfValid(updatedIdrs, item.dataAsIdr(), debug);
        }
    }

    private addReferenceIfValid(updatedIdrs: IIdr[], idr: IIdr, debug = false) {
        if (!updatedIdrs) {
            return;
        }
        if (Ref.isVoid(idr)) {
            if (debug) {
                this.log('addReferenceIfValid-invalid', idr);
            }
        } else if (!Ref.includes(updatedIdrs, idr)) {
            updatedIdrs.push(idr);
        }
    }

    private updatePropertyValue(
        data: BaseMasterData,
        propertyName: string,
        propertyValue: any,
        debug = false,
    ) {
        if (debug) {
            this.log('updatePropertyValue', data, propertyName, propertyValue);
        }

        if (typeof propertyValue == 'boolean') {
            return;
        }

        let newValue: any;
        if (typeof propertyValue == 'string') {
            const lowercaseValue = propertyValue.toLowerCase();
            if (lowercaseValue === 'true') {
                newValue = true;
            } else if (lowercaseValue === 'false') {
                newValue = false;
            } else {
                newValue = propertyValue;
            }
        } else {
            newValue = propertyValue;
        }

        const newPropertyName = propertyName.split('.').join('_');

        // Skip updating an Array value with a non array Value
        if (
            Array.isArray(data[newPropertyName as keyof BaseMasterData]) &&
            !Array.isArray(newValue)
        ) {
            return;
        }
        (data as any)[newPropertyName] = newValue;
    }

    private handleUpdatedProperties(
        updatedProperties: SaveDataResultUpdatedPropertyItem[],
        updatedIdrs: IIdr[],
        debug = false,
    ) {
        if (!updatedProperties?.length) {
            return;
        }
        if (debug) {
            this.log('handleUpdatedProperties', updatedProperties);
        }
        for (const upItem of updatedProperties) {
            const dataIdr = upItem.asIdr();
            const data = this.getMasterObjectFromIdr(dataIdr);
            if (!data) {
                continue;
            }
            this.updatePropertyValue(
                data,
                upItem.PropertyName,
                upItem.PropertyValue,
                debug,
            );
            this.addReferenceIfValid(updatedIdrs, dataIdr, debug);
        }
    }

    //#endregion - saveData

    //#region common

    public updateMasterDataWithObject<T extends BaseMasterData>(
        obj: T,
        isUpdate: boolean,
        debug = false,
    ) {
        if (!obj) {
            return;
        }
        if (obj.IsDeleted) {
            this.deleteMasterObject(obj, debug);
        } else if (!isUpdate || !this.isMasterObjectLoaded(obj)) {
            this.attachToParent(obj);
            this.addMasterObject(obj, debug);
            if (obj instanceof Column) {
                this.ensureColumnHasDisplayOrder(obj);
            }
        } else {
            this.addMasterObject(obj, debug);
        }
    }

    private ensureColumnHasDisplayOrder(col: Column) {
        if (!col || col.DisplayOrder) {
            return;
        }
        const table = this.getMasterObject(col.TableRef);
        if (!table) {
            return;
        }
        col.DisplayOrder =
            1 + table.Columns.findIndex((r) => Ref.areSame(r, col));
    }

    private attachToParent(
        obj: BaseMasterData,
        parentRef?: Ref,
        debug = false,
    ) {
        if (!obj) {
            return;
        }

        const log = (logId: string, ...data: any[]) => {
            if (debug) {
                this.log(
                    `attachToParent-${logId}`,
                    ServerType[obj.ServerType],
                    obj.ReferenceId,
                    obj,
                    ...data,
                );
            }
        };

        const tmd = this.typeMetadata.get(obj.ServerType);
        if (!parentRef) {
            const key = tmd?.childPropertyName;
            parentRef = key ? (obj as any)[key] : null;
            if (debug && key && parentRef) {
                log('getParentRef', {
                    key,
                    parentRef,
                    st: ServerType[parentRef?.ServerType],
                    id: parentRef?.ReferenceId,
                });
            }
        }

        if (!parentRef) {
            return;
        }

        const parent = this.getMasterObject(parentRef);
        if (!parent) {
            if (debug && parentRef) {
                log(
                    'no-parent',
                    {
                        parentRef,
                        prSt: parentRef?.ServerType,
                        prId: parentRef?.ReferenceId,
                    },
                    this.masterData,
                );
            }
            return;
        }

        const childPropName = tmd?.parentPropertyName;
        if (!childPropName) {
            return;
        }

        if (childPropName in parent) {
            const objRef = Ref.from(obj);
            const ipcpp = (parent as any)[childPropName] as Ref | Ref[];
            let attached = false;
            if (Array.isArray(ipcpp)) {
                Ref.addIfNotExists(ipcpp, objRef);
                attached = true;
            } else if (ipcpp instanceof Ref) {
                (parent as any)[childPropName] = objRef;
                attached = true;
            }
            if (debug) {
                log('out', { attached, parent, ipcpp, objRef });
            }
        } else {
            this.warn(
                `itemParent has no '${childPropName}' property`,
                obj,
                ServerType[obj.ServerType],
                { parent },
            );
        }
    }

    /** Returns true if the given Ref is not null nor empty, and its object is present as a master-data */
    public isLoadedAndValid(ref: Ref) {
        return !Ref.isVoid(ref) && this.isMasterObjectLoaded(ref);
    }

    /** Returns true if the given Ref is not null nor empty, and its object is not present as a master-data */
    public isNotLoaded<T extends BaseMasterData>(ref: Ref<T>) {
        return !Ref.isVoid(ref) && !this.isMasterObjectLoaded(ref);
    }

    public isMasterObjectLoaded(idr: IIdr) {
        return (
            idr && !!this.masterData.get(idr.ServerType)?.has(idr.ReferenceId)
        );
    }

    public getMasterObject<T extends BaseMasterData>(
        r: Ref<T>,
        noWarn = false,
    ) {
        const obj = this.getMasterObjectFromIdr<T>(r);
        if (!obj && !noWarn && !Ref.isVoid(r)) {
            this.warn('ref not loaded', r, ServerType[r?.ServerType]);
        }
        return obj;
    }

    public getMasterObjectFromIdr<T extends BaseMasterData = BaseMasterData>(
        idr: IIdr,
    ) {
        return idr
            ? ((this.masterData
                  .get(idr.ServerType)
                  ?.get(idr.ReferenceId) as T) ?? null)
            : null;
    }

    /** Returns the master-data objects matching the given refs. Emits a warning for non loaded data */
    public getMasterObjects<T extends BaseMasterData>(
        refs: Ref<T>[],
        dontFilterNulls = false,
    ) {
        if (!refs?.length) {
            return [];
        }
        if (!CoreUtil.isProduction) {
            const notLoadedRefs = refs.filter((r) => r && this.isNotLoaded(r));
            if (notLoadedRefs.length) {
                console.warn('refs not loaded', notLoadedRefs);
            }
        }
        const objs = refs.map((r) => this.getMasterObject(r));
        return dontFilterNulls ? objs : objs.filter((o) => o);
    }

    public addMasterObject(obj: BaseMasterData, debug = false) {
        if (!obj) {
            this.warn('no obj');
            return;
        }
        let exists = false;
        if (debug) {
            exists = this.isMasterObjectLoaded(obj);
        }
        const st = obj.ServerType;
        let map = this.masterData.get(st);
        if (map) {
            map.set(obj.ReferenceId, obj);
        } else {
            this.masterData.set(st, (map = new Map([[obj.ReferenceId, obj]])));
        }
        if (debug) {
            this.log(
                'addMasterObject-out',
                ServerType[st],
                obj.ReferenceId,
                exists,
                this.isMasterObjectLoaded(obj),
                obj,
                this.masterData,
            );
        }
    }

    private deleteMasterObject(idr: IIdr, debug = false) {
        const change = this.isMasterObjectLoaded(idr);
        if (change) {
            this.masterData.get(idr.ServerType)?.delete(idr.ReferenceId);
        }
        if (debug) {
            this.log('deleteMasterObject'), change;
        }
        return change;
    }

    private postProcess(
        obj: BaseMasterData,
        parent?: BaseMasterData,
        parentPropertyName?: string,
    ) {
        if (obj instanceof Table) {
            obj.Columns.forEach((r, index) => {
                const col = this.getMasterObject(r);
                if (col) {
                    col.DisplayOrder = index + 1;
                }
            });
            return true;
        }

        // used on savedata result
        if (
            obj instanceof Column &&
            parentPropertyName &&
            this.hasProp(parent, parentPropertyName)
        ) {
            const tableColumnRefs = (parent as any)[parentPropertyName];
            if (Array.isArray(tableColumnRefs)) {
                tableColumnRefs.push(Ref.from(obj));
                obj.DisplayOrder = tableColumnRefs.length;
                return true;
            }
        }

        return false;
    }

    private hasProp(obj: any, propName: string) {
        return obj && propName != undefined
            ? Object.prototype.hasOwnProperty.call(obj, propName)
            : false;
    }

    // new deserialization - updating existing object not implemented
    /** References a deserialized object graph(tree) */
    private processDeserialized(data: IDeserializedData) {
        const debugAttachToParent = false;
        const { instance, dbg } = data ?? {};
        const { logId, json, verbose } = dbg ?? {};
        this.log('processDeserialized-in', logId);
        /** key is the child, value is the parent */
        let parentByChild: Map<BaseMasterData, BaseMasterData | undefined> =
            new Map();
        const postProcessed: BaseMasterData[] = [];
        try {
            parentByChild = data.instance.getData();
            // 1) register objects
            for (const item of parentByChild.keys()) {
                this.addMasterObject(item, verbose);
                data.result = item; //last item is the root of the tree
            }
            // 2) link objects to each other and already loaded objects
            parentByChild.forEach((parent, item) => {
                if (!parent) {
                    return;
                }
                this.attachToParent(
                    item,
                    Ref.from(parent),
                    verbose || debugAttachToParent,
                );
                const wasPostProcessed = this.postProcess(item, parent);
                if (wasPostProcessed) {
                    postProcessed.push(item);
                }
            });
        } catch (e) {
            this.warn(e);
        } finally {
            if (this.debug) {
                this.log('processDeserialized-out', logId ?? '', {
                    json,
                    instance,
                    parentByChild,
                    itemsByType: CollectionsHelper.mapToMap(
                        parentByChild,
                        (v) => v != undefined,
                        (v) => v?.ServerType,
                        (v) => v,
                    ),
                    postProcessed,
                    masterData: this.masterData,
                });
            }
        }
    }

    //#endregion - common
}
