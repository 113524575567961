import { inject, Injectable, InjectionToken } from '@angular/core';
import {
    CrudActionType,
    CrudOperation,
    LogFunctionalActionParameter,
} from './log-functional';
import { CoreUtil, DomUtil, THTMLElement } from '@datagalaxy/core-util';
import { Subject } from 'rxjs';
import { MonitoringApiService } from '../monitoring-api.service';

export const FUNCTIONAL_LOG_CONTEXT = new InjectionToken<() => object>(
    'FUNCTIONAL_LOG_CONTEXT',
);

/**
 #Archi-service-leaf (Does not reference any other app service)
 */
@Injectable({ providedIn: 'root' })
export class FunctionalLogService {
    private monitoringApiService = inject(MonitoringApiService);
    private context = inject(FUNCTIONAL_LOG_CONTEXT, {
        optional: true,
    });

    private readonly onDispatch = new Subject<{
        featureCode: string;
        crudOperation?: CrudOperation;
        source?: string;
        crudActionType?: CrudActionType;
    }>();

    //#region static

    private static parseData(logFunctionalData: string, origin?: THTMLElement) {
        //FEATURECODE,R
        //FEATURECODE,A,ADD
        const parts = logFunctionalData?.trim().split(',');
        if (!parts?.length) {
            return {};
        }

        const [featureCode, crudLetter, actionTypeString] = parts;
        const crudOp: CrudOperation =
            CrudOperation[crudLetter as keyof typeof CrudOperation];
        const actionType = actionTypeString as CrudActionType;

        const parents =
            origin &&
            DomUtil.getParentComponents(
                origin,
                'ui-view',
                'as-split',
                'as-split-area',
            );
        const source =
            parents && `\\${parents.map((p) => p.localName).join('\\')}`;

        return { featureCode, crudOp, source, actionType };
    }

    //#endregion

    public get onDispatch$() {
        return this.onDispatch.asObservable();
    }

    public async logFunctionalAction(
        featureCode: string,
        crudOperation: CrudOperation,
        crudActionType?: CrudActionType,
    ) {
        await this.dispatchLog(
            featureCode,
            crudOperation,
            undefined,
            crudActionType,
        );
    }

    /**
     * *text* in the form: "*featureCode*,*action*"
     * or "*featureCode*,*action*,*actionType*", where:
     * - *action* is one letter: C, R, U, D, A (see *CrudOperation* enum)
     * - *actionType* is a text matching one of the *CrudActionType* enum values (to be used when *action* is A) */
    public async parseAndLog(text: string, origin?: THTMLElement) {
        if (!text) {
            return;
        }
        const { featureCode, crudOp, source, actionType } =
            FunctionalLogService.parseData(text, origin);
        await this.dispatchLog(featureCode, crudOp, source, actionType);
    }

    private async dispatchLog(
        featureCode?: string,
        crudOperation?: CrudOperation,
        source?: string,
        crudActionType?: CrudActionType,
    ) {
        if (!featureCode) {
            CoreUtil.warn('undefined featureCode, server will fail', {
                crudOperation,
                source,
                crudActionType,
            });
            return;
        }

        if (crudOperation == undefined) {
            CoreUtil.warn('undefined crudOperation, server will fail', {
                featureCode,
                source,
                crudActionType,
            });
        }

        try {
            const userActionParam = new LogFunctionalActionParameter(
                featureCode,
                crudOperation as CrudOperation,
                this.context?.() || {},
                source || '',
                crudActionType,
            );

            await this.monitoringApiService.logFunctionalAction(
                userActionParam,
            );

            this.onDispatch.next({
                featureCode,
                crudOperation,
                source,
                crudActionType,
            });
        } catch (e) {
            CoreUtil.warn(e);
        }
    }
}
