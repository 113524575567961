import { Inject, Injectable } from '@angular/core';
import { TranslateDefaultParser } from '@ngx-translate/core';
import { CoreUtil } from '@datagalaxy/core-util';
import { DXY_TRANSLATE_CONFIG, ITranslationConfig } from './translate.types';

@Injectable()
export class DxyTranslateParser extends TranslateDefaultParser {
    constructor(
        @Inject(DXY_TRANSLATE_CONFIG)
        private config: ITranslationConfig,
    ) {
        super();
    }

    override interpolate(
        expr: string | ((params: unknown) => string),
        params?: unknown,
    ): string {
        try {
            return super.interpolate(expr, params);
        } catch (e) {
            if (!CoreUtil.isProduction) {
                console.warn('DxyTranslateParser.interpolate', {
                    expr,
                    params,
                    error: e,
                });
            }

            this.config.onError?.(e, null, 'DxyTranslateParser.interpolate');

            return '';
        }
    }
}
