<div *ngIf="isReadonly" class="read-only-field">
    <label [dxyEllipsisTooltip]="labelTooltip">
        {{ label }}
    </label>
    <span *ngIf="hintBeforeControl" class="mat-hint">{{ hint }}</span>
    <p class="value-container">{{ valueText }}</p>
    <div class="read-only-separator"></div>
    <span *ngIf="!hintBeforeControl" class="mat-hint">{{ hint }}</span>
</div>
<mat-form-field
    *ngIf="!readonly"
    floatLabel="always"
    [appearance]="fieldAppearance"
    [class.dg5-compact]="compact"
>
    <mat-label [dxyEllipsisTooltip]="labelTooltip">
        {{ label }}
    </mat-label>
    <span *ngIf="hintBeforeControl" class="mat-hint">{{ hint }}</span>
    <input
        type="number"
        matInput
        #fieldControl="matInput"
        [(ngModel)]="value"
        [ngModelOptions]="modelOptionsForUpdateOn"
        [errorStateMatcher]="errorStateMatcher"
        [disabled]="disabled"
        [required]="required"
        [min]="min"
        [max]="max"
        [pattern]="pattern"
    />
    <mat-error>{{ errorMessage }}</mat-error>
</mat-form-field>
<span *ngIf="!hintBeforeControl" class="mat-hint">{{ hint }}</span>
