import {
    Directive,
    ElementRef,
    HostBinding,
    inject,
    Input,
    input,
    OnChanges,
    OnDestroy,
} from '@angular/core';
import { TooltipDestroyRef, TooltipService } from '../tooltip/tooltip.service';
import { TooltipPosition } from '../tooltip/tooltip-position';

/**
 * ## Role
 * Add a tooltip text when text is ellipsed
 * ## Features
 * - By default display the original content of HTMLElement
 * - Setting lineClamp input will truncate the text vertically
 * on the x specified line
 * - Tooltip text can be set by input
 * @example <div dxyEllipsisTooltip> content </div>
 * // tooltip contains 'content'
 * @example <div [dxyEllipsisTooltip]="'otherContent'"> content </div>
 * // tooltip contains 'otherContent'
 * @example <div [dxyEllipsisTooltip]="'otherContent'" dxyEllipsisMode="alwaysVisible"> content <div>
 * // tooltip contains 'otherContent' is showed even if the text is not ellipsed
 * // tooltip contains 'otherContent\ncontent' when the text is ellipsed
 */
@Directive({
    standalone: true,
    selector: '[dxyEllipsisTooltip]',
})
export class EllipsisTooltipDirective implements OnDestroy, OnChanges {
    @Input('dxyEllipsisTooltip') customText?: string;
    position = input<TooltipPosition>('above', {
        alias: 'dxyEllipsisTooltipPosition',
    });
    disabled = input(false, { alias: 'dxyEllipsisTooltipDisabled' });

    /**
     * If set, it will apply the line clamp css property to the element.
     * that way the text will be truncated vertically on the specified line.
     */
    lineClamp = input<number | undefined>(undefined, {
        alias: 'dxyEllipsisTooltipLineClamp',
    });

    @HostBinding('class')
    get tooltipTriggerClass() {
        return this.lineClamp()
            ? 'multiline-ellipsis-content'
            : 'ellipsis-content';
    }

    private elementRef = inject(ElementRef<HTMLElement>);
    private tooltipService = inject(TooltipService);
    private tooltipDestroyRef?: TooltipDestroyRef;

    private get el(): HTMLElement {
        return this.elementRef.nativeElement;
    }

    private get ellipsed() {
        const isVerticalEllipsis = (this.lineClamp() || 0) > 0;
        return this.isEllipsed(this.el, isVerticalEllipsis);
    }

    ngOnChanges() {
        const element = this.elementRef.nativeElement;

        this.tooltipDestroyRef?.();

        if (this.disabled()) {
            return;
        }

        this.tooltipDestroyRef = this.tooltipService.setTooltip(
            element,
            () => this.getTooltipContent(),
            {
                position: this.position(),
            },
        );
    }

    ngOnDestroy() {
        this.tooltipDestroyRef?.();
    }

    private getTooltipContent(): string {
        const content = this.customText || '';
        const textContent = this.el.textContent || '';

        if (!this.ellipsed) {
            return content;
        }

        return content ? `${textContent}\n${content}` : textContent;
    }

    /**
     * Returns true if the given element is bigger than its container on one dimension:
     * taller if *isVertical* is true, else wider.
     * It is usefull when paired with line clamp css property
     */
    private isEllipsed(el: HTMLElement, isVertical?: boolean) {
        if (!el) {
            return;
        }
        return (
            (!isVertical && el.scrollWidth > el.offsetWidth) ||
            (isVertical && el.scrollHeight > el.offsetHeight)
        );
    }
}
