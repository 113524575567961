import { EntityService } from './entity.service';
import {
    IEntityToolParams,
    IEntityToolState,
} from '../interfaces/entity-panel.interface';
import { Injectable } from '@angular/core';
import { EntityPanelTool } from '../../../entity-panels/entity-panels.types';
import { ScreenService } from '../../../screens/screen.service';
import { BaseEntityToolService } from '../../../entity-panels/BaseEntityToolService';
import { ProjectVersion } from '@datagalaxy/webclient/versioning/data-access';
import { WorkspaceService } from '@datagalaxy/webclient/workspace/data-access';

/**
 * ## Role
 * Manage Docking pane Visibility and active tool state
 */

@Injectable({ providedIn: 'root' })
export class EntityDockingPaneService extends BaseEntityToolService<
    IDockingPanelParams,
    IDockingPanelState
> {
    /*
        Behaviour note:
        We want the active tool to stay open (at least visually) between entity changes within the same module.
        But, when selecting another entity, the router changes its state&view, so the panel component is destroyed and recreated.
        So the active tool is re-initialized with the previous entity, then updated with the new one.
        So, each tool has to manage this transition - cf DG-2750.
    */

    public get isPanelCollapsed() {
        return !!this.activeStateValue.collapsed;
    }
    public get isPanelHidden() {
        return !!this.activeStateValue.hidden;
    }

    constructor(
        screenService: ScreenService,
        entityService: EntityService,
        workspaceService: WorkspaceService,
    ) {
        super(screenService, entityService, workspaceService);
        this.setActiveState({
            activeTool: undefined,
            collapsed: true,
        });
    }

    public expandPanel() {
        this.setIsCollapsed(false);
    }
    public collapsePanel() {
        this.setIsCollapsed(true);
    }
    public togglePanel() {
        this.setIsCollapsed(!this.isPanelCollapsed);
    }
    public showPanel() {
        this.setIsHidden(false);
    }
    public hidePanel() {
        this.setIsHidden(true);
    }

    public isInsightsPaneVisible() {
        return (
            super.isActiveTool(EntityPanelTool.Insights) &&
            !this.isPanelCollapsed
        );
    }

    public openPaneVersion(requestedVersion: ProjectVersion) {
        super.setActiveTool(
            EntityPanelTool.VersionComparison,
            requestedVersion,
        );
        this.expandPanel();
    }

    public gotoMetabotPane() {
        this.setActiveState({
            activeTool: EntityPanelTool.Suggestion,
            collapsed: false,
        });
    }

    public resetPanel() {
        this.collapsePanel();
        super.clearData();
    }

    public onDockingPaneInit() {
        // If the pane has been collapsed before entity changed, reset active tool
        if (this.isPanelCollapsed) {
            this.clearActiveTool();
        }
    }

    private setIsCollapsed(collapsed: boolean) {
        collapsed = !!collapsed;

        if (!!this.activeStateValue.collapsed != collapsed) {
            this.setActiveState({
                collapsed,
            });
        }
    }

    private setIsHidden(isHidden: boolean) {
        if (!!this.activeStateValue.hidden == !!isHidden) {
            return false;
        }
        this.setActiveState({
            hidden: isHidden,
        });

        return true;
    }
}

interface IDockingPanelState extends IEntityToolState {
    hidden?: boolean;
    collapsed?: boolean;
}

export interface IDockingPanelParams extends IEntityToolParams {
    isPaneCollapsed?: boolean;
}
