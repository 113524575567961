<div *ngIf="isReadonly" class="read-only-field">
    <label [dxyEllipsisTooltip]="labelTooltip">
        {{ label }}
    </label>
    <span *ngIf="hintBeforeControl" class="mat-hint">{{ hint }}</span>
    <p class="value-container">{{ readOnlyPassword }}</p>
    <div class="read-only-separator"></div>
    <span *ngIf="!hintBeforeControl" class="mat-hint">{{ hint }}</span>
</div>
<mat-form-field
    *ngIf="!isReadonly"
    floatLabel="always"
    [appearance]="fieldAppearance"
    [class.dg5-compact]="compact"
>
    <mat-label [dxyEllipsisTooltip]="labelTooltip">
        {{ label }}
        <span
            *ngIf="infoTooltip"
            class="glyph-info"
            [dxyTooltip]="infoTooltip"
        ></span>
    </mat-label>
    <span *ngIf="hintBeforeControl" class="mat-hint">{{ hint }}</span>
    <input
        [type]="typeInput"
        matInput
        #fieldControl="matInput"
        spellcheck="false"
        [placeholder]="placeholder"
        [(ngModel)]="value"
        [ngModelOptions]="modelOptionsForUpdateOn"
        [errorStateMatcher]="errorStateMatcher"
        [disabled]="disabled"
        [maxLength]="passwordMaxLengthValue"
        (ngModelChange)="onInputChange($event)"
        [required]="required"
        [attr.autocomplete]="browserAutocomplete"
    />
    <button
        *ngIf="!disabled"
        dxyIconButton
        variant="tertiary"
        matSuffix
        type="button"
        (mouseenter)="toggleIconClass()"
        (mouseleave)="toggleIconClass()"
        (click)="togglePasswordVisibility()"
    >
        <i [ngClass]="iconClass"></i>
    </button>
    <mat-error>{{ errorMessage }}</mat-error>
</mat-form-field>
<span *ngIf="!hintBeforeControl" class="mat-hint">{{ hint }}</span>
