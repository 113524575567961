<div *ngIf="isReadonly" class="read-only-field">
    <label [dxyEllipsisTooltip]="labelTooltip">
        {{ label }}
    </label>
    <span *ngIf="hintBeforeControl" class="mat-hint">{{ hint }}</span>
    <dxy-data-type-size-field-control
        [readonly]="true"
        [ngModel]="value"
        [availableDataTypes]="availableDataTypes"
        [noTypeLabel]="noTypeLabel"
        [noLabels]="noLabels"
        [adapter]="adapter"
    ></dxy-data-type-size-field-control>
    <span *ngIf="!hintBeforeControl" class="mat-hint">{{ hint }}</span>
</div>
<mat-form-field
    *ngIf="!isReadonly"
    floatLabel="always"
    appearance="standard"
    [class.dg5-compact]="compact"
>
    <mat-label [dxyEllipsisTooltip]="labelTooltip">
        {{ label }}
    </mat-label>
    <span *ngIf="hintBeforeControl" class="mat-hint">{{ hint }}</span>
    <dxy-data-type-size-field-control
        [(ngModel)]="value"
        [availableDataTypes]="availableDataTypes"
        [errorStateMatcher]="errorStateMatcher"
        [adapter]="adapter"
        [disabled]="disabled"
        [noLabels]="noLabels"
        [noTypeLabel]="noTypeLabel"
        [required]="required"
    ></dxy-data-type-size-field-control>
    <mat-error>{{ errorMessage }}</mat-error>
</mat-form-field>
<span *ngIf="!hintBeforeControl" class="mat-hint">{{ hint }}</span>
