<div *ngIf="isReadonly" class="read-only-field">
    <label>
        <span [dxyTooltip]="labelTooltip">{{ label }}</span>
        <ng-container *ngTemplateOutlet="labelActions"></ng-container>
    </label>
    <span *ngIf="hintBeforeControl" class="mat-hint">{{ hint }}</span>
    <dxy-rich-text-field-control
        [ngModel]="value"
        [readonly]="true"
        [adapter]="adapter"
        [mentionResolvers]="mentionResolvers"
    ></dxy-rich-text-field-control>
    <div class="read-only-separator"></div>
    <span *ngIf="!hintBeforeControl" class="mat-hint">{{ hint }}</span>
</div>

<mat-form-field
    *ngIf="!isReadonly"
    floatLabel="always"
    [appearance]="fieldAppearance"
    [class.dg5-compact]="compact"
>
    <mat-label>
        <span [dxyTooltip]="labelTooltip">{{ label }}</span>
        <ng-container *ngTemplateOutlet="labelActions"></ng-container>
    </mat-label>
    <span *ngIf="hintBeforeControl" class="mat-hint">{{ hint }}</span>
    <dxy-rich-text-field-control
        #fieldControl
        [(ngModel)]="value"
        [ngModelOptions]="modelOptionsForUpdateOn"
        [errorStateMatcher]="errorStateMatcher"
        [placeholder]="placeholder"
        [disabled]="!!disabled"
        [showToolbar]="isToolbarVisible"
        [adapter]="adapter"
        [mentionResolvers]="mentionResolvers"
        [noTabCapture]="noTabCapture"
        (onAcquireMentionData)="onAcquireMentionDataInternal($event)"
        [required]="required"
    ></dxy-rich-text-field-control>
    <mat-error>{{ errorMessage }}</mat-error>
</mat-form-field>

<span *ngIf="!hintBeforeControl" class="mat-hint">{{ hint }}</span>

<ng-template #labelActions>
    <ng-content select="[label-actions]"></ng-content>
</ng-template>
