import { Inject, Injectable } from '@angular/core';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { CoreUtil } from '@datagalaxy/core-util';
import { DXY_TRANSLATE_CONFIG, ITranslationConfig } from './translate.types';

export interface TranslationObject {
    [key: string]: string | TranslationObject;
}

@Injectable()
export class DxyTranslateMessageFormatCompiler extends TranslateMessageFormatCompiler {
    constructor(
        @Inject(DXY_TRANSLATE_CONFIG)
        private config?: ITranslationConfig,
    ) {
        super();
    }

    public override compileTranslations(
        translations: TranslationObject,
        lang: string,
    ): unknown {
        const { doCheckAllOnError, doReplaceShortcutAll } =
            this.config?.messageFormatPatch || {};

        if (!doCheckAllOnError && !doReplaceShortcutAll) {
            this.log('using genuine TranslateMessageFormatCompiler');
            return;
        }

        this.log('compileTranslations', lang);
        this.time('compileTranslations' + lang);

        if (doReplaceShortcutAll) {
            this.replaceShortcutAll(translations);
        }

        try {
            const result = super.compileTranslations(translations, lang);
            this.log('messageFormat.compile OK', lang, result);
            this.timeEnd('compileTranslations' + lang);

            return result;
        } catch (e) {
            if (!CoreUtil.isProduction) {
                console.warn('messageFormat.compile', lang, e);
            }
            if (doCheckAllOnError) {
                this.checkAll(lang, translations);
            }

            this.timeEnd('compileTranslations' + lang);

            return undefined;
        }
    }

    /** replace recursively @:path shortcuts by linked value */
    private replaceShortcutAll(translations: TranslationObject) {
        this.visitAll(translations, (v, k, o) => {
            if (!v.startsWith('@:')) {
                return;
            }
            let value = v;
            do {
                value = CoreUtil.getObjectPathValue(
                    translations,
                    value.slice(2),
                );
            } while (value?.startsWith('@:'));

            if (value) {
                o[k] = value;
            } else {
                this.log(`${v} shortcut links to no existing value`);
            }
        });
    }
    /** compiles each text individually to show where the errors are */
    private checkAll(lang: string, translations: TranslationObject) {
        this.log('checkAll', lang);
        this.visitAll(translations, (v, k, _, stack) => {
            try {
                this.compile(v, lang);
            } catch (e) {
                this.log(v, [...stack, k].join('.'), e);
            }
        });
        this.log('checkAll-done', lang);
    }
    /** executes the given function with each string property of the given object, recursively */
    private visitAll(
        obj: TranslationObject,
        fn: (
            v: string,
            k: keyof typeof o,
            o: TranslationObject,
            stack: string[],
        ) => void,
    ) {
        const stack: string[] = [];
        const visit = (o: TranslationObject) =>
            Object.entries(o).forEach(([k, v]) => {
                if (typeof v == 'string') {
                    fn(v, k, o, stack);
                } else if (typeof v == 'object') {
                    stack.push(k);
                    visit(v);
                    stack.pop();
                }
            });

        visit(obj);
    }

    private log(...args: unknown[]) {
        this.config?.messageFormatPatch?.doLog && console.log(...args);
    }
    private time(label?: string) {
        this.config?.messageFormatPatch?.doLog && console.time(label);
    }
    private timeEnd(label?: string) {
        this.config?.messageFormatPatch?.doLog && console.timeEnd(label);
    }
}
