import {
    Component,
    ElementRef,
    Input,
    NgZone,
    Optional,
    Self,
    ViewChild,
} from '@angular/core';
import { FormsModule, NgControl } from '@angular/forms';
import {
    MatLegacyInput as MatInput,
    MatLegacyInputModule,
} from '@angular/material/legacy-input';
import { TranslateService } from '@ngx-translate/core';
import { DxyBaseFocusableFieldComponent } from '@datagalaxy/ui/fields';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import {
    EllipsisTooltipDirective,
    TooltipDirective,
} from '@datagalaxy/ui/tooltip';
import { NgIf } from '@angular/common';

@Component({
    selector: 'dxy-field-number',
    templateUrl: 'field-number.component.html',
    standalone: true,
    imports: [
        NgIf,
        EllipsisTooltipDirective,
        MatLegacyFormFieldModule,
        FormsModule,
        MatLegacyInputModule,
        TooltipDirective,
    ],
})
export class DxyFieldNumberComponent extends DxyBaseFocusableFieldComponent<number> {
    @Input() placeholder: string;
    @Input() min: number;
    @Input() max: number;
    @Input() pattern: string | RegExp;
    /** optional function to format the number when displayed as readonly */
    @Input() formatNumber: (n: number) => string;

    public get label() {
        return this.getLabel(this.translate);
    }
    public get labelTooltip() {
        return this.getLabelTooltip(this.translate);
    }
    public get errorMessage() {
        return this.getErrorMessage(this.translate);
    }
    public get valueText() {
        return this.formatNumber
            ? this.formatNumber(this.value)
            : `${this.value ?? ''}`;
    }

    @ViewChild('fieldControl') protected fieldControl: MatInput;

    constructor(
        private translate: TranslateService,
        elementRef: ElementRef<HTMLElement>,
        ngZone: NgZone,
        @Optional() @Self() ngControl: NgControl,
    ) {
        super(elementRef, ngZone, ngControl);
    }
}
