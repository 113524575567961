import {
    BaseServiceParameter,
    BaseServiceResult,
} from '@datagalaxy/data-access';
import {
    deserialize,
    deserializeAs,
    inheritSerialization,
    serialize,
} from 'cerialize';
import { IEntityIdentifier } from '@datagalaxy/dg-object-model';
import { DataTypeDTO } from './data-type';
import { getSpaceIdFromEntity } from '@datagalaxy/webclient/utils';

@inheritSerialization(BaseServiceParameter)
export class GetModelDataTypesParameter extends BaseServiceParameter {
    @serialize public ModelDataReferenceId: string;
    @serialize public ProjectReferenceId?: string;

    static fromModelIdr(modelIdr: IEntityIdentifier) {
        const spaceId = getSpaceIdFromEntity(modelIdr);
        return new GetModelDataTypesParameter(
            modelIdr.ReferenceId,
            spaceId,
            modelIdr.VersionId,
        );
    }

    constructor(entityId: string, projectId?: string, versionId?: string) {
        super();
        this.ModelDataReferenceId = entityId;
        this.ProjectReferenceId = projectId;
        this.VersionId = versionId || '';
    }
}

@inheritSerialization(BaseServiceResult)
export class GetModelDataTypesResult extends BaseServiceResult {
    @deserialize public IsErrorUnknownModelReferenceId!: boolean;
    @deserialize public IsErrorUnknownProjectReferenceId!: boolean;
    @deserializeAs(DataTypeDTO) public DataTypes!: DataTypeDTO[];
}
