import { deserialize, SerializableEnumeration } from 'cerialize';

export class ClientPublicData {
    @deserialize public $id?: string; //#archi-abasedata-$id: may be not used
    @deserialize public ClientId?: string;
    @deserialize public DisplayName?: string;

    constructor() {}
}

export enum ClientAccessFlags {
    None = 0,

    // <summary>Sub Organization</summary>
    EnableSubOrganizations = 1 << 0, // bit 0 set to 1

    /// <summary>
    /// Clients with this flag has a single Project, and no ability to create more workspaces
    /// </summary>
    IsSingleWorkspace = 1 << 1,

    /// <summary>
    /// Clients with this flag do not have versioning functionality
    /// </summary>
    DisableVersioning = 1 << 2,

    /// <summary>
    /// Client with this flag has access to the Online Connector (tab and import plugins) if enabled on instance
    /// </summary>
    EnableEmbeddedConnector = 1 << 4,

    /// <summary>
    /// Client with this flag does not have access to send feedback
    /// </summary>
    DisableFeedback = 1 << 5,

    /// <summary>
    /// Client with this flag DOES NOT have access to the Desktop Connector (page and import plugins) if enabled on instance
    /// </summary>
    DisableDesktopConnector = 1 << 7,

    /// <summary>
    /// Clients with this flag have access to advanced mode in Online Connector (with raw text input)
    /// </summary>
    EnableOnlineConnectionTextEditor = 1 << 8,

    /// <summary>
    /// Enable Campaigns Feature
    /// </summary>s
    EnableCampaigns = 1 << 9,

    /// <summary>
    /// Clients with this flag have access to data quality screens
    /// </summary>
    EnableDataQuality = 1 << 10,

    /// <summary>
    /// Enable Auto glossary generation Feature
    /// </summary>s
    EnableGlossaryAutoGen = 1 << 11,
}
SerializableEnumeration(ClientAccessFlags);

export enum NotificationRealTimeBehavior {
    All = 0,
    None = 1,
    ActionOnly = 2,
}
SerializableEnumeration(NotificationRealTimeBehavior);

export class LoginSecurityData {
    @deserialize public $id!: string; //#archi-abasedata-$id: may be not used
    @deserialize public UserRoleLocalIds: string[] = [];
    @deserialize public LicenseLevel!: string;
    @deserialize public CanCreateEntities!: boolean;
    @deserialize public CanImportEntities!: boolean;
}

export interface ClientInfoDto {
    ClientId: string;
    UserId: string;
    FriendlyName: string;
    AccessFlags: ClientAccessFlags[];
    Category: string;
    Status: string;
    EngagementLifeCycle: string;
    PovStartTime?: string;
    PovEndTime?: string;
    TacitAgreementDate?: string;
    EndOfContractNotificationDate?: string;
    Description: string;
    UserSessionTimeoutMinutes: number;
    UiSettingsJson: string;
    FirstConnectionTime?: string;
    LastConnectionTime?: string;
    DefaultSpaceUid?: string;
    OfferPlan: string;
    OfferName: string;
    NotificationRealTimeBehavior: NotificationRealTimeBehavior;
    /**
     * Indicates whether the client uses an SSO login mechanism
     * @deprecated This property is deprecated in Auth V2.
     * Use `IsSsoConfigured` for auth V2 implementations.
     */
    IsExternalLogin: boolean;

    /**
     * Indicates whether the client is configured to use SSO (Single Sign-On).
     * This property is used in Auth V2.
     */
    IsSsoEnabled: boolean;
}
