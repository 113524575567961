import { inject, Injectable, signal } from '@angular/core';
import { LANGUAGE_OPTIONS } from './language-options';

@Injectable({
    providedIn: 'root',
})
export class LanguageService {
    private options = inject(LANGUAGE_OPTIONS);
    private _language = signal(this.options.defaultLanguage);

    public get locales() {
        return this.options.locales;
    }

    public get language() {
        return this._language.asReadonly();
    }

    init(): void {
        const browserLanguages = this.getCompatibleBrowserLanguage();
        const storedLanguage = this.getStoredLanguage();

        if (storedLanguage) {
            this.setLanguage(storedLanguage);
        } else if (
            this.options?.useDefaultBrowserLanguage &&
            browserLanguages
        ) {
            this.setLanguage(browserLanguages);
        } else {
            this.setLanguage(this.options.defaultLanguage);
        }
    }

    public getLanguage(): string {
        return this._language();
    }

    public setLanguage(lang: string): void {
        if (!this.locales.includes(lang)) {
            throw new Error(`Language ${lang} is not supported`);
        }
        localStorage.setItem('language', lang);
        this.options?.onChange?.(lang);
        this._language.set(lang);
    }

    private getCompatibleBrowserLanguage(): string | undefined {
        return navigator.languages.find((lang) => this.locales.includes(lang));
    }

    private getStoredLanguage(): string | undefined {
        const storedLanguage = localStorage.getItem('language');
        return this.locales.find((lang) => lang === storedLanguage);
    }
}
