import { Injectable } from '@angular/core';
import { BackendApiService } from '@datagalaxy/data-access';
import {
    AddColumnToPrimaryKeyParameter,
    ModelerUpdatePKApiResult,
    RemoveColumnFromPrimaryKeyParameter,
    UpdatePrimaryKeyParameter,
} from './primary-key';
import {
    ConvertForeignKeyParameter,
    CreateFunctionalForeignKeyParameter,
    CreateTechnicalForeignKeyParameter,
    DeleteForeignKeyParameter,
    UpdateFunctionalForeignKeyParameter,
    UpdateTechnicalForeignKeyParameter,
} from './foreign-key';
import {
    GetModelDataTypesParameter,
    GetModelDataTypesResult,
} from './data-type';
import {
    LoadDataParameter,
    LoadDataResult,
    ModelerApiParameter,
    ModelerApiResult,
    SaveDataParameter,
    SaveDataResult,
} from './model';

@Injectable({ providedIn: 'root' })
export class ModelerApiService {
    constructor(private backendApiService: BackendApiService) {}

    public async getModelDataTypes(param: GetModelDataTypesParameter) {
        return await this.backendApiService.postPromise(
            'Model/GetModelDataTypes',
            param,
            GetModelDataTypesResult,
        );
    }

    //#region Primary Key
    public async addColumnToPrimaryKey(param: AddColumnToPrimaryKeyParameter) {
        return await this.backendApiService.postPromise(
            'Model/AddColumnToPk',
            param,
            ModelerApiResult,
        );
    }

    public async removeColumnFromPrimaryKey(
        param: RemoveColumnFromPrimaryKeyParameter,
    ) {
        return await this.backendApiService.postPromise(
            'Model/RemoveColumnFromPk',
            param,
            ModelerApiResult,
        );
    }

    public async updatePrimaryKey(param: UpdatePrimaryKeyParameter) {
        return this.backendApiService.postPromise(
            'Model/UpdatePk',
            param,
            ModelerUpdatePKApiResult,
        );
    }

    public async getModelPkList(param: ModelerApiParameter) {
        return await this.backendApiService.postPromise(
            'Model/GetPkList',
            param,
            ModelerApiResult,
        );
    }

    public async getModelFkList(param: ModelerApiParameter) {
        return await this.backendApiService.postPromise(
            'Model/GetFkList',
            param,
            ModelerApiResult,
        );
    }

    public async getDataForPkModal(param: ModelerApiParameter) {
        return await this.backendApiService.postPromise(
            'Model/GetDataForPkModal',
            param,
            ModelerApiResult,
        );
    }

    public async getDataForFkModal(param: ModelerApiParameter) {
        return await this.backendApiService.postPromise(
            'Model/GetDataForFkModal',
            param,
            ModelerApiResult,
        );
    }

    /** Returns the list of Tables/Foreign Keys and Primary Keys for a given Model.
     Table items are returned:
     - without their Columns
     - with the HddData property filled */
    public async getModelerData(param: ModelerApiParameter) {
        return await this.backendApiService.postPromise(
            'Model/GetModelerData',
            param,
            ModelerApiResult,
        );
    }

    /** Table items are returned with the HddData property filled */
    public async getModelerTableColumns(param: ModelerApiParameter) {
        return await this.backendApiService.postPromise(
            'Model/GetModelerTableColumns',
            param,
            ModelerApiResult,
        );
    }
    //#endregion - PK

    //#region Foreign Key

    public async createTechnicalForeignKey(
        param: CreateTechnicalForeignKeyParameter,
    ) {
        return await this.backendApiService.postPromise(
            'Model/CreateFk',
            param,
            ModelerApiResult,
        );
    }

    public async createFunctionalForeignKey(
        param: CreateFunctionalForeignKeyParameter,
    ) {
        return await this.backendApiService.postPromise(
            'Model/CreateFunctionalFk',
            param,
            ModelerApiResult,
        );
    }

    public async updateTechnicalForeignKey(
        param: UpdateTechnicalForeignKeyParameter,
    ) {
        return await this.backendApiService.postPromise(
            'Model/UpdateFk',
            param,
            ModelerApiResult,
        );
    }

    public async updateFunctionalForeignKey(
        param: UpdateFunctionalForeignKeyParameter,
    ) {
        return await this.backendApiService.postPromise(
            'Model/UpdateFunctionalFk',
            param,
            ModelerApiResult,
        );
    }

    public async deleteForeignKey(param: DeleteForeignKeyParameter) {
        return await this.backendApiService.postPromise(
            'Model/DeleteFk',
            param,
            ModelerApiResult,
        );
    }

    public async convertForeignKey(param: ConvertForeignKeyParameter) {
        return await this.backendApiService.postPromise(
            'Model/ConvertFunctionalFkToFk',
            param,
            ModelerApiResult,
        );
    }
    //#endregion - FK

    public async saveData(param: SaveDataParameter) {
        return await this.backendApiService.postPromise(
            'Data/SaveData',
            param,
            SaveDataResult,
        );
    }

    public async loadData(param: LoadDataParameter) {
        return await this.backendApiService.postPromise(
            'Data/LoadData',
            param,
            LoadDataResult,
        );
    }
}
