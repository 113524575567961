import {
    ChangeDetectionStrategy,
    Component,
    computed,
    HostBinding,
    input,
    TemplateRef,
} from '@angular/core';
import { fadeInFadeOutAnimation } from '@datagalaxy/ui/animation';
import { PortalModule } from '@angular/cdk/portal';
import { NgTemplateOutlet } from '@angular/common';

@Component({
    selector: 'dxy-tooltip',
    standalone: true,
    imports: [PortalModule, NgTemplateOutlet],
    templateUrl: './tooltip.component.html',
    styleUrl: './tooltip.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [fadeInFadeOutAnimation('150ms')],
})
export class TooltipComponent {
    content = input.required<string | TemplateRef<unknown>>();
    width = input<number | undefined>();

    @HostBinding('@fadeInFadeOut') animation = true;

    @HostBinding('class.template-tooltip') get tooltipClass() {
        return !!this.templateRef();
    }

    protected templateRef = computed(() => {
        const content = this.content();
        return content instanceof TemplateRef ? content : null;
    });
}
