<div *ngIf="isReadonly" class="read-only-field">
    <label [dxyEllipsisTooltip]="labelTooltip">
        {{ label }}
    </label>
    <span *ngIf="hasHintBeforeField" class="mat-hint">{{ hint }}</span>
    <span
        *ngIf="hasHintBeforeField && htmlHint"
        class="mat-hint"
        [innerHtml]="sanitizedHint()"
    ></span>
    <div class="value-container" [ngStyle]="getOptionStyle(value)">
        <dxy-option-item
            *ngIf="selectedOption"
            [option]="selectedOption"
            [dxyTooltip]="hintAsTooltipWhenMini ? hint : ''"
            [isMini]="true"
            (click)="onSelectedItemClick($event)"
        ></dxy-option-item>
    </div>
    <div class="read-only-separator"></div>
    <span *ngIf="hasHintAfterField" class="mat-hint">{{ hint }}</span>
    <span
        *ngIf="hasHintAfterField && htmlHint"
        class="mat-hint"
        [innerHtml]="sanitizedHint()"
    ></span>
</div>
<ng-container *ngIf="!isReadonly">
    <mat-form-field
        floatLabel="always"
        [appearance]="fieldAppearance"
        [class.dg5-compact]="compact"
    >
        <mat-label [dxyEllipsisTooltip]="labelTooltip">
            {{ label }}
            <span
                *ngIf="infoTooltip"
                class="glyph-info"
                [dxyTooltip]="infoTooltip"
            ></span>
        </mat-label>
        <span *ngIf="hasHintBeforeField" class="mat-hint">{{ hint }}</span>
        <span
            *ngIf="hasHintBeforeField && htmlHint"
            class="mat-hint"
            [innerHtml]="sanitizedHint()"
            >-</span
        >
        <mat-select
            #fieldControl
            [(ngModel)]="value"
            [ngModelOptions]="modelOptionsForUpdateOn"
            [placeholder]="placeholder"
            [errorStateMatcher]="errorStateMatcher"
            [disabled]="disabled"
            [disableOptionCentering]="true"
            (selectionChange)="onSelectionChange($event.value)"
            (opened)="onOpenedClosed(true)"
            (closed)="onOpenedClosed(false)"
            [required]="required"
            [panelClass]="panelClass"
        >
            <!-- selected option -->
            <mat-select-trigger [ngStyle]="getOptionStyle(value)">
                <div class="select-trigger-content">
                    <dxy-option-item
                        *ngIf="selectedOption"
                        class="option-item"
                        [dxyTooltip]="hintAsTooltipWhenMini ? hint : ''"
                        [option]="selectedOption"
                        [isMini]="true"
                        (click)="onSelectedItemClick($event)"
                    ></dxy-option-item>
                    <button
                        *ngIf="showRemoveSelectedOption"
                        (click)="onRemoveSelectedOption($event)"
                        type="button"
                        class="remove-tag-btn"
                    >
                        <i class="glyph-cancelsearch"></i>
                    </button>
                </div>
            </mat-select-trigger>

            <!-- search box -->
            <mat-option
                *ngIf="search"
                [disabled]="true"
                class="mat-option-search"
            >
                <ng-content select="[headerBeforeSearch]"></ng-content>
                <dxy-search-input
                    #searchInput
                    [searchString]="searchString"
                    (onSearchString)="onSearch($event)"
                    [showCancelSearch]="true"
                    [captureKeyboard]="true"
                    dxyDataTestId="select-search-input"
                ></dxy-search-input>
                <ng-content select="[headerAfterSearch]"></ng-content>
            </mat-option>

            <!-- options when grouping -->
            <ng-container *ngIf="groups">
                <mat-optgroup *ngFor="let g of groups" [label]="g.text">
                    <mat-option
                        *ngFor="let o of g.options"
                        [value]="o.value"
                        [ngClass]="o.class"
                        [ngStyle]="o.style"
                        [class.rich-layout-option]="useRichLayout"
                        class="option-container"
                        dxyDataTestId
                        [dxyDataTestIdValue]="getDataTestValue(o)"
                    >
                        <dxy-option-item [option]="o"></dxy-option-item>
                    </mat-option>
                </mat-optgroup>
            </ng-container>

            <!-- Fake hidden selected option 
             to be sure selected option displayed 
             in the select-trigger-content div 
             event if option is not in search result -->
            <mat-option
                *ngIf="selectedOption"
                [value]="selectedOption.value"
                [class.hidden]="true"
            ></mat-option>

            <!-- options when not grouping -->
            <ng-container *ngIf="!groups">
                <mat-option
                    *ngFor="let o of actualOptions"
                    [value]="o.value"
                    [ngClass]="o.class"
                    [ngStyle]="o.style"
                    class="option-container"
                    [class.rich-layout-option]="useRichLayout"
                    dxyDataTestId
                    [dxyDataTestIdValue]="getDataTestValue(o)"
                >
                    <dxy-option-item [option]="o"></dxy-option-item>
                </mat-option>
            </ng-container>
            <div
                *ngIf="noSearchResultPlaceholderVisible"
                class="dxy-placeholder"
            >
                <img
                    class="dxy-placeholder-img"
                    src="/images/placeholders/search/empty-search.svg"
                />
                <div class="dxy-placeholder-text" translate>
                    UI.Search.noResults
                </div>
            </div>
            <ng-content select="[footer]"></ng-content>
        </mat-select>

        <mat-error>{{ errorMessage }}</mat-error>
    </mat-form-field>
    <span *ngIf="hasHintAfterField" class="mat-hint">{{ hint }}</span>
    <span
        *ngIf="hasHintAfterField && htmlHint"
        class="mat-hint"
        [innerHtml]="sanitizedHint()"
    ></span>
</ng-container>
