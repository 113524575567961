import { Injectable } from '@angular/core';
import { RestApiService } from '@datagalaxy/data-access';
import { GetClientInfoQueryResult } from './get-client-info-query-result';
import { firstValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ClientInfoApiService {
    constructor(private restApiService: RestApiService) {}

    public async getClientInfo() {
        return await firstValueFrom(
            this.restApiService.get<GetClientInfoQueryResult>('client-info'),
        );
    }
}
