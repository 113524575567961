import {
    Component,
    ElementRef,
    Input,
    NgZone,
    Optional,
    Self,
    ViewChild,
} from '@angular/core';
import { FormsModule, NgControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DxyDataTypeSizeFieldControlComponent } from '../controls/data-type-size-field-control/data-type-size-field-control.component';
import {
    IDataTypeDisplayAdapter,
    IDataTypePrecisionSize,
} from '../controls/data-type-size-field-control/IDataTypePrecisionSize';
import { DxyBaseFocusableFieldComponent } from '@datagalaxy/ui/fields';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import {
    EllipsisTooltipDirective,
    TooltipDirective,
} from '@datagalaxy/ui/tooltip';
import { NgIf } from '@angular/common';

/** Displays a type selector, a size input and a precision (number) input,
 * For to display or set the type, precision and size of a column in a database table */
@Component({
    selector: 'dxy-field-data-type-size',
    templateUrl: 'field-data-type-size.component.html',
    standalone: true,
    imports: [
        NgIf,
        EllipsisTooltipDirective,
        DxyDataTypeSizeFieldControlComponent,
        FormsModule,
        MatLegacyFormFieldModule,
        TooltipDirective,
    ],
})
export class DxyFieldDataTypeSizeComponent<
    TDataType,
> extends DxyBaseFocusableFieldComponent<IDataTypePrecisionSize<TDataType>> {
    @Input() availableDataTypes: TDataType[];
    @Input() adapter: IDataTypeDisplayAdapter<TDataType>;
    @Input() noLabels: boolean;
    @Input() noTypeLabel: boolean;

    @ViewChild(DxyDataTypeSizeFieldControlComponent)
    fieldControl: DxyDataTypeSizeFieldControlComponent<TDataType>;

    public get label() {
        return this.getLabel(this.translate);
    }
    public get labelTooltip() {
        return this.getLabelTooltip(this.translate);
    }
    public get errorMessage() {
        return this.getErrorMessage(this.translate);
    }

    protected get empty() {
        return this.fieldControl?.empty;
    }

    constructor(
        private translate: TranslateService,
        elementRef: ElementRef<HTMLElement>,
        ngZone: NgZone,
        @Optional() @Self() ngControl: NgControl,
    ) {
        super(elementRef, ngZone, ngControl);
    }
}
