import { deserialize, inheritSerialization } from 'cerialize';
import { HierarchyDataDescriptor } from '@datagalaxy/dg-object-model';
import { UserSpaceAccessType } from './user-space-access-type.enum';
import { UserPublicData } from '@datagalaxy/webclient/user/domain';

@inheritSerialization(UserPublicData)
export class UserAdminData extends UserPublicData {
    @deserialize public LicenseExpiresOn?: string;
    @deserialize public LicenseStartsOn?: string;
    @deserialize public IsLicenseExpired?: boolean;
    @deserialize public IsLicenseNotStarted?: boolean;
    @deserialize public LicenseClientUid?: string;
    @deserialize public LicenseId?: number;
    @deserialize public IsClientAdmin?: boolean;
    @deserialize public FirstConnectionOn?: string;
    @deserialize public LastConnectionOn?: string;
    /** HierarchyDataDescriptors of Space, by attribute-key */
    @deserialize public DefaultOfficialUserSpaces?: Map<
        string,
        HierarchyDataDescriptor[]
    >;

    @deserialize public AccessType?: UserSpaceAccessType;

    public static OnDeserialized(instance: UserAdminData) {
        const defaultOfficialUserSpaces = new Map<
            string,
            HierarchyDataDescriptor[]
        >();
        const data = instance.DefaultOfficialUserSpaces as unknown as {
            Key: string;
            Value: HierarchyDataDescriptor[];
        }[];
        data?.forEach((o) => defaultOfficialUserSpaces.set(o.Key, o.Value));
        instance.DefaultOfficialUserSpaces = defaultOfficialUserSpaces;
    }

    public static override copy(
        source: UserAdminData,
        target = new UserAdminData(),
    ) {
        if (!source) {
            throw 'no source';
        }
        super.copy(source, target);
        target.LicenseExpiresOn = source.LicenseExpiresOn;
        target.LicenseStartsOn = source.LicenseStartsOn;
        target.FirstConnectionOn = source.FirstConnectionOn;
        target.LastConnectionOn = source.LastConnectionOn;
        target.IsLicenseExpired = source.IsLicenseExpired;
        target.LicenseClientUid = source.LicenseClientUid;
        target.LicenseId = source.LicenseId;
        target.IsClientAdmin = source.IsClientAdmin;
        target.DefaultOfficialUserSpaces = new Map(
            source.DefaultOfficialUserSpaces,
        );

        return target;
    }
}
