import {
    deserialize,
    deserializeAs,
    inheritSerialization,
    serialize,
} from 'cerialize';
import {
    BaseServiceParameter,
    BaseServiceResult,
} from '@datagalaxy/data-access';
import { HierarchyDataDescriptor } from '@datagalaxy/dg-object-model';
import { UserAdminData } from './user-admin-data';
import { UserDto, UserPublicData } from '@datagalaxy/webclient/user/domain';
import { LicenseLevel } from '@datagalaxy/webclient/license/domain';
import { ClientPublicData } from '@datagalaxy/webclient/client/domain';

export class UserAttributeUsage {
    @deserialize DisplayName?: string;
    @deserialize IsCdp?: boolean;
    @deserialize DataTypeName?: string;
    @deserialize AttributeUid?: string;
    @deserialize AttributeKey?: string;
    @deserialize AttributeType?: string;
    @deserialize UsageCount?: number;
}

export class UserSpaceGovernanceDefaultUsage {
    @deserialize AttributeKey?: string;
    @deserialize SpaceDescriptor?: HierarchyDataDescriptor;
}

@inheritSerialization(BaseServiceResult)
export class PreDeleteUserResult extends BaseServiceResult {
    @deserialize
    SpaceGovernanceDefaultUsages?: UserSpaceGovernanceDefaultUsage[];
    @deserialize AttributeUsages?: UserAttributeUsage[];
    @deserialize IsMultiClientUser?: boolean;
    @deserialize CanBeDeleted?: boolean;
    @deserialize AuthorizedClients?: ClientPublicData[];

    constructor() {
        super();
    }
}

@inheritSerialization(BaseServiceParameter)
export class BaseUserServiceParameter extends BaseServiceParameter {
    @serialize public UserId: string;

    constructor(userId: string) {
        super();
        this.UserId = userId;
    }
}

@inheritSerialization(BaseServiceParameter)
export class CreateUserParameter extends BaseServiceParameter {
    @serialize public Email: string;
    @serialize public FirstName: string;
    @serialize public LastName: string;
    @serialize public Title: string;
    @serialize public Service: string;
    @serialize public Role: string;

    @serialize public IsClientAdministrator: boolean;
    @serialize public IsExplorerUser: boolean;
    @serialize public IsReaderUser: boolean;
    @serialize public IsSupportUser: boolean;
    @serialize public Password: string;
    @serialize public UseSetPasswordLinkSystem: boolean;
    @serialize public CallbackUrl?: string;
    @serialize public LicenseId?: number;
    @serialize public CreateLicense: boolean;
    @serialize public SetAdminAccessToSpaces: boolean;

    constructor(
        email: string,
        firstName: string,
        lastName: string,
        isClientAdmin: boolean,
        isExplorerUser: boolean,
        isReaderUser: boolean,
        isSupportUser: boolean,
        password: string,
        useSetPasswordLinkSystem: boolean,
        title: string,
        service: string,
        role: string,
        createLicense = true,
        setAdminAccessToSpaces = false,
    ) {
        super();
        this.Email = email;
        this.FirstName = firstName;
        this.LastName = lastName;
        this.IsClientAdministrator = isClientAdmin;
        this.IsExplorerUser = isExplorerUser;
        this.IsReaderUser = isReaderUser;
        this.IsSupportUser = isSupportUser;
        this.Password = password;
        this.UseSetPasswordLinkSystem = useSetPasswordLinkSystem;
        this.Title = title;
        this.Service = service;
        this.Role = role;
        this.CreateLicense = createLicense;
        this.SetAdminAccessToSpaces = setAdminAccessToSpaces;
    }
}

@inheritSerialization(BaseServiceResult)
export class CreateUserResult extends BaseServiceResult {
    @deserialize public UserId?: string;
    @deserialize public LoginToken?: string;
    @deserialize public IsErrorDuplicateEmail?: boolean;
    @deserialize public IsErrorBadClient?: boolean;
    @deserialize public IsErrorSecurityTokenExpired?: boolean;
    @deserializeAs(UserAdminData) public CreatedUser?: UserAdminData;
    /** NOTE: Only used and filled in Bunkerized deployments, when no Mail Server is available */
    @deserialize public CreatedUserLoginUrl?: string;

    constructor() {
        super();
    }
}

@inheritSerialization(BaseServiceParameter)
export class DeletePersonParameter extends BaseServiceParameter {
    @serialize public PersonId: string;

    constructor(personId: string) {
        super();
        this.PersonId = personId;
    }
}

@inheritSerialization(BaseServiceParameter)
export class BaseUserListParameter extends BaseServiceParameter {
    @serialize UserIds?: string[];
    @serialize Emails?: string[];
    @serialize IncludedLicenseLevels?: LicenseLevel[];
    @serialize StartIndex?: number;
    @serialize Size?: number;
    @serialize ExcludeDeleted?: boolean;
}

@inheritSerialization(BaseUserListParameter)
export class LoadKnownUsersParameter extends BaseUserListParameter {
    @serialize IncludedSpaceGuids?: string[];
}

@inheritSerialization(BaseServiceResult)
export class LoadKnownUsersResult extends BaseServiceResult {
    @deserializeAs(UserPublicData) Users!: UserPublicData[];
}

@inheritSerialization(BaseServiceResult)
export class AdminUserListResult extends BaseServiceResult {
    @deserializeAs(UserAdminData) Users!: UserAdminData[];
}

@inheritSerialization(BaseServiceParameter)
export class GetEntityUsersParameter extends BaseServiceParameter {
    @serialize public DataReferenceId: string;
    @serialize public DataTypeName: string;
    @serialize public AttributeKey?: string;

    constructor(
        dataReferenceId: string,
        dataTypeName: string,
        versionId: string,
        attributeKey?: string,
    ) {
        super();
        this.DataReferenceId = dataReferenceId;
        this.DataTypeName = dataTypeName;
        this.VersionId = versionId;
        this.AttributeKey = attributeKey;
    }
}

@inheritSerialization(BaseServiceResult)
export class GetEntityUsersResult extends BaseServiceResult {
    @deserializeAs(UserDto) public AvailableUserList!: UserDto[];
}

export interface IsUserLockedResult {
    IsLocked: boolean;
}
