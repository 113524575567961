<div *ngIf="isReadonly" class="read-only-field">
    <label [dxyEllipsisTooltip]="labelTooltip">
        {{ label }}
    </label>
    <span *ngIf="hintBeforeControl" class="mat-hint">{{ hint }}</span>
    <dxy-hyperlink-field-control
        #fieldControl
        [ngModel]="value"
        [readonly]="true"
        [tooltipCopy]="'UI.HtmlLink.copy' | translate"
        [tooltipCopied]="'UI.HtmlLink.copied' | translate"
    ></dxy-hyperlink-field-control>
    <span *ngIf="!hintBeforeControl" class="mat-hint">{{ hint }}</span>
</div>
<mat-form-field
    *ngIf="!isReadonly"
    floatLabel="always"
    floatLabel="always"
    [appearance]="fieldAppearance"
    [class.dg5-compact]="compact"
>
    <mat-label [dxyEllipsisTooltip]="labelTooltip">{{ label }}</mat-label>
    <span *ngIf="hintBeforeControl" class="mat-hint">{{ hint }}</span>
    <dxy-hyperlink-field-control
        #fieldControl
        [(ngModel)]="value"
        [ngModelOptions]="modelOptionsForUpdateOn"
        [errorStateMatcher]="errorStateMatcher"
        [disabled]="disabled"
        [urlLabel]="'UI.HtmlLink.uri' | translate"
        [tooltipCopy]="'UI.HtmlLink.copy' | translate"
        [tooltipCopied]="'UI.HtmlLink.copied' | translate"
        [nameLabel]="nameLabel"
        [editOnly]="editOnly"
        [inputsLabelClass]="inputsLabelClass"
        [required]="required"
    ></dxy-hyperlink-field-control>
    <mat-error>{{ errorMessage }}</mat-error>
</mat-form-field>
<span *ngIf="!hintBeforeControl" class="mat-hint">{{ hint }}</span>
