import {
    EntityType,
    HierarchyDataDescriptor,
    ModelType,
    ServerType,
} from '@datagalaxy/dg-object-model';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';

/* Do not import DataUtil or HddUtil here */

export class ModelerDataUtil {
    public static readonly modelerServerTypes = [
        ServerType.Model,
        ServerType.Container,
        ServerType.Table,
        ServerType.Column,
    ];

    public static isModelerServerType(serverType: ServerType) {
        return ModelerDataUtil.modelerServerTypes.includes(serverType);
    }

    public static isModelSource(entityType: EntityType) {
        return [
            EntityType.RelationalModel,
            EntityType.NonRelationalModel,
            EntityType.NoSqlModel,
            EntityType.TagBase,
        ].includes(entityType);
    }

    public static isModelRelational(
        serverType: ServerType,
        modelDescriptor: HierarchyDataDescriptor,
    ) {
        switch (serverType) {
            case ServerType.Model:
            case ServerType.Container:
            case ServerType.Table:
                return (
                    modelDescriptor.SubTypeName ==
                    ModelType[ModelType.Relational]
                );
        }
    }

    public static getOrderedModelerServerTypes() {
        return [
            ServerType.Model,
            ServerType.Container,
            ServerType.Table,
            ServerType.Column,
        ];
    }

    public static canBePartOfModel(serverType: ServerType) {
        switch (serverType) {
            case ServerType.Column:
            case ServerType.Container:
            case ServerType.DataType:
            case ServerType.ForeignKey:
            case ServerType.Table:
            case ServerType.PrimaryKey:
                return true;
            default:
                return false;
        }
    }

    public static isModelPrimaryKeyForeignKeyListsEnabled(
        entityItem: EntityItem,
    ) {
        return entityItem?.EntityType == EntityType.RelationalModel;
    }
    public static isModelSettingEnabled(entityItem: EntityItem) {
        return (
            !!entityItem &&
            entityItem.ServerType == ServerType.Model &&
            entityItem.SecurityData?.HasWriteAccess
        );
    }

    public static supportsStructureAsChild(serverType: ServerType) {
        return (
            serverType == ServerType.Model || serverType == ServerType.Container
        );
    }
}
