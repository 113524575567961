import { FocusMonitor } from '@angular/cdk/a11y';
import {
    Component,
    ElementRef,
    Input,
    NgZone,
    OnInit,
    Optional,
    Self,
} from '@angular/core';
import {
    FormGroupDirective,
    FormsModule,
    NgControl,
    NgForm,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatLegacyFormFieldControl as MatFormFieldControl } from '@angular/material/legacy-form-field';
import { DomUtil } from '@datagalaxy/core-util';
import { IHyperlinkModel } from '../..';
import { DxyBaseMatFormFieldControl } from '../../../base/DxyBaseMatFormFieldControl';
import { KeyboardUtil } from '@datagalaxy/utils';
import { TranslateModule } from '@ngx-translate/core';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { NgClass, NgIf } from '@angular/common';
import { TooltipDirective } from '@datagalaxy/ui/tooltip';

/*
    NOTE: Proper change detection is ensured by
    replacing the value instead of mutating it
*/

/** Behaviour: When not in readonly mode,
 * clicking on the control will activate the isEdit mode.
 * Also, the link icon (span) is focusable and will activate the edit mode
 * when pressing SpaceBar or Enter when it has focus. */
@Component({
    selector: 'dxy-hyperlink-field-control',
    templateUrl: './hyperlink-field-control.component.html',
    styleUrls: ['./hyperlink-field-control.component.scss'],
    providers: [
        {
            provide: MatFormFieldControl,
            useExisting: DxyHyperlinkFieldControlComponent,
        },
    ],
    standalone: true,
    imports: [
        NgIf,
        NgClass,
        MatLegacyInputModule,
        FormsModule,
        TranslateModule,
        TooltipDirective,
    ],
})
export class DxyHyperlinkFieldControlComponent
    extends DxyBaseMatFormFieldControl<IHyperlinkModel>
    implements OnInit
{
    /** When true, only the clickable link and the copy icon are visible */
    @Input() readonly: boolean;

    /** Label for the url input (By default is 'Url')*/
    @Input() urlLabel = 'Url';

    /** for the copy icon */
    @Input() tooltipCopy: string;
    /** for the copy icon, while being copied */
    @Input() tooltipCopied: string;

    /** Should be sommething like 'click to edit' */
    @Input() linkIconTooltip: string;

    /** Label for the name input (By default is empty and not shown)*/
    @Input() nameLabel: string;
    /** When true (and readonly is false) the inputs are always visible */
    @Input() editOnly: boolean;
    /** optional specific class for both input labels */
    @Input() inputsLabelClass: string;

    /** When true, only the name and url inputs are visible.
     *  When false, only the link icon and the clickable link are visible */
    public isEdit: boolean;

    public copyIconClass = 'glyph-file-copy';
    public copyIconTooltip: string;

    public get valueName(): string {
        return this.value?.name ?? '';
    }
    public set valueName(name: string) {
        this.value = { name, url: this.valueUrl };
    }

    public get valueUrl(): string {
        return this.value?.url ?? '';
    }
    public set valueUrl(url: string) {
        this.value = { name: this.valueName, url };
    }

    public get empty(): boolean {
        return !this.value || (!this.value.name && !this.value.url);
    }
    public get hasUrl(): boolean {
        return !!this.value?.url;
    }

    protected get dispatchNativeChangeEvent() {
        return true;
    }

    private isActivatingEdit = false;

    constructor(
        @Optional() @Self() ngControl: NgControl,
        @Optional() parentForm: NgForm,
        @Optional() parentFormGroup: FormGroupDirective,
        defaultErrorStateMatcher: ErrorStateMatcher,
        focusMonitor: FocusMonitor,
        elementRef: ElementRef,
        ngZone: NgZone,
    ) {
        super(
            'dxy-hyperlink-field-control',
            ngControl,
            parentForm,
            parentFormGroup,
            defaultErrorStateMatcher,
            focusMonitor,
            elementRef,
            ngZone,
        );
    }

    ngOnInit() {
        super.ngOnInit();
        this.copyIconTooltip = this.tooltipCopy;
        if (this.editOnly) {
            this.isEdit = true;
        }
    }

    public getUrl(): string {
        const url = this.valueUrl;
        // url with backslash is considered as a filepath
        if (url && !url.includes(':') && !url.includes('\\')) {
            return `http://${url}`;
        }
        return url;
    }

    public copyUrl(event: Event) {
        event.stopPropagation();
        this.copyIconClass = 'glyph-check-circle-filled';
        this.copyIconTooltip = this.tooltipCopied;
        DomUtil.copyToClipboard(this.getUrl());
        setTimeout(() => {
            this.copyIconClass = 'glyph-file-copy';
            this.copyIconTooltip = this.tooltipCopy;
        }, 999);
    }

    public focus() {
        const selector = this.isEdit ? '.input-name' : '.glyph-link';
        DomUtil.focusElement(this.elementRef, selector);
    }

    public blur() {
        const selector = this.isEdit ? '.input-name' : '.glyph-link';
        DomUtil.blurElement(this.elementRef, selector);
    }

    public onLinkIconKeyPress(event: KeyboardEvent) {
        if (
            !this.readonly &&
            !this.isEdit &&
            (KeyboardUtil.isSpaceBarKey(event) ||
                KeyboardUtil.isEnterKey(event))
        ) {
            event.preventDefault();
            this.activateEdit();
        }
    }

    public onFocusIn(event: FocusEvent) {
        if (this.focused && this.isInput(event.target)) {
            // allows focus switching between internal inputs using tab key
            this.log('onFocusIn-input');
            return;
        }
        super.onFocusIn(event);
    }

    public onContainerClick(event: PointerEvent): void {
        if (this.isInput(event.target) || this.isLink(event.target)) {
            return;
        }
        this.log('onContainerClick', event);
        if (this.isEdit) {
            this.focus();
        } else {
            this.activateEdit();
        }
    }

    /** Prevent material input EnterKey event to avoid focus change issue */
    public onInputEnterKeyPress(event: Event) {
        event.preventDefault();
    }

    protected preventBlurOnFocusOut(event: FocusEvent) {
        if (this.isActivatingEdit) {
            this.isActivatingEdit = false;
            //this.log('preventBlurOnFocusOut-isActivatingEdit')
            return true;
        }
        if (this.elementRef.nativeElement.contains(event.target as Element)) {
            if (!event.relatedTarget && this.isClickingInside) {
                //this.log('preventBlurOnFocusOut-innerElement')
                return true;
            }
            if (
                this.isInput(event.target) &&
                this.isInput(event.relatedTarget)
            ) {
                //this.log('preventBlurOnFocusOut-input-to-input')
                return true;
            }
        }
        if (!this.editOnly) {
            this.isEdit = false;
        }
        return false;
    }

    private isInput(eventTarget: EventTarget) {
        const classList = (eventTarget as Element)?.classList;
        return (
            classList &&
            (classList.contains('input-name') ||
                classList.contains('input-url'))
        );
    }
    private isLink(eventTarget: EventTarget) {
        return (eventTarget as Element)?.tagName.toLowerCase() == 'a';
    }

    private activateEdit() {
        this.isActivatingEdit = true;
        this.isEdit = true;
        setTimeout(() => {
            this.isActivatingEdit = false;
            this.focus();
        });
    }
}
