import { Inject, Injectable } from '@angular/core';
import {
    BACKEND_API_CONFIG,
    BackendApiService,
    BaseServiceParameter,
    BaseServiceResult,
    IApiConfig,
    RestApiService,
} from '@datagalaxy/data-access';
import {
    DeleteUserSettingValueParameter,
    DeleteUserSettingValueResult,
    GetUserSettingCategoryRoutesResult,
    GetUserSettingCategoryValuesParameter,
    GetUserSettingCategoryValuesResult,
    GetUserSettingsParameter,
    GetUserSettingsResult,
    GetUserSettingValueParameter,
    GetUserSettingValueResult,
    SetUserSettingsParameter,
    SetUserSettingsResult,
    SetUserSettingValueParameter,
    SetUserSettingValueResult,
} from './user-settings';
import { GetLicensesResult, UpdateUserLicenseParameter } from './user-license';
import {
    AdminUserListResult,
    BaseUserServiceParameter,
    CreateUserParameter,
    CreateUserResult,
    DeletePersonParameter,
    LoadKnownUsersParameter,
    LoadKnownUsersResult,
    PreDeleteUserResult,
    BaseUserListParameter,
    GetEntityUsersResult,
    GetEntityUsersParameter,
    IsUserLockedResult,
} from './user';
import { PersonalAccessTokenResult } from './personal-access-token';
import {
    GetCommunicationSettingsResult,
    SetCommunicationSettingParameter,
} from './user-communication-settings';
import {
    GenericPersonProfileDataResult,
    SetPersonProfileDataParameter,
    SetUserProfileImageParameter,
} from './user-profile';
import { GetInsightsResult } from './user-insights';
import {
    ChangePasswordParameter,
    ChangePasswordResult,
} from '@datagalaxy/webclient/auth/data-access';
import { Location } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class UserApiService {
    constructor(
        @Inject(BACKEND_API_CONFIG) private config: IApiConfig,
        private backendApiService: BackendApiService,
        private restApiService: RestApiService,
    ) {}

    public async loadKnownUsers(param: LoadKnownUsersParameter) {
        return await this.backendApiService.postPromise(
            'User/LoadKnownUsers',
            param,
            LoadKnownUsersResult,
        );
    }

    public async getUserSettings(param: GetUserSettingsParameter) {
        return await this.backendApiService.postPromise(
            'User/GetUserSettings',
            param,
            GetUserSettingsResult,
        );
    }

    public async setUserSettings(param: SetUserSettingsParameter) {
        return await this.backendApiService.postPromise(
            'User/SetUserSettings',
            param,
            SetUserSettingsResult,
        );
    }

    public async getUserSettingValue(param: GetUserSettingValueParameter) {
        return await this.backendApiService.postPromise(
            'User/GetUserSettingValue',
            param,
            GetUserSettingValueResult,
        );
    }

    public async setUserSettingValue(param: SetUserSettingValueParameter) {
        return await this.backendApiService.postPromise(
            'User/SetUserSettingValue',
            param,
            SetUserSettingValueResult,
        );
    }

    public async deleteUserSettingValue(
        param: DeleteUserSettingValueParameter,
    ) {
        return await this.backendApiService.postPromise(
            'User/DeleteUserSettingValue',
            param,
            DeleteUserSettingValueResult,
        );
    }

    public async getUserSettingCategoryValues(
        param: GetUserSettingCategoryValuesParameter,
    ) {
        return await this.backendApiService.postPromise(
            'User/GetUserSettingCategoryValues',
            param,
            GetUserSettingCategoryValuesResult,
        );
    }

    public async getUserSettingCategoryRoutes(
        param: GetUserSettingCategoryValuesParameter,
    ) {
        return await this.backendApiService.postPromise(
            'User/GetUserSettingCategoryRoutes',
            param,
            GetUserSettingCategoryRoutesResult,
        );
    }

    public async setUserProfileImage(param: SetUserProfileImageParameter) {
        return await this.backendApiService.postPromise(
            'User/SetUserProfileImage',
            param,
            GenericPersonProfileDataResult,
        );
    }

    public async setUserProfileData(param: SetPersonProfileDataParameter) {
        return await this.backendApiService.postPromise(
            'User/SetUserProfileData',
            param,
            GenericPersonProfileDataResult,
        );
    }

    public async getUserPersonalAccessToken(param: BaseUserServiceParameter) {
        return await this.backendApiService.postPromise(
            'User/GetPersonalAccessToken',
            param,
            PersonalAccessTokenResult,
        );
    }

    public async generatePersonalAccessToken(param: BaseServiceParameter) {
        return await this.backendApiService.postPromise(
            'User/CreatePersonalAccessToken',
            param,
            PersonalAccessTokenResult,
        );
    }

    public async regeneratePersonalAccessToken(param: BaseServiceParameter) {
        return await this.backendApiService.postPromise(
            'User/RegeneratePersonalAccessToken',
            param,
            PersonalAccessTokenResult,
        );
    }

    public async revokePersonalAccessToken(param: BaseUserServiceParameter) {
        return await this.backendApiService.postPromise(
            'User/RevokePersonalAccessToken',
            param,
            PersonalAccessTokenResult,
        );
    }

    public async getUserCommunicationSettings() {
        return await this.backendApiService.postPromise(
            'User/GetCommunicationSettings',
            new BaseServiceParameter(),
            GetCommunicationSettingsResult,
        );
    }

    public async setUserCommunicationSetting(
        param: SetCommunicationSettingParameter,
    ) {
        return await this.backendApiService.postPromise(
            'User/SetCommunicationSetting',
            param,
            GetCommunicationSettingsResult,
        );
    }

    public async createUser(param: CreateUserParameter) {
        return await this.backendApiService.postPromise(
            'User/CreateUser',
            param,
            CreateUserResult,
        );
    }

    public async deleteUser(param: BaseUserServiceParameter) {
        return await this.backendApiService.postPromise(
            'User/DeleteUser',
            param,
            BaseServiceResult,
        );
    }

    public async preDeleteUser(param: BaseUserServiceParameter) {
        return await this.backendApiService.postPromise(
            'User/PreDeleteUser',
            param,
            PreDeleteUserResult,
        );
    }

    public async removeUserFromClient(param: BaseUserServiceParameter) {
        return await this.backendApiService.postPromise(
            'User/RemoveUserFromClient',
            param,
            BaseServiceResult,
        );
    }

    public async getLicenses(param: BaseServiceParameter) {
        return await this.backendApiService.postPromise(
            'User/GetLicenses',
            param,
            GetLicensesResult,
        );
    }

    public async updateUserLicense(param: UpdateUserLicenseParameter) {
        return await this.backendApiService.postPromise(
            'User/UpdateUserLicense',
            param,
            CreateUserResult,
        );
    }

    public async removeLicenseForUser(param: DeletePersonParameter) {
        return await this.backendApiService.postPromise(
            'User/RemoveLicenseForUser',
            param,
            GenericPersonProfileDataResult,
        );
    }

    public getUserImage(imageHash: string) {
        return !imageHash
            ? ''
            : Location.joinWithSlash(
                  this.config.baseUrl,
                  `api/User/GetImage?hash=${encodeURIComponent(imageHash)}`,
              );
    }

    public async getUserInsights(param: BaseUserServiceParameter) {
        return await this.backendApiService.postPromise(
            'Insight/GetUserInsight',
            param,
            GetInsightsResult,
        );
    }

    public async loadUserList(param: BaseUserListParameter) {
        return await this.backendApiService.postPromise(
            'ClientAdmin/LoadUserList',
            param,
            AdminUserListResult,
        );
    }

    public async getEntityUsers(parameter: GetEntityUsersParameter) {
        return await this.backendApiService.postPromise(
            'Security/GetEntityUsers',
            parameter,
            GetEntityUsersResult,
        );
    }

    public async changeUserPassword(param: ChangePasswordParameter) {
        return await this.backendApiService.postPromise(
            'Account/ChangePassword',
            param,
            ChangePasswordResult,
        );
    }

    public async isUserLocked(userGuid: string) {
        return await this.restApiService.getPromise<IsUserLockedResult>(
            `user/${userGuid}/locked`,
        );
    }

    public async unlockUser(userGuid: string) {
        return await this.restApiService.putPromise(`user/${userGuid}/unlock`);
    }
}
