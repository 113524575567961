import { AuthenticationService } from '@datagalaxy/webclient/auth/feature';
import { inject } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivateFn,
    CanMatchFn,
    Router,
    RouterStateSnapshot,
} from '@angular/router';
import { AppConfigService } from '@datagalaxy/webclient/config';
import {
    CurrentUserService,
    UserApiService,
    UserInfoApiService,
} from '@datagalaxy/webclient/user/data-access';
import { isAccessDeniedError } from '@datagalaxy/data-access';
import { TranslateService } from '@ngx-translate/core';
import { filter, switchMap, take } from 'rxjs/operators';
import { ClientInfoApiService } from '@datagalaxy/webclient/client/data-access';
import { LanguageService } from '@datagalaxy/webclient/translate';

/**
 * Grant access to routes when authV2 is enabled
 */
export const AuthGuardMatch: CanMatchFn = () => {
    const appConfig = inject(AppConfigService);

    return appConfig.ENABLE_AUTH_V2;
};

/**
 * Grant access to routes when authV2 is disabled
 * Aka for legacy auth (classic login + SSO)
 */
export const DisabledAuthGuardMatch: CanMatchFn = () => {
    const appConfig = inject(AppConfigService);

    return !appConfig.ENABLE_AUTH_V2;
};

/**
 * Guard for routes that require authentication (AuthV2)
 */
export const AuthGuard: CanActivateFn = async (
    _route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
) => {
    const authService: AuthenticationService = inject(AuthenticationService);
    const router: Router = inject(Router);

    if (authService.authenticated) {
        return true;
    }

    const { authenticated, url } = await authService.checkAuth();

    if (authenticated) {
        if (url) {
            return router.parseUrl(url);
        }

        return true;
    }

    await authService.authorize(state.url);
    return false;
};

/**
 * Guard that fetch the user infos, update the current user service and
 * redirect to the error page if an error occurs
 */
export const AuthUserGuard: CanActivateFn = () => {
    const userInfoApiService = inject(UserInfoApiService);
    const clientInfoApiService = inject(ClientInfoApiService);
    const userApiService = inject(UserApiService);
    const currentUserService = inject(CurrentUserService);
    const authenticationService = inject(AuthenticationService);
    const languageService = inject(LanguageService);
    const translate = inject(TranslateService);
    const router = inject(Router);

    return authenticationService.authenticated$.pipe(
        filter((authenticated) => authenticated),
        take(1),
        switchMap(async () => {
            try {
                /**
                 * This call is executed sequentially because the backend creates the client's
                 * default space when the first user logs in. Therefore, we must fetch user
                 * information before fetching client information to prevent duplication,
                 * which would lead to an unstable state.
                 */
                const userInfoResult = await userInfoApiService.getUserInfo();

                const [clientInfoResult, CommunicationSettingsResult] =
                    await Promise.all([
                        clientInfoApiService.getClientInfo(),
                        userApiService.getUserCommunicationSettings(),
                    ]);

                currentUserService.init({
                    userInfo: userInfoResult.UserInfo,
                    clientInfo: clientInfoResult.ClientInfo,
                    communicationSettings:
                        CommunicationSettingsResult.CommunicationSettings,
                });

                languageService.setLanguage(
                    userInfoResult.UserInfo.LanguageCode,
                );

                return true;
            } catch (error) {
                const errorMessageKey = getErrorMessageKey(error);

                return router.navigate(['/error'], {
                    queryParams: {
                        errorMessage: translate.instant(errorMessageKey),
                        accessLevel: 'login',
                    },
                });
            }
        }),
    );
};

function getErrorMessageKey(error: unknown): string {
    if (isAccessDeniedError(error) && error?.error?.error?.ErrorCodeString) {
        return `UI.Login.${error.error.error.ErrorCodeString}`;
    }
    return 'UI.Login.lblServerError';
}
