import * as moment from 'moment';
import { BaseService } from '@datagalaxy/core-ui';
import { CookieService } from 'ngx-cookie-service';
import { Injectable } from '@angular/core';
import { CoreUtil } from '@datagalaxy/core-util';

/**
 * @deprecated to delete post authV2
 */
@Injectable({ providedIn: 'root' })
export class ClientStorageService extends BaseService {
    private readonly cookey = 'DG_COOKIE';
    private cookieData: CookieStoreData;

    constructor(private cookieService: CookieService) {
        super();
    }

    public storeCookieOnLogin(loginData: ICookieLoginData) {
        try {
            const cookieData = new CookieStoreData();

            if (
                this.cookieData &&
                loginData.login === this.cookieData?.loginData?.login
            ) {
                CoreUtil.merge(cookieData, this.cookieData);
            }

            cookieData.loginData = loginData;

            this.saveCookie(cookieData);
            this.cookieData = cookieData;
        } catch (e) {
            this.warn(e);
        }
    }

    public getCookieKey(key: string) {
        return this.cookieData?.[key];
    }

    public dropCookieKey(key: string) {
        if (this.cookieData && key) {
            delete this.cookieData[key];
            this.saveCookie(this.cookieData);
        }
    }

    public updateCookieOnLogout() {
        if (!this.cookieData) {
            return;
        }

        this.cookieData.loginData.loginToken = '';
        this.cookieData.loginData.useAutoLogin = false;
        this.saveCookie(this.cookieData);
    }

    public clearCookie() {
        this.log('clearCookie');
        this.cookieData = null;
        window.localStorage?.clear();
        this.cookieService.delete(this.cookey);
    }

    public readCookieLoginData(): ICookieLoginData {
        return this.readCookie()?.loginData;
    }

    private saveCookie(cookieData: CookieStoreData) {
        this.log('saveCookie', cookieData);
        const expireDate = moment().add(3, 'months').toDate();
        this.cookieService.set(this.cookey, JSON.stringify(cookieData), {
            expires: expireDate,
            secure: true,
        });
    }

    private readCookie() {
        const cookieString = this.cookieService.get(this.cookey);
        this.log('readCookie', this.cookey, cookieString);
        if (!cookieString) {
            return null;
        }
        return (this.cookieData = JSON.parse(cookieString));
    }
}

export class CookieStoreData {
    public loginData: ICookieLoginData;
    /** @deprecated Legacy (moved to userSettingValue) */
    public userViewType: string;
    public languageCode: string;
}
export interface ICookieLoginData {
    useAutoLogin: boolean;
    login: string;
    loginToken: string;
}
