import { autoserialize, autoserializeAs } from 'cerialize';
import { getContextId } from '@datagalaxy/utils';
import { ServerType } from '@datagalaxy/dg-object-model';

export interface IHasReferenceId {
    ReferenceId: string;
}

export interface IHas$type {
    /** .Net type, generated by .Net serialization */
    $type: string;
}

export const SystemContextId = '00000000-0000-0000-0000-000000000040';

/** Base class for objects managed by masterDataService */
export abstract class BaseMasterData implements IHas$type {
    @autoserialize protected $id!: string; //#archi-masterdata-$id
    /* #archi-masterdata-$type Used in MasterDataService
        for serialization: in saveData()/forAddNewDataManualParent: on SaveDataParamItem.DataContent,  DataType, Column.
        Will be removed when loadData and saveData api routes have been replaced
    */
    @autoserialize $type!: string;

    @autoserializeAs(String, 'ReferenceId') private _referenceId?: string;

    /** used to remove object from MasterData, used only for Table|Column|PrimaryKey|ForeignKey */
    @autoserialize public IsDeleted?: boolean;

    public abstract readonly ServerType: ServerType;

    public get ReferenceId() {
        return this._referenceId ?? this.$id;
    }

    public get isSystem() {
        return getContextId(this.ReferenceId) === SystemContextId;
    }

    constructor(id?: string, $type?: string) {
        // note: for savedata DataContent deserialization on the server,
        // $id and $type properties must be the first on the object, in that order.
        // So we have to set them, even with undefined value
        this.$id = id || '';
        this.$type = $type || '';
    }

    public setId(id: string) {
        this.$id = id;
        delete this._referenceId;
    }
}

type TBase = BaseMasterData;

/** Interface for an object producing a T object (cf meta-data.service) */
export interface IIsData<T extends TBase = TBase> {
    /** Instanciates a T object based on data retrieved during deserialization */
    asData(): T;
}

export class ITypeMetadata {
    /** (!! used for back-end by savedata) Name of the property referencing the child(ren), in the parent */
    parentPropertyName?: string;
    /** Name of the property referencing the parent, in the object */
    childPropertyName?: string;
}
