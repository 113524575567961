<div *ngIf="isReadonly" class="read-only-field">
    <label [dxyEllipsisTooltip]="labelTooltip">
        {{ label }}
    </label>
    <span *ngIf="hintBeforeControl" class="mat-hint">{{ hint }}</span>
    <dxy-multi-select-field-control
        [data]="data"
        [required]="required"
        [readonly]="readonly"
        (selectedItemClick)="onSelectedItemClick($event)"
    >
        <ng-container
            [ngTemplateOutlet]="selectedValueContentTemplate"
            selectedValueContent
        ></ng-container>
    </dxy-multi-select-field-control>
    <div class="read-only-separator"></div>
    <span *ngIf="!hintBeforeControl" class="mat-hint">{{ hint }}</span>
</div>
<mat-form-field
    *ngIf="!isReadonly"
    floatLabel="always"
    appearance="standard"
    [class.dg5-compact]="compact"
>
    <mat-label [dxyEllipsisTooltip]="labelTooltip">
        {{ label }}
    </mat-label>
    <span *ngIf="hintBeforeControl" class="mat-hint">{{ hint }}</span>
    <dxy-multi-select-field-control
        [disabled]="disabled"
        [readonly]="readonly"
        [openMenuOnFocus]="openMenuOnFocus"
        [maxDisplayedOptions]="maxDisplayedOptions"
        [menuTakeFullWidth]="menuTakeFullWidth"
        [data]="data"
        [(ngModel)]="value"
        [required]="required"
        (onMenuToggle)="onMenuToggle($event)"
        (selectedItemClick)="onSelectedItemClick($event)"
        [errorStateMatcher]="errorStateMatcher"
        [menuHasDynamicHeight]="menuHasDynamicHeight"
    >
        <ng-container
            [ngTemplateOutlet]="selectedValueContentTemplate"
            selectedValueContent
        ></ng-container>
        <ng-content select="[emptyResult]" emptyResult></ng-content>
        <ng-content
            select="[headerBeforeSearch]"
            headerBeforeSearch
        ></ng-content>
        <ng-content select="[headerAfterSearch]" headerAfterSearch></ng-content>
    </dxy-multi-select-field-control>
    <mat-error>{{ errorMessage }}</mat-error>
</mat-form-field>
<span *ngIf="!hintBeforeControl" class="mat-hint">{{ hint }}</span>

<ng-template #selectedValueContentTemplate>
    <ng-content select="[selectedValueContent]"></ng-content>
</ng-template>
