import {
    Component,
    ElementRef,
    Input,
    NgZone,
    OnInit,
    Optional,
    Self,
    ViewChild,
} from '@angular/core';
import { FormsModule, NgControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DxyBaseMatFormFieldControl } from '../../base';
import { DxyBaseFocusableFieldComponent } from '@datagalaxy/ui/fields';
import { DxyCheckboxFieldControlComponent } from '../controls/checkbox-field-control/checkbox-field-control.component';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import {
    EllipsisTooltipDirective,
    TooltipDirective,
} from '@datagalaxy/ui/tooltip';
import { NgIf } from '@angular/common';

@Component({
    selector: 'dxy-field-boolean',
    templateUrl: 'field-boolean.component.html',
    styleUrls: ['field-boolean.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        EllipsisTooltipDirective,
        MatLegacyFormFieldModule,
        DxyCheckboxFieldControlComponent,
        FormsModule,
        TooltipDirective,
    ],
})
export class DxyFieldBooleanComponent
    extends DxyBaseFocusableFieldComponent<boolean>
    implements OnInit
{
    @Input() beta: boolean;

    /** label tooltip for information */
    @Input() infoTooltip: string;
    @Input() placeholder: string;
    @Input() textValueTrue: string;
    @Input() textValueFalse: string;
    @Input() allowIndeterminate: boolean;
    @Input() textValueIndeterminate: string;

    public get label() {
        return this.getLabel(this.translate);
    }
    public get labelTooltip() {
        return this.getLabelTooltip(this.translate);
    }
    public get errorMessage() {
        return this.getErrorMessage(this.translate);
    }
    public get valueText() {
        return this.value ? this.textValueTrue : this.textValueFalse;
    }

    @ViewChild('fieldControl')
    protected fieldControl: DxyBaseMatFormFieldControl<boolean>;

    protected get empty() {
        return this.fieldControl?.empty;
    }

    constructor(
        private translate: TranslateService,
        elementRef: ElementRef<HTMLElement>,
        ngZone: NgZone,
        @Optional() @Self() ngControl: NgControl,
    ) {
        super(elementRef, ngZone, ngControl);
    }

    ngOnInit() {
        super.ngOnInit();
        this.textValueTrue ??= this.translate.instant(
            'DgServerTypes.BooleanValue.True',
        );
        this.textValueFalse ??= this.translate.instant(
            'DgServerTypes.BooleanValue.False',
        );
        this.textValueIndeterminate ??= '';
    }

    protected preventBlur(event: FocusEvent, isClickingInside: boolean) {
        // prevent the blur event to be emitted on the matCheckbox's focusout
        // when clicking in the field but outside the field control
        return isClickingInside;
    }
}
