import { inject, Injectable } from '@angular/core';
import {
    DeleteUserSettingValueParameter,
    GetUserSettingCategoryValuesParameter,
    GetUserSettingValueParameter,
    SetUserSettingValueParameter,
} from './user-settings.queries';
import { UserApiService } from '../user-api.service';

@Injectable({
    providedIn: 'root',
})
export class UserSettingsService {
    private userApiService = inject(UserApiService);

    public async getUserSettingValue(
        category: string,
        route: string,
        goldenUserId?: string,
    ) {
        const result = await this.userApiService.getUserSettingValue(
            new GetUserSettingValueParameter(category, route),
        );

        if (result.Value || !goldenUserId) {
            return result;
        }

        return this.userApiService.getUserSettingValue(
            new GetUserSettingValueParameter(category, route, goldenUserId),
        );
    }

    public setUserSettingValue(
        category: string,
        route: string,
        value: string,
        meta?: string,
    ) {
        return this.userApiService.setUserSettingValue(
            new SetUserSettingValueParameter(category, route, value, meta),
        );
    }

    public deleteUserSettingValue(category: string, route: string) {
        return this.userApiService.deleteUserSettingValue(
            new DeleteUserSettingValueParameter(category, route),
        );
    }

    public getUserSettingCategoryValues(category: string) {
        return this.userApiService.getUserSettingCategoryValues(
            new GetUserSettingCategoryValuesParameter(category),
        );
    }

    public getUserSettingCategoryRoutes(category: string) {
        return this.userApiService.getUserSettingCategoryRoutes(
            new GetUserSettingCategoryValuesParameter(category),
        );
    }
}
