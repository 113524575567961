<div *ngIf="isReadonly" class="read-only-field">
    <label>
        <span [dxyEllipsisTooltip]="labelTooltip">
            {{ label }}
        </span>
        <ng-container *ngTemplateOutlet="labelActions"></ng-container>
    </label>
    <span *ngIf="hintBeforeControl" class="mat-hint">{{ hint }}</span>
    <p *ngIf="!multiLine" class="value-container">{{ value }}</p>
    <textarea
        *ngIf="multiLine"
        matInput
        #fieldControl="matInput"
        #inputRef
        [ngModel]="value"
        [cdkTextareaAutosize]="autoSizeMultiLine"
        [cdkAutosizeMaxRows]="autoSizeMultiLineMaxRows!"
        readonly
    ></textarea>
    <div class="read-only-separator"></div>
    <span *ngIf="!hintBeforeControl" class="mat-hint">{{ hint }}</span>
</div>
<mat-form-field
    *ngIf="!isReadonly"
    floatLabel="always"
    [appearance]="fieldAppearance!"
    [class.dg5-compact]="compact"
>
    <mat-label>
        <span [dxyEllipsisTooltip]="labelTooltip">
            {{ label }}
        </span>
        <span
            *ngIf="infoTooltip"
            class="glyph-info"
            [dxyTooltip]="infoTooltip"
        ></span>
        <ng-container *ngTemplateOutlet="labelActions"></ng-container>
    </mat-label>
    <span *ngIf="hintBeforeControl" class="mat-hint">{{ hint }}</span>
    <input
        *ngIf="!useTextArea"
        type="text"
        matInput
        #fieldControl="matInput"
        #inputRef
        [(ngModel)]="value"
        [ngModelOptions]="modelOptionsForUpdateOn"
        [errorStateMatcher]="errorStateMatcher"
        [placeholder]="placeholder"
        [disabled]="disabled"
        [maxLength]="maxLength"
        [matAutocomplete]="matAutocomplete!"
        [matAutocompleteDisabled]="!matAutocomplete"
        [required]="required"
        [dxyDataTestId]="dataTestId"
    />
    <textarea
        *ngIf="useTextArea"
        matInput
        #fieldControl="matInput"
        #inputRef
        [errorStateMatcher]="errorStateMatcher"
        [(ngModel)]="value"
        [disabled]="disabled"
        [maxLength]="maxLength"
        [matAutocomplete]="matAutocomplete!"
        [matAutocompleteDisabled]="!matAutocomplete"
        [placeholder]="placeholder"
        [ngModelOptions]="modelOptionsForUpdateOn"
        [cdkTextareaAutosize]="autoSizeMultiLine"
        [cdkAutosizeMaxRows]="autoSizeMultiLineMaxRows!"
        [required]="required"
        [dxyDataTestId]="dataTestId"
    ></textarea>
    <mat-hint *ngIf="showMaxLengthHint" align="end"
        >{{ value?.length || 0 }}/{{ maxLength }}</mat-hint
    >
    <mat-error>{{ errorMessage }}</mat-error>
</mat-form-field>
<span *ngIf="!hintBeforeControl" class="mat-hint">{{ hint }}</span>

<ng-template #labelActions>
    <ng-content select="[label-actions]"></ng-content>
</ng-template>
