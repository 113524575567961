<div *ngIf="isReadonly" class="read-only-field">
    <label [dxyEllipsisTooltip]="labelTooltip">
        {{ label }}
    </label>
    <span *ngIf="hintBeforeControl" class="mat-hint">{{ hint }}</span>
    <div class="value-container">
        <span class="glyph-upload"></span>
        <div class="files-list">
            <div *ngFor="let file of value" class="file-container">
                <p class="file-name">{{ file.name }}</p>
            </div>
        </div>
    </div>
    <div class="read-only-separator"></div>
    <span *ngIf="!hintBeforeControl" class="mat-hint">{{ hint }}</span>
</div>
<div
    *ngIf="!isReadonly"
    class="file-upload-container"
    (click)="onContainerClick()"
>
    <mat-form-field
        floatLabel="always"
        [appearance]="fieldAppearance"
        [class.dg5-compact]="compact"
    >
        <mat-label [dxyEllipsisTooltip]="labelTooltip">
            {{ label }}
            <span
                *ngIf="infoTooltip"
                class="glyph-info"
                [dxyTooltip]="infoTooltip"
            ></span>
        </mat-label>
        <span *ngIf="hintBeforeControl" class="mat-hint">{{ hint }}</span>
        <dxy-file-upload-field-control
            [(ngModel)]="value"
            [ngModelOptions]="modelOptionsForUpdateOn"
            [errorStateMatcher]="errorStateMatcher"
            [disabled]="disabled"
            [allowsMultiple]="allowsMultiple"
            [maxFileSize]="maxFileSize"
            [checkFiles]="checkFilesFn"
            [required]="required"
        ></dxy-file-upload-field-control>
        <mat-error>{{ errorMessage }}</mat-error>
    </mat-form-field>
</div>
<span *ngIf="!hintBeforeControl" class="mat-hint">{{ hint }}</span>
