import { Subject } from 'rxjs';
import { CollectionsHelper, StringUtil } from '@datagalaxy/core-util';
import { IEntityForm } from '../entity/interfaces/entity-form.interface';
import { AttributeFieldInfo } from './attribute.types';
import { BaseService } from '@datagalaxy/core-ui';
import { Injectable, NgZone } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
    EntityType,
    EntityTypeUtil,
    FirstClassType,
    FirstClassTypeUtil,
    IEntityIdentifier,
    ObjectLinkType,
    ServerType,
} from '@datagalaxy/dg-object-model';
import { GlyphUtil } from '../util/GlyphUtil';
import { DataUtil } from '../util/DataUtil';
import { ViewTypeService } from '../../services/viewType.service';
import { UserService } from '../../services/user.service';
import { TechnologyService } from '../../client-admin/services/technology.service';
import { DataQualityResult } from '@datagalaxy/webclient/data-quality/data-access';
import {
    GetSpaceGovernanceUsersParameter,
    WorkSpaceApiService,
} from '@datagalaxy/webclient/workspace/data-access';
import {
    AttributeApiService,
    GetAttributesParameter,
    GetAttributeTagsParameter,
    SetTextQualityVoteParameter,
} from '@datagalaxy/webclient/attribute/data-access';
import { FilteredEntityItem } from '@datagalaxy/webclient/search/data-access';
import {
    GetEntityUsersParameter,
    UserApiService,
} from '@datagalaxy/webclient/user/data-access';
import { EntityTypeUtils } from '@datagalaxy/webclient/entity/utils';
import {
    getAttributeGlyphClass,
    getAttributeTypeGlyphClass,
} from '@datagalaxy/webclient/attribute/feature';
import { WorkspaceIdentifier } from '@datagalaxy/webclient/workspace/utils';
import { ArrayUtils, ZoneUtils } from '@datagalaxy/utils';
import {
    IWorkspaceIdentifier,
    SpaceSecurityProfileType,
} from '@datagalaxy/webclient/workspace/domain';
import {
    AttributeDTO,
    AttributeMetaInfo,
    AttributeMetaType,
    AttributeMetaValue,
    AttributeTagDTO,
    EntityLifecycleStatus,
    HierarchyKind,
    TextQualityVoteStatus,
} from '@datagalaxy/webclient/attribute/domain';
import { DgModule } from '@datagalaxy/shared/dg-module/domain';
import { ServerConstants } from '@datagalaxy/shared/server/domain';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import PropertyName = ServerConstants.PropertyName;
import { LanguageService } from '@datagalaxy/webclient/translate';

/**
 * ## Role
 * Service that contains all the caching logic and the data logic for the Attributes, that define every Type in the system
 *
 * ## Note
 * The goal is, once loading for the first time the complete attributes for a Type, to avoid having to load it again.
 * This way, we can remove it from all calls currently used such as LoadEntity, LoadMultiEntity, and even GetEntityAttributes itself
 *
 */

@Injectable({ providedIn: 'root' })
export class AttributeDataService extends BaseService {
    private debugDetailed = false;

    //#region static

    public static readonly dataProcessingItemAttributes = [
        ServerConstants.PropertyName.ObjectLinkHasInput,
        ServerConstants.PropertyName.ObjectLinkHasOutput,
        ServerConstants.PropertyName.DisplayName,
        ServerConstants.PropertyName.TechnicalName,
        ServerConstants.PropertyName.Description,
        ServerConstants.PropertyName.DpiType,
        ServerConstants.AttributeConstants.LongDescription,
    ];

    public static getSystemListTranslationKey(
        attributeKey: string,
        dataServerTypeName: string,
    ) {
        switch (attributeKey) {
            case ServerConstants.PropertyName.EntityStatus:
            case ServerConstants.PropertyName.PersonalDataClass:
            case ServerConstants.PropertyName.Technology:
            case ServerConstants.PropertyName.Module:
                return attributeKey;
            default:
                return `${dataServerTypeName}${attributeKey}`;
        }
    }

    public static isReplaceOptionSupported(attribute: AttributeMetaInfo) {
        switch (attribute?.AttributeType) {
            case AttributeMetaType.EntityLinkShortcut:
                return attribute.IsMultiValue;
            case AttributeMetaType.ManagedTag:
            case AttributeMetaType.PersonReference:
            case AttributeMetaType.Hierarchy:
            case AttributeMetaType.ClientTag:
            case AttributeMetaType.MultiValueList:
            case AttributeMetaType.StewardUserReference:
            case AttributeMetaType.UserReference:
                return true;
            default:
                return false;
        }
    }

    public static isShortcutAttribute(attributeType: AttributeMetaType) {
        switch (attributeType) {
            case AttributeMetaType.EntityLinkShortcut:
                return true;
            default:
                return false;
        }
    }

    public static isOfficialRoleAttribute(attribute: AttributeMetaInfo) {
        switch (attribute?.AttributeType) {
            case AttributeMetaType.StewardUserReference:
                return true;

            default:
                return false;
        }
    }

    public static isDataOwnerOrDataStewardAttribute(
        attribute: AttributeMetaInfo,
    ) {
        return AttributeDataService.isDataOwnerOrDataStewardAttributeKey(
            attribute?.AttributeKey,
        );
    }
    public static isDataOwnerOrDataStewardAttributeKey(attributeKey: string) {
        switch (attributeKey) {
            case ServerConstants.PropertyName.DataOwners:
            case ServerConstants.PropertyName.DataStewards:
                return true;
            default:
                return false;
        }
    }

    public static getOrderedEntityStatusList() {
        return [
            EntityLifecycleStatus.Proposed,
            EntityLifecycleStatus.InRevision,
            EntityLifecycleStatus.InValidation,
            EntityLifecycleStatus.Validated,
            EntityLifecycleStatus.Obsolete,
        ];
    }

    public static getOrderedSpaceSecurityProfileType() {
        return [
            SpaceSecurityProfileType.Open,
            SpaceSecurityProfileType.Limited,
            SpaceSecurityProfileType.Private,
        ];
    }

    public static readonly logicalParentAttributeKeys = [
        ServerConstants.PropertyName.LogicalParentId,
        ServerConstants.PropertyName.FilterDirectParentId,
        ServerConstants.PropertyName.Parents,
    ];
    public static isLogicalParentAttributeKey(attributeKey: string) {
        return AttributeDataService.logicalParentAttributeKeys.includes(
            attributeKey,
        );
    }

    public static readonly spaceGovUserAttributeKeys = [
        ServerConstants.PropertyName.DataOwners,
        ServerConstants.PropertyName.DataStewards,
        'CdoUsers',
        'DpoUsers',
        'CisoUsers',
        'ExpertUsers',
    ];
    public static isSpaceGovUserAttributeKey(attributeKey: string) {
        return AttributeDataService.spaceGovUserAttributeKeys.includes(
            attributeKey,
        );
    }

    public static isUserOrPersonAttribute(
        attributeMetaType: AttributeMetaType,
    ) {
        switch (attributeMetaType) {
            case AttributeMetaType.UserGuid:
            case AttributeMetaType.UserReference:
            case AttributeMetaType.PersonReference:
            case AttributeMetaType.StewardUserReference:
                return true;
            default:
                return false;
        }
    }

    public static isTagAttribute(attributeMetaType: AttributeMetaType) {
        switch (attributeMetaType) {
            case AttributeMetaType.ManagedTag:
            case AttributeMetaType.ClientTag:
            case AttributeMetaType.Hierarchy:
            case AttributeMetaType.MultiValueList:
                return true;
            default:
                return false;
        }
    }

    public static isTagOrUserOrPersonAttribute(
        attributeMetaType: AttributeMetaType,
    ) {
        return (
            AttributeDataService.isTagAttribute(attributeMetaType) ||
            AttributeDataService.isUserOrPersonAttribute(attributeMetaType)
        );
    }

    public static isIconAttribute(attributeMetaType: AttributeMetaType) {
        return attributeMetaType == AttributeMetaType.Technology;
    }

    public static isEntityRefAttribute(attributeMetaType: AttributeMetaType) {
        switch (attributeMetaType) {
            case AttributeMetaType.EntityReference:
            case AttributeMetaType.ReferenceList:
            case AttributeMetaType.ObjectLink:
            case AttributeMetaType.AllLinkedData:
                return true;
            default:
                return false;
        }
    }

    public static isSystemDataTypeRefAttribute(ami: AttributeMetaInfo) {
        return (
            ami &&
            !ami.IsCdp &&
            ami.AttributeType == AttributeMetaType.Reference &&
            ami.AttributeKey == 'DataTypeRef'
        );
    }

    public static getEntityTypeAllowedChildTypes(entityType: EntityType) {
        const etm = EntityTypeUtil.getMapping(entityType);
        return etm?.ChildRules?.[0]?.AllowedChildrenTypes ?? [];
    }

    public static getDefaultAttributesForGrid(serverType: ServerType) {
        switch (serverType) {
            case ServerType.Property:
            case ServerType.DataProcessing:
            case ServerType.SoftwareElement:
            case ServerType.Model:
            case ServerType.Container:
            case ServerType.Table:
            case ServerType.Column:
                return [
                    'DisplayName',
                    'Description',
                    'Type',
                    'CreationTime',
                    'LastModificationTime',
                ];

            /** FOR REFERENCE:
             *
             *   Before removing EntityConfigService
             *
             *   Old values for Table:
             *
             *   defaultAttributes: [
             *       "Type",
             *       "TechnicalName",
             *       "DisplayName",
             *       "TechnicalComments",
             *       "Description"
             *   ]
             *
             *   Old values for Column:
             *
             *   defaultAttributes: [
             *       "Type",
             *       "TechnicalName",
             *       "DisplayName",
             *       "TableTechnicalName",
             *       "TableDisplayName",
             *       "TechnicalComments",
             *       "Description",
             *       "DataTypeDisplayName"
             *   ]
             */
        }
    }

    /**
     * Reference method used throughout the client in order to display in a deterministic order the list of sub-types for a
     * given ServerType.
     * */
    public static getOrderedAdminSubTypes(
        serverType: ServerType,
    ): Array<EntityType> {
        switch (serverType) {
            case ServerType.Property:
                return [
                    EntityType.Universe,
                    EntityType.Concept,
                    EntityType.BusinessTerm,
                    EntityType.IndicatorGroup,
                    EntityType.Indicator,
                    EntityType.Dimension,
                    EntityType.DimensionGroup,
                    EntityType.BusinessDomain,
                    EntityType.BusinessDomainGroup,
                    EntityType.ReferenceData,
                    EntityType.ReferenceDataValue,
                ];
            case ServerType.Model:
                return [
                    EntityType.RelationalModel,
                    EntityType.NonRelationalModel,
                    EntityType.NoSqlModel,
                    EntityType.TagBase,
                ];
            case ServerType.DataProcessing:
                return [EntityType.DataFlow, EntityType.DataProcessing];
            case ServerType.SoftwareElement:
                return [
                    EntityType.Application,
                    EntityType.Screen,
                    EntityType.Dashboard,
                    EntityType.Report,
                    EntityType.Algorithm,
                    EntityType.DataSet,
                    EntityType.OpenDataSet,
                    EntityType.Process,
                    EntityType.Use,
                    EntityType.Feature,
                    EntityType.UsageField,
                    EntityType.UsageComponent,
                ];
            case ServerType.Container:
                return [
                    EntityType.Model,
                    EntityType.Directory,
                    EntityType.Equipment,
                ];
            case ServerType.Table:
                return [
                    EntityType.Table,
                    EntityType.View,
                    EntityType.File,
                    EntityType.Document,
                    EntityType.Tag,
                    EntityType.SubStructure,
                ];
            case ServerType.Column:
                return [EntityType.Column, EntityType.Field];

            case ServerType.Diagram:
                return [EntityType.FreeDiagram, EntityType.PhysicalDiagram];
        }
    }

    public static getChildAvailableSubTypes(
        entityType: EntityType,
    ): Array<EntityType> {
        return AttributeDataService.getEntityTypeAllowedChildTypes(entityType);
    }

    /** returns true if the given attribute is a non-readonly, non-mandatory & text attribute */
    public static isFreeTextAttribute(ami: AttributeMetaInfo) {
        return !ami.IsReadOnly && !ami.IsMandatory && ami.isText;
    }

    public static isMandatoryOrNonNullableAttribute(
        attributeMeta: AttributeMetaInfo,
    ) {
        if (attributeMeta.IsMandatory) {
            return true;
        }
        switch (attributeMeta.AttributeKey) {
            case 'EntityStatus':
            case 'CreationTime':
            case ServerConstants.PropertyName.Technology:
                return true;
        }
        return false;
    }
    public static getAttributeTypeTranslateKey(
        attributeType: AttributeMetaType,
    ) {
        return `DgServerTypes.AttributeType.${AttributeMetaType[attributeType]}`;
    }

    public static getEntityDetailsAttributes(serverType?: ServerType) {
        const headerAttributes = [
            ServerConstants.PropertyName.EntityStatus,
            ServerConstants.PropertyName.DisplayName,
            ServerConstants.PropertyName.DataOwners,
            ServerConstants.PropertyName.DataStewards,
        ];

        if (serverType != ServerType.Property) {
            headerAttributes.push(ServerConstants.PropertyName.TechnicalName);
        }

        const getTabAttributes = (dgModule: DgModule) => {
            switch (dgModule) {
                case DgModule.Glossary:
                    return [
                        ServerConstants.PropertyName.ImplementationLinkCount,
                        ServerConstants.PropertyName.EntityLinkCount,
                        ServerConstants.PropertyName.EntityDiagramCount,
                    ];
                case DgModule.Catalog:
                    return [
                        ServerConstants.PropertyName.EntityDiagramCount,
                        ServerConstants.PropertyName.TableColumnCount,
                        ServerConstants.PropertyName.ContainerContainerCount,
                        ServerConstants.PropertyName.ContainerTableCount,
                        ServerConstants.PropertyName.ModelPrimaryKeyCount,
                        ServerConstants.PropertyName.ModelForeignKeyCount,
                        ServerConstants.PropertyName.EntityLinkCount,
                    ];
                case DgModule.Processing:
                    return [
                        ServerConstants.PropertyName.EntityLinkCount,
                        ServerConstants.PropertyName.EntityDiagramCount,
                    ];
                case DgModule.Usage:
                    return [
                        ServerConstants.PropertyName.SoftwareTotalLinkCount,
                        ServerConstants.PropertyName.EntityLinkCount,
                        ServerConstants.PropertyName.EntityDiagramCount,
                    ];
                default:
                    return [];
            }
        };

        if (serverType) {
            const dgModule = DataUtil.getModuleFromServerType(serverType);
            const tabAttributes = getTabAttributes(dgModule);

            return [...headerAttributes, ...tabAttributes];
        }
        return headerAttributes;
    }

    private static getServerTypesFromAttributePaths(attributePaths: string[]) {
        return CollectionsHelper.distinct(
            attributePaths
                .map(
                    (ap) =>
                        AttributeDataService.getInfosFromAttributePath(ap, true)
                            ?.serverType,
                )
                .filter((o) => o),
        );
    }
    private static getInfosFromAttributePath(
        attributePath: string,
        usePropertyInsteadOfAllTypes = false,
    ) {
        if (!attributePath) {
            return;
        }
        const ST = ServerType,
            FCT = FirstClassType,
            split = attributePath.split('.'),
            attributeKey = split[split.length - 1],
            fctn = split.length > 1 ? split[0] : undefined,
            firstClassType: FirstClassType = (fctn && FCT[fctn]) || FCT.unknown,
            serverType = firstClassType
                ? FirstClassTypeUtil.serverTypeFromFirstClassType(
                      firstClassType,
                  )
                : usePropertyInsteadOfAllTypes
                  ? ST.Property
                  : ST.AllTypes;
        //console.log(attributePath, FCT[firstClassType], ST[serverType], attributeKey)
        return { firstClassType, serverType, attributeKey };
    }

    /** Cancels the event if its key is e or E */
    public static preventNumberUnsupportedChars(event: KeyboardEvent) {
        if (['e', 'E'].includes(event.key)) {
            event.preventDefault();
        }
    }

    /** Replace the *ListValues* array of the given AttributeMetaInfo by one sorted according to the given *orderedValues* */
    public static sortEnumListValues<TEnumType>(
        enumType: any,
        ami: AttributeMetaInfo,
        orderedValues: TEnumType[],
    ) {
        ami.ListValues = CollectionsHelper.orderBy(ami.ListValues, (e) =>
            orderedValues.indexOf(enumType[e.Key]),
        );
    }

    //#endregion - static

    //#region events

    public get attributeCacheUpdated$() {
        return this.attributeCacheUpdated.asObservable();
    }
    private attributeCacheUpdated = new Subject<void>();
    private notityAttributeCacheUpdatedTimer: number;
    private debouncedNotityAttributeCacheUpdated() {
        if (this.notityAttributeCacheUpdatedTimer) {
            window.clearTimeout(this.notityAttributeCacheUpdatedTimer);
        }
        this.notityAttributeCacheUpdatedTimer = ZoneUtils.zoneTimeout(
            () => {
                this.log('notityAttributeCacheUpdated');
                this.attributeCacheUpdated.next();
                this.notityAttributeCacheUpdatedTimer = undefined;
            },
            555,
            this.ngZone,
            true,
        );
    }
    //#endregion

    private clientTagsList: Array<AttributeTagDTO> = null;

    /** outer key is typeName (ServerType as a string)
     * inner key is attributekey or attributePath */
    private readonly typeAttributes = new Map<
        string,
        Map<string, AttributeMetaInfo>
    >();

    constructor(
        private ngZone: NgZone,
        private translate: TranslateService,
        private userApiService: UserApiService,
        private attributeApiService: AttributeApiService,
        private workspaceApiService: WorkSpaceApiService,
        private userService: UserService,
        private viewTypeService: ViewTypeService,
        private technologyApiService: TechnologyService,
        private languageService: LanguageService,
    ) {
        super();

        toObservable(this.languageService.language)
            .pipe(takeUntilDestroyed())
            .subscribe(() => this.updateAllTranslatedNames());
    }

    public async getAttributesForFiltering(
        dgModule?: DgModule,
    ): Promise<AttributeMetaInfo[]> {
        // key is AttributePath
        const map = new Map<string, AttributeMetaInfo>();
        const serverTypes = DataUtil.getServerTypesFromModule(dgModule);
        this.debug &&
            this.log(
                'getAttributesForFiltering',
                DgModule[dgModule],
                serverTypes.map((st) => ServerType[st]),
            );

        const serverTypeNames = serverTypes.map((st) => ServerType[st]);
        await this.loadAttributes(serverTypeNames);
        this.getAttributes(serverTypeNames, true, true)
            .filter((a) => a.IsFilteringEnabled)
            .forEach((ami) => {
                if (map.has(ami.AttributePath)) {
                    return;
                }
                ami.translatedDisplayName = this.getAttributeDisplayName(
                    ami,
                    ami.serverType,
                );
                map.set(ami.AttributePath, ami);
            });
        const result = Array.from(map.values());

        if (this.debug) {
            // for DG-5332
            const parentsAttrs = result.filter(
                (ami) => ami.AttributeKey == 'Parents',
            );
            if (
                parentsAttrs.length &&
                !parentsAttrs.every((pa) => serverTypes.includes(pa.serverType))
            ) {
                this.warn(
                    'getAttributesForFiltering-Parents-' + parentsAttrs.length,
                    {
                        module: DgModule[dgModule],
                        module_serverTypes: serverTypes?.map(
                            (st) => ServerType[st],
                        ),
                        parentsAttr0: parentsAttrs[0],
                        parentsAttr0_serverType:
                            ServerType[parentsAttrs[0].serverType],
                        parentsAttrs: parentsAttrs.map((pa) => ({
                            parentsAttr: pa,
                            parentsAttr_serverType: ServerType[pa.serverType],
                        })),
                    },
                );
            }
        }

        return result;
    }

    public makeAllEntityTypesAttributeValuesForSearch(ami: AttributeMetaInfo) {
        return CollectionsHelper.getEnumValues(
            EntityType,
            EntityType.None,
            EntityType.All,
            EntityType.Project,
            EntityType.Diagram,
        ).map((et) => this.makeEntityTypeAttributeValue(et, ami));
    }
    private makeEntityTypeAttributeValue(
        entityType: EntityType,
        ami: AttributeMetaInfo,
    ) {
        const etName = EntityType[entityType];
        const translatedDisplayName = this.getEntityTypeTranslation(entityType);
        const glyphClass = EntityTypeUtils.getColoredGlyphClass(entityType);
        return new AttributeMetaValue(ami, etName, etName, {
            translatedDisplayName,
            glyphClass,
        });
    }

    /** Load Attributes from Cache or Server.
     *
     * WARNING: if requesting CDP attributes, do this one serverType at a time,
     * otherwise their attributeKey may overlap.
     * And when doing this, also do a distinct(attributePath) on the whole results,
     * as 'AllType' CDPs will come out for each serverType. */
    public async loadAttributes(
        typeNames: string[],
        forceReload = false,
    ): Promise<Map<string, AttributeMetaInfo>> {
        const cachedAttributes = new Map<string, AttributeMetaInfo>();
        let dataTypeNames: string[] = [];
        typeNames.forEach((tn) => {
            if (!this.typeAttributes.has(tn)) {
                dataTypeNames.push(tn);
            } else {
                this.typeAttributes.get(tn).forEach((value, key) => {
                    if (!cachedAttributes.has(key)) {
                        cachedAttributes.set(key, value);
                    }
                });
            }
        });
        if (cachedAttributes?.size && !dataTypeNames.length) {
            if (forceReload) {
                typeNames.forEach((tn) => this.typeAttributes.delete(tn));
            } else {
                this.debug &&
                    this.log(
                        'loadAttributes-cached',
                        typeNames,
                        this.debugDetailed
                            ? cachedAttributes
                            : cachedAttributes?.size,
                    );
                return cachedAttributes;
            }
        }
        dataTypeNames = forceReload ? typeNames : dataTypeNames;
        const attributes = await this.loadAttributesFromServer(dataTypeNames);

        dataTypeNames.forEach((typeName) => {
            const attributesForType = attributes.filter(
                (a) => a.DataTypeName == typeName,
            );

            // DG-5332 - Exclude IsAllTypes attributes found with the wanted type
            const attributesForTypePaths = attributesForType.map(
                (a) => a.AttributePath,
            );
            const attributesAllTypes = attributes.filter(
                (a) =>
                    a.IsAllTypes &&
                    a.DataTypeName != typeName &&
                    !attributesForTypePaths.includes(a.AttributePath),
            );

            const attributesByKey = CollectionsHelper.arrayToMap(
                [...attributesForType, ...attributesAllTypes],
                (ami) => ami.AttributeKey,
            );
            this.typeAttributes.set(typeName, attributesByKey);
        });
        this.logTypeAttributes('loadAttributes', typeNames, forceReload);
        return CollectionsHelper.arrayToMap(
            attributes,
            (ami) => ami.AttributePath,
        );
    }
    private async loadAttributesFromServer(
        dataTypeNames: string[],
    ): Promise<AttributeMetaInfo[]> {
        this.log('loadAttributesFromServer', dataTypeNames);
        const parameter = new GetAttributesParameter(
            dataTypeNames,
            true,
            true,
            true,
        );
        const result = await this.attributeApiService.getAttributes(parameter);
        const attributes = result.Attributes;

        attributes.forEach((att) => {
            att.serverType = ServerType[att.DataTypeName];
            if (!att.IsCdp) {
                att.Description = this.getSystemAttributeDescription(
                    att.AttributeKey,
                    att.DataTypeName,
                );
            }
            this.setTranslatedNames(att);
            this.setGlyphClasses(att);
            att.ListValues = this.sortAttributeValues(
                att.AttributeKey,
                att.ListValues,
            );
        });

        this.debug &&
            this.log(
                'loadAttributesFromServer-result',
                dataTypeNames,
                this.debugDetailed
                    ? attributes.map((a) => a.AttributePath)
                    : attributes.length,
            );
        return attributes;
    }

    public getAttributes(
        typeNames: string[],
        includeReferences = false,
        includeComputed = false,
        excludedAttributes?: string[],
        keepCommonAttributesOnly?: boolean,
    ) {
        const attributes = new Map<string, AttributeMetaInfo>();
        let matchingAttributesCount: Map<string, number>;

        if (keepCommonAttributesOnly) {
            matchingAttributesCount = new Map<string, number>();
        }
        typeNames.forEach((tn) => {
            this.getLocalAttributes(tn).forEach((value) => {
                const localKey = value.AttributePath;

                if (keepCommonAttributesOnly) {
                    const currentCount =
                        matchingAttributesCount.get(localKey) || 0;
                    matchingAttributesCount.set(localKey, currentCount + 1);
                }

                if (!attributes.has(localKey)) {
                    attributes.set(localKey, value);
                }
            });
        });
        return CollectionsHelper.filterMap(
            attributes,
            (v) =>
                (includeReferences || !v.IsReference) &&
                (includeComputed || !v.IsComputed) &&
                !excludedAttributes?.includes(v.AttributePath) &&
                (!keepCommonAttributesOnly ||
                    matchingAttributesCount.get(v.AttributePath) ==
                        typeNames.length),
        );
    }

    private getLocalAttributes(typeName: string) {
        this.debugDetailed && this.log('getLocalAttributes', typeName);
        const cacheData = this.typeAttributes.get(typeName);
        if (cacheData) {
            return cacheData;
        }
        throw new Error(
            `attributeDataService.loadAttributes must be called before use (${typeName})`,
        );
    }

    public getAttributeDisplayNameInternal(
        attributeKey: string,
        attributeIsCdp: boolean,
        dataDisplayName: string,
        serverType: ServerType,
    ) {
        if (!attributeKey) {
            return null;
        }
        if (attributeIsCdp) {
            return dataDisplayName;
        }
        let attKey = attributeKey.replace('.', '_');
        attKey = attKey.replace('_lastEntry', '');
        attKey = attKey.replace('_lastEntries', '');
        let tradKey = `DgServerTypes.${ServerType[serverType]}.name.${attKey}`;
        let tradString = this.translate.instant(tradKey);
        if (tradString === tradKey) {
            tradKey = `DgServerTypes.BaseData.fields.${attKey}`;
            tradString = this.translate.instant(tradKey);
        }
        return tradString;
    }

    public getAttributeDisplayName(
        ami: AttributeMetaInfo,
        serverType?: ServerType,
    ) {
        if (
            !ami.IsCdp &&
            (ami.AttributeType === AttributeMetaType.ObjectValueList ||
                ami.AttributeType === AttributeMetaType.ObjectNameList)
        ) {
            const attributeMetaInternal = this.getAttributeInternal(
                serverType ?? ami.serverType,
                ami.SourceAttributeKey,
            );
            if (attributeMetaInternal) {
                ami = attributeMetaInternal;
            }
        }

        if (ami.AttributeType == AttributeMetaType.ObjectLink) {
            const linkPrefix = this.translate.instant(
                'DgServerTypes.BaseData.fields.ObjectLinkPrefix',
            );
            const linkName = this.translate.instant(
                `DgServerTypes.ObjectLinkType.${
                    ObjectLinkType[ami.ObjectLinkType]
                }`,
            );
            return `${linkPrefix}${linkName}`;
        }

        const attributeEntityType = ami?.serverType ?? serverType;
        return this.getAttributeDisplayNameInternal(
            ami.AttributeKey,
            ami.IsCdp,
            ami.DisplayName,
            attributeEntityType,
        );
    }

    public sortAlphaAttributes(
        attributes: AttributeMetaInfo[],
        serverType: ServerType,
    ) {
        return CollectionsHelper.orderByText(attributes, (ami) =>
            this.getAttributeDisplayName(ami, serverType),
        );
    }

    public getSubTypeNameTranslation(
        dataServerTypeName: string,
        subTypeName: string,
        isPlural?: boolean,
    ) {
        const result = this.getSystemListAttributeOptionDisplayName(
            'Type',
            dataServerTypeName,
            subTypeName,
            isPlural,
        );
        //console.log('getSubTypeNameTranslation', { dataServerTypeName, subTypeName, subTypePropertyName }, result)
        return result;
    }

    public getEntityTypeTranslation(entityType: EntityType) {
        const etm = entityType && EntityTypeUtil.getMapping(entityType);
        return (
            etm &&
            this.getSubTypeNameTranslation(etm.DataTypeName, etm.SubTypeName)
        );
    }

    public getEntityTypeTranslationWithArticle(
        entityType: EntityType,
        optionalTranslateKey?: string,
    ) {
        const etm = entityType && EntityTypeUtil.getMapping(entityType);
        const gender = this.translate.instant(
            `DgServerTypes.${etm.DataTypeName}TypeGender.${etm.SubTypeName}`,
        );
        const entityTypeStr = this.getEntityTypeTranslation(entityType);
        const translateKey =
            optionalTranslateKey ?? `UI.Global.entityTypeWithArticle`;
        return this.translate.instant(translateKey, {
            typeName: entityTypeStr.toLowerCase(),
            typeNameGender: gender,
        });
    }

    public getEntityTypePluralTranslation(entityType: EntityType) {
        const etm = entityType && EntityTypeUtil.getMapping(entityType);
        return this.getSubTypeNameTranslation(
            etm.DataTypeName,
            etm.SubTypeName,
            true,
        );
    }

    public getAttributeOptionDisplayName(
        ami: AttributeMetaInfo,
        option: AttributeMetaValue,
        isPlural?: boolean,
    ) {
        if (
            (ami.IsSystem && ami.AttributeKey == 'Type') ||
            ami.AttributeType == AttributeMetaType.SystemEntityType
        ) {
            return this.getSystemListAttributeOptionDisplayName(
                'Type',
                ServerType[ami.serverType],
                option.Value,
                isPlural,
            );
        }

        const sourceValue = ami.isTranslated
            ? option.translatedDisplayName
            : option.Key;
        return this.getAttributeOptionDisplayNameInternal(
            ami.IsCdp,
            ami.ListTypeName,
            sourceValue,
        );
    }

    public getAttributeOptionGlyphClass(
        ami: AttributeMetaInfo,
        option: AttributeMetaValue,
    ) {
        switch (ami.AttributeKey) {
            case PropertyName.EntityStatus:
                return GlyphUtil.getStatusGlyphClass(
                    EntityLifecycleStatus[option.Value as string],
                );
            case PropertyName.DataQuality:
                return GlyphUtil.getDataQualityGlyphClass(
                    DataQualityResult[option.Value as string],
                );
            case PropertyName.Module:
                return GlyphUtil.getModuleColoredGlyphClass(
                    DgModule[option.Value as string],
                );
            case PropertyName.LegacySubTypeAttributeKey: {
                const entityType = EntityTypeUtil.getEntityType(
                    ami.DataTypeName,
                    option.Value,
                );
                return EntityTypeUtils.getColoredGlyphClass(entityType);
            }
            case PropertyName.EntityType:
                return EntityTypeUtils.getColoredGlyphClass(
                    EntityType[option.Value as string],
                );
        }
    }

    public getSystemAttributeValueListOptionDisplayName(
        listTranslateKey: string,
        sourceValue: string,
    ) {
        return this.getAttributeOptionDisplayNameInternal(
            false,
            listTranslateKey,
            sourceValue,
        );
    }
    public getSystemListAttributeOptionDisplayName(
        attributeKey: string,
        dataServerTypeName: string,
        sourceValue: string,
        isPlural?: boolean,
    ) {
        const translationKey = AttributeDataService.getSystemListTranslationKey(
            attributeKey,
            dataServerTypeName,
        );
        const systemListServerTypeName = `${translationKey}${
            isPlural ? 'Plural' : ''
        }`;
        return this.getAttributeOptionDisplayNameInternal(
            false,
            systemListServerTypeName,
            sourceValue,
        );
    }
    private getAttributeOptionDisplayNameInternal(
        isCdp: boolean,
        systemListServerTypeName: string,
        sourceValue: string,
    ) {
        if (isCdp || !systemListServerTypeName) {
            return sourceValue;
        }
        const tradKey =
            !sourceValue && systemListServerTypeName.endsWith('Type')
                ? `DgServerTypes.ServerTypeName.${StringUtil.removeSuffix(
                      systemListServerTypeName,
                      'Type',
                  )}`
                : `DgServerTypes.${systemListServerTypeName}.${sourceValue}`;
        return this.translate.instant(tradKey);
    }

    public getSystemAttributeDescription(
        attributeKey: string,
        serverTypeStr: string,
    ) {
        const suffix = `description.${attributeKey}`;
        const tradPath = `DgServerTypes.BaseData.${suffix}`;
        const baseDataTrad = this.translate.instant(tradPath);
        if (baseDataTrad != tradPath) {
            return baseDataTrad;
        }

        const moduleTradPath = `DgServerTypes.${serverTypeStr}.${suffix}`;
        const moduleTrad = this.translate.instant(moduleTradPath);
        if (moduleTrad != moduleTradPath) {
            return moduleTrad;
        }
        return '';
    }

    public async getAttributeTags(
        moduleName: string,
        attributeKey: string,
        computeParents: boolean,
        includeActiveOnly = false,
    ) {
        const getTagsAttributeParameter = new GetAttributeTagsParameter(
            moduleName,
            attributeKey,
            includeActiveOnly,
            '',
        );
        const result = await this.attributeApiService.getAttributeTags(
            getTagsAttributeParameter,
        );
        const tagsList = result.AttributeTags;
        if (computeParents) {
            const resultDicitionary = new Map<string, AttributeTagDTO>();
            tagsList.forEach((c) => resultDicitionary.set(c.TagId, c));
            tagsList.forEach((c) => {
                if (c.ParentTagId) {
                    c.parentValue = resultDicitionary.get(c.ParentTagId);
                    c.parentValue.children.push(c);
                }
            });
            const allRoots = tagsList.filter((o) => !o.parentValue);
            this.setOrderValue(allRoots, 0);
        }
        return result.AttributeTags;
    }
    private setOrderValue(dataList: AttributeTagDTO[], topIterator: number) {
        let localValue = topIterator;
        CollectionsHelper.alphaSort(dataList, 'DisplayName').forEach((o) => {
            o.localOrder = localValue++;
            localValue = this.setOrderValue(o.children, localValue);
        });
        return localValue;
    }

    public async getHierarchyValues(
        attributeMetadata: AttributeMetaInfo,
    ): Promise<AttributeMetaValue[]> {
        if (attributeMetadata.AttributeType != AttributeMetaType.Hierarchy) {
            return [];
        }

        // For System-Type hierarchy kind, values are hard coded and received as part of the Attribute.HierarchyValues collection
        if (attributeMetadata.HierarchyKind == HierarchyKind.System) {
            return attributeMetadata.HierarchyValues.map(
                (hv) =>
                    new AttributeMetaValue(
                        attributeMetadata,
                        hv.ObjectId,
                        hv.DisplayName,
                        { color: hv.TagColor },
                    ),
            );
        }

        const result = await this.getAttributeTags(
            attributeMetadata.DataTypeName,
            attributeMetadata.AttributeKey,
            true,
            true,
        );
        const amvs = result.map((dto) =>
            AttributeMetaValue.fromHierarchicalAttributeTag(
                dto,
                attributeMetadata,
            ),
        );
        const map = CollectionsHelper.arrayToMap(amvs, (a) => a.Key);
        amvs.forEach((c) => {
            if (c.parentKey) {
                c.parentValue = map.get(c.parentKey);
            }
        });
        return amvs.sort(
            (a, b) => map.get(a.Key).localOrder - map.get(b.Key).localOrder,
        );
    }

    private lastInit = 0;
    public init() {
        //# region #Archi-doubleLogin
        if (this.debug && Date.now() - this.lastInit < 1000) {
            // Only if debug is activated
            // Avoid the attribute cache-clear after search service init
            this.warn('init (double-login)');
            return;
        }
        this.lastInit = Date.now();
        //#endregion

        this.log('init');
        this.clearClientTags();
        this.typeAttributes.clear();
    }

    public clearClientTags() {
        this.clientTagsList = null;
    }

    // Called on Real Time (SignalR) deletiong of existing CDP attribute (and eventually Editor Attributes as well)
    public removeAttributeFromLocalCache(
        moduleName: string,
        attributeKey: string,
    ) {
        if (!this.typeAttributes.has(moduleName)) {
            return;
        }

        const localAttributes = this.getLocalAttributes(moduleName);
        if (!localAttributes.has(attributeKey)) {
            return;
        }

        localAttributes.delete(attributeKey);
    }

    // Called on Real Time (SignalR) update of existing CDP attribute (and eventually Editor Attributes as well)
    public async refreshLocalCacheAttributes(moduleName: string) {
        const clear = moduleName == ServerType[ServerType.AllTypes];
        const reload = this.typeAttributes.has(moduleName);
        this.logTypeAttributes(
            'refreshLocalCacheAttributes',
            moduleName,
            clear,
            reload,
        );

        if (clear) {
            this.typeAttributes.clear();
        }

        if (reload) {
            // Force reloading cache from server
            await this.loadAttributes([moduleName], true);
        }

        return this.debouncedNotityAttributeCacheUpdated();
    }

    public async loadReferenceOptionsEntity(
        attributeMeta: AttributeMetaInfo,
        entityIdr: IEntityIdentifier,
    ) {
        return await this.loadReferenceOptionsInternal(
            attributeMeta,
            WorkspaceIdentifier.fromEntity(entityIdr),
            entityIdr,
        );
    }

    public async loadReferenceOptionsSpace(
        attributeMeta: AttributeMetaInfo,
        spaceIdr?: IWorkspaceIdentifier,
    ) {
        return await this.loadReferenceOptionsInternal(attributeMeta, spaceIdr);
    }

    // External Data (Domain Data) logic:
    /// Steward User Reference (dependent on Entity)
    // Client User List
    // Client Person List
    // Client Tags
    // Client Attribute Tags
    private async loadReferenceOptionsInternal(
        attributeMeta: AttributeMetaInfo,
        spaceIdr?: IWorkspaceIdentifier,
        entityIdr?: IEntityIdentifier,
    ): Promise<AttributeMetaValue[]> {
        this.log(
            'loadReferenceOptionsInternal',
            AttributeMetaType[attributeMeta.AttributeType],
            spaceIdr,
            entityIdr,
        );
        switch (attributeMeta.AttributeType) {
            case AttributeMetaType.ClientTag: {
                const res = await this.getAvailableClientTags();
                return res.map((dto) =>
                    AttributeMetaValue.fromClientTag(dto, attributeMeta),
                );
            }

            case AttributeMetaType.StewardUserReference: {
                if (entityIdr) {
                    const res = await this.getEntityUsers(
                        entityIdr.ReferenceId,
                        entityIdr.ServerType,
                        entityIdr.VersionId,
                        attributeMeta.AttributeKey,
                    );
                    return res.AvailableUserList.map(
                        (user) =>
                            new AttributeMetaValue(
                                attributeMeta,
                                user.ReferenceId,
                                user.FullName,
                            ),
                    );
                } else {
                    const res = await this.getSpaceGovernanceUsers(
                        spaceIdr,
                        attributeMeta.AttributeKey,
                    );
                    //console.log('loadReferenceOptionsInternal-getSpaceGovernanceUsers', res, attributeMeta.AttributeKey)
                    return res.SpaceGovernanceUserList.get(
                        attributeMeta.AttributeKey,
                    )?.map((user) =>
                        AttributeMetaValue.fromSpaceGovUser(
                            user,
                            attributeMeta,
                        ),
                    );
                }
            }

            case AttributeMetaType.Technology: {
                const technologies = this.technologyApiService.technologies;
                return technologies.map((tec) => {
                    const iconUrl =
                        this.technologyApiService.getTechnologyIconUrl(
                            tec,
                            true,
                        );
                    return AttributeMetaValue.fromTechnology(
                        tec,
                        attributeMeta,
                        iconUrl,
                    );
                });
            }

            case AttributeMetaType.UserGuid:
            //#Archi-users: todo (jga) does it still exists ? if no it can be deleted
            case AttributeMetaType.PersonReference:
            case AttributeMetaType.UserReference: {
                const loadMetaBot =
                    attributeMeta.AttributeKey === PropertyName.CreationUserId;
                const res = await this.userService.getNonDeletedUsers(
                    true,
                    loadMetaBot,
                );
                const useGuid =
                    attributeMeta.AttributeType === AttributeMetaType.UserGuid;
                return res.map((user) =>
                    AttributeMetaValue.fromUserReference(
                        user,
                        attributeMeta,
                        useGuid,
                    ),
                );
            }

            case AttributeMetaType.ManagedTag:
            case AttributeMetaType.MultiValueList: {
                const res = await this.getAttributeTags(
                    ServerType[attributeMeta.serverType],
                    attributeMeta.AttributeKey,
                    false,
                    true,
                );
                return res.map((tag) =>
                    AttributeMetaValue.fromAttributeTag(tag, attributeMeta),
                );
            }

            case AttributeMetaType.Hierarchy:
                return this.getHierarchyValues(attributeMeta);

            //#Archi-users: todo (fbo) check and remove (dxy-space-edit-modal now uses userService)
            /** This is the source list for the Space Creation Modal : All users are valid candidates, but the format is AttributeMetaValue[] */
            case AttributeMetaType.SpaceDefaultOfficialUser: {
                const res = await this.userService.getNonDeletedUsers();
                return res.map(
                    (user) =>
                        new AttributeMetaValue(
                            attributeMeta,
                            user.UserId,
                            user.FullName,
                        ),
                );
            }

            //#Archi-users: todo (fbo) check and remove (dxy-space-roles now uses userService)
            /** This is the source list for the Space Governance Users management : All users are valid candidates */
            case AttributeMetaType.SpaceGovernanceUser: {
                const res = await this.userService.getUsersDataAsSpaceGovDtos();
                return res.map((dto) =>
                    AttributeMetaValue.fromSpaceGovUser(dto, attributeMeta),
                );
            }
        }
    }

    private getEntityUsers(
        entityId: string,
        serverType: ServerType,
        versionId: string,
        attributeKey?: string,
    ) {
        const parameter = new GetEntityUsersParameter(
            entityId,
            ServerType[serverType],
            versionId,
            attributeKey,
        );
        return this.userApiService.getEntityUsers(parameter);
    }

    private getAttributeInternal(serverType: ServerType, attributeKey: string) {
        const localAttributes = this.getLocalAttributes(ServerType[serverType]);
        return localAttributes.get(attributeKey);
    }

    private updateAllTranslatedNames() {
        this.typeAttributes.forEach((map) =>
            map.forEach((ami) => this.setTranslatedNames(ami)),
        );
    }

    public setGlyphClasses(att: AttributeMetaInfo) {
        att.ListValues?.forEach((lv) => {
            lv.glyphClass = this.getAttributeOptionGlyphClass(att, lv);
        });
    }

    public setTranslatedNames(att: AttributeMetaInfo) {
        att.translatedDisplayName = this.getAttributeDisplayNameInternal(
            att.AttributeKey,
            att.IsCdp,
            att.DisplayName,
            att.serverType,
        );
        const attributeTypes = [
            AttributeMetaType.ValueList,
            AttributeMetaType.SystemEntityType,
            AttributeMetaType.Module,
        ];
        if (attributeTypes.includes(att.AttributeType)) {
            att.ListValues.forEach(
                (lv) =>
                    (lv.translatedDisplayName =
                        this.getAttributeOptionDisplayName(att, lv)),
            );
        }
    }

    public setAttributeTextQualityUserVote(
        entityIdr: IEntityIdentifier,
        attributePath,
        vote: TextQualityVoteStatus,
    ) {
        const params = new SetTextQualityVoteParameter();
        params.ReferenceId = entityIdr.ReferenceId;
        params.AttributePath = attributePath;
        params.TextQualityVoteStatus = vote;
        params.VersionId = entityIdr.VersionId;
        return this.attributeApiService.setFormattedTextUserVote(params);
    }

    /// CLIENT ATTRIBUTE METHODS

    public hasTags(): boolean {
        return !!this.clientTagsList;
    }

    public getClientTagsFromServer() {
        return this.getAttributeTags(
            ServerConstants.AttributeConstants.ModuleNameAllTypes,
            ServerConstants.AttributeConstants.SystemTagsAttributeKey,
            false,
        );
    }

    public alphaSortTags(tagsList: Array<AttributeTagDTO>) {
        return CollectionsHelper.alphaSort(tagsList, 'DisplayName');
    }

    /** NOTE: This method may be called without *spaceIdr*, by the search-input,
     * in order to provide a client-wide list of Space Governance Users */
    public getSpaceGovernanceUsers(
        spaceIdr?: IWorkspaceIdentifier,
        attributeKey?: string,
    ) {
        const param = new GetSpaceGovernanceUsersParameter(
            spaceIdr?.spaceId,
            attributeKey,
            true,
        );
        param.setVersionId(spaceIdr?.versionId);
        return spaceIdr?.spaceId
            ? this.workspaceApiService.getSpaceGovernanceUsers(param)
            : this.workspaceApiService.getClientSpaceGovernanceUsers(param);
    }

    public async getClientTags(): Promise<AttributeTagDTO[]> {
        if (!this.clientTagsList) {
            const result = await this.getClientTagsFromServer();
            this.clientTagsList = this.alphaSortTags(result);
        }
        return this.clientTagsList;
    }

    public async getAvailableClientTags() {
        const tagsList = await this.getClientTags();
        return tagsList?.filter((tag) => tag.IsActive) ?? [];
    }

    public getAttribute(serverType: ServerType, attributeKey: string) {
        const localAttributes = this.getLocalAttributes(ServerType[serverType]);
        return localAttributes.get(attributeKey);
    }

    public extractExactMatchesAttributes(
        entity: FilteredEntityItem,
    ): IAttributeSearchMatch[] {
        if (!entity) {
            return [];
        }

        const matchedAttributes: IAttributeSearchMatch[] = [];

        entity.ExactMatchAttributes?.forEach((value, key) => {
            const attribute = this.getAttribute(entity.ServerType, key);
            if (!attribute) {
                return;
            }
            matchedAttributes.push({
                displayName: attribute?.translatedDisplayName,
                text: value,
                attributeKey: key,
            });
        });
        return this.sortExactMatchesAttributes(matchedAttributes);
    }

    public getFirstOrNullExactMatch(
        exactMatches: IAttributeSearchMatch[],
        entity: FilteredEntityItem,
    ) {
        if (!entity || !exactMatches?.length) {
            return;
        }
        const isTechnicalView = this.viewTypeService.isTechnicalView;
        const hasSameDisplayAndTechnicalName =
            entity.TechnicalName?.toLowerCase() ==
            entity.DisplayName.toLowerCase();
        const exactMatch = exactMatches?.[0];

        if (
            [PropertyName.TechnicalName, PropertyName.DisplayName].includes(
                exactMatch.attributeKey,
            ) &&
            hasSameDisplayAndTechnicalName
        ) {
            return;
        }
        if (
            (isTechnicalView &&
                exactMatch.attributeKey === PropertyName.TechnicalName) ||
            (!isTechnicalView &&
                exactMatch.attributeKey === PropertyName.DisplayName)
        ) {
            return;
        }

        return exactMatch;
    }

    /**
     * Sort exact matches by attributeKey first, then for cdp and exceptions by displayName
     * cf: https://datagalaxy.atlassian.net/wiki/spaces/DG3/pages/2612002817/Low+medium+and+high+intent+search#INTENT_SEARCH_01_RG_06
     */
    public sortExactMatchesAttributes(exactMatches: IAttributeSearchMatch[]) {
        const attributeOrder = [
            PropertyName.DisplayName,
            PropertyName.TechnicalName,
            PropertyName.LocalSynonymsNames,
            PropertyName.Description,
            PropertyName.LongDescriptionRaw,
            PropertyName.Code,
            PropertyName.ExternalTechnologyType,
            PropertyName.SchemaName,
            PropertyName.PrimaryKeyTechnicalName,
            PropertyName.TechnicalCommentsRaw,
            PropertyName.GdprMainPurpose,
            PropertyName.GdprSecondaryPurpose,
            PropertyName.GdprUsageDuration,
        ];
        const sortExactMatchesAttributes = (
            a: IAttributeSearchMatch,
            b: IAttributeSearchMatch,
        ) => {
            const indexA = attributeOrder.indexOf(a.attributeKey);
            const indexB = attributeOrder.indexOf(b.attributeKey);
            if (indexA === -1 && indexB === -1) {
                return 0;
            } else if (indexB === -1) {
                return -1;
            } else if (indexA === -1) {
                return 1;
            }
            return indexA - indexB;
        };
        return CollectionsHelper.orderBy(
            exactMatches,
            (d) => d.displayName,
        ).sort(sortExactMatchesAttributes);
    }

    public createFieldInfo(
        entityForm: IEntityForm<any>,
        attributeKey: string,
        attributeDisplayName: string,
        attributeType: AttributeMetaType,
        opt?: {
            isMandatory?: boolean;
            isReadOnly?: boolean;
            defaultValue?: any;
        },
    ): AttributeFieldInfo {
        const ami = this.createDynamicAttribute(
            attributeKey,
            attributeDisplayName,
            attributeType,
            opt?.isMandatory,
        );
        if (opt?.isReadOnly != undefined) {
            ami.IsReadOnly = opt.isReadOnly;
        }
        if (opt?.defaultValue != undefined) {
            ami.DefaultValue = opt.defaultValue;
        }
        return new AttributeFieldInfo(ami, entityForm);
    }

    /** sets the ListValue property of the given fieldInfo's attributeMeta.
     * Note: getKey and getValue default to toString() */
    public setFieldInfoValues<T>(
        fieldInfo: AttributeFieldInfo,
        data: T[],
        opt?: ISetFieldInfoValuesOptions<T>,
    ) {
        this.setListValues(
            fieldInfo.attributeMeta,
            data,
            opt?.getKey ?? ((a) => a?.toString()),
            opt?.getValue ?? ((a) => a?.toString()),
            opt?.getTranslatedValue ?? ((a) => a?.toString()),
            opt?.getTranslatedDescription,
            opt?.getGlyphClass,
            opt?.keepArrayInstance,
        );
    }

    private getAttributeModule(attribute: AttributeMetaInfo) {
        return DataUtil.getModuleFromServerType(attribute.serverType);
    }

    private setListValues<T>(
        attributeMeta: AttributeMetaInfo,
        data: T[],
        keyAccessor: (o: T) => string,
        valueAccessor: (o: T) => string,
        translatedValueAccessor: (o: T) => string,
        translatedDescriptionAccessor: (o: T) => string = undefined,
        glyphClassAccessor: (o: T) => string = undefined,
        keepArrayInstance = false,
    ) {
        if (keepArrayInstance) {
            attributeMeta.ListValues.length = 0;
        } else {
            attributeMeta.ListValues = [];
        }
        data.forEach((a) => {
            const translatedDisplayName = this.translate.instant(
                translatedValueAccessor(a),
            );
            const translatedDescription =
                (translatedDescriptionAccessor &&
                    this.translate.instant(translatedDescriptionAccessor(a))) ||
                undefined;
            const glyphClass = glyphClassAccessor?.(a);
            attributeMeta.ListValues.push(
                new AttributeMetaValue(
                    attributeMeta,
                    keyAccessor(a),
                    valueAccessor(a),
                    {
                        glyphClass,
                        translatedDisplayName,
                        translatedDescription,
                    },
                ),
            );
        });
    }

    public createDynamicAttribute(
        attributeKey: string,
        attributeDisplayName: string,
        attributeType: AttributeMetaType,
        isMandatory = false,
    ) {
        const attribute = new AttributeMetaInfo();
        attribute.AttributeKey = attribute.AttributePath = attributeKey;
        attribute.AttributeType = attributeType;
        attribute.translatedDisplayName = attributeDisplayName
            ? this.translate.instant(attributeDisplayName)
            : '';
        attribute.DisplayName = attributeDisplayName;
        attribute.IsReadOnly = false;
        attribute.isTranslated = true;
        attribute.IsMandatory = isMandatory;
        if (attributeType === AttributeMetaType.ValueList) {
            attribute.ListValues = [];
        }
        attribute.init();
        return attribute;
    }

    public updateAttributesFromLocalCacheAfterChangeListValue(
        attributeMeta: AttributeDTO,
        index?: number,
    ) {
        this.typeAttributes.forEach((attributes) => {
            const attribute = attributes.get(attributeMeta.AttributeKey);
            if (attribute) {
                if (index) {
                    attribute.ListValues[index] =
                        attributeMeta.ListValues[index];
                } else {
                    attribute.ListValues = attributeMeta.ListValues;
                }
            }
        });
    }

    public async getCommonAttributesForInsightWidgets() {
        const map = await this.loadAttributes(
            DataUtil.getFirstClassEntityServerTypeNames(),
        );
        return CollectionsHelper.filterMap(
            map,
            (ami) =>
                !ami.IsCdp &&
                ami.IsAllTypes &&
                ami.AttributeType != AttributeMetaType.EntityReference,
        );
    }

    public async getFreeTextAttributesForInsightWidgets(
        dgModule?: DgModule,
        entityTypes?: EntityType[],
    ): Promise<AttributeMetaInfo[]> {
        const serverTypes =
            entityTypes && EntityTypeUtil.getDistinctServerTypes(entityTypes);

        const firstClassNames = DataUtil.getServerTypesFromModule(dgModule)
            .filter((st) => serverTypes?.includes(st))
            .map((st) => ServerType[st]);

        const amiGroups = await Promise.all(
            firstClassNames.map(async (stn) => {
                const map = await this.loadAttributes([stn]);
                return CollectionsHelper.filterMap(
                    map,
                    AttributeDataService.isFreeTextAttribute,
                );
            }),
        );

        const amis = CollectionsHelper.distinctByProperty(
            CollectionsHelper.flatten(amiGroups),
            (ami) => ami.AttributePath,
        );

        // for debugging
        // eslint-disable-next-line no-constant-condition
        if (false) {
            this.log('getFreeTextAttributesForInsightWidgets', firstClassNames);
            amis.forEach((a) =>
                console.log(a.AttributePath, [
                    a.DisplayName,
                    AttributeMetaType[a.AttributeType],
                    ServerType[a.serverType],
                    a,
                ]),
            );
        }

        return amis;
    }

    public getTextAttributeDisplayNameForInsightWidgets(
        ami: AttributeMetaInfo,
        excludeType = false,
    ) {
        if (!ami) {
            return '';
        }

        const displayName = this.getAttributeDisplayName(
            ami,
            ServerType[ami.DataTypeName],
        );
        if (excludeType || ami.IsAllTypes) {
            return displayName;
        }

        const typeDisplayName = this.translate.instant(
            `DgServerTypes.ServerTypeNamePlural.${ami.DataTypeName}`,
        );
        return `${displayName} (${typeDisplayName})`;
    }

    public loadAttributesForInsightWidgetFilters(attributePaths: string[]) {
        const serverTypes =
            AttributeDataService.getServerTypesFromAttributePaths(
                attributePaths,
            );
        const serverTypeNames = serverTypes.map((st) => ServerType[st]);
        return this.loadAttributes(serverTypeNames);
    }
    public getTextAttributeDisplayNameForInsightWidgetFilter(
        attributePath: string,
        excludeType = false,
    ) {
        const { serverType, attributeKey } =
            AttributeDataService.getInfosFromAttributePath(attributePath, true);
        const ami = this.getAttribute(serverType, attributeKey);
        return this.getTextAttributeDisplayNameForInsightWidgets(
            ami,
            excludeType,
        );
    }

    public getGlyphClass(attributeType: AttributeMetaType) {
        return getAttributeTypeGlyphClass(attributeType);
    }

    public getAttributeGlyphClass(attribute: AttributeMetaInfo) {
        return getAttributeGlyphClass(attribute);
    }

    public getGlyphClassName(
        attributeKey: string,
        attributeType: AttributeMetaType,
    ) {
        switch (attributeKey) {
            case PropertyName.IsWatchedByCurrentUser:
                return 'glyph-show';
            default:
                return getAttributeTypeGlyphClass(attributeType);
        }
    }

    private logTypeAttributes(...args: unknown[]) {
        if (!this.debug) {
            return;
        }
        this.log(
            'typeAttributes',
            ...args,
            '\n',
            this.debugDetailed
                ? CollectionsHelper.mapToKeyValArray(
                      this.typeAttributes,
                      CollectionsHelper.mapToKeyValArray,
                  )
                : Array.from(this.typeAttributes.keys()),
        );
    }

    private sortAttributeValues(
        attributeKey: string,
        attributeValues: AttributeMetaValue[],
    ) {
        switch (attributeKey) {
            case PropertyName.EntityStatus: {
                const status =
                    AttributeDataService.getOrderedEntityStatusList();
                return attributeValues.sort(
                    ArrayUtils.sortByIndexOf(
                        status,
                        (o) =>
                            EntityLifecycleStatus[
                                o.Value as keyof typeof EntityLifecycleStatus
                            ],
                    ),
                );
            }
            default: {
                return attributeValues;
            }
        }
    }
}

export interface ISetFieldInfoValuesOptions<T> {
    getKey?: (o: T) => string;
    getValue?: (o: T) => string;
    getTranslatedValue?: (o: T) => string;
    getTranslatedDescription?: (o: T) => string;
    getGlyphClass?: (o: T) => string;
    keepArrayInstance?: boolean;
}

export interface IAttributeSearchMatch {
    displayName: string;
    text: string;
    attributeKey: string;
}
