<div *ngIf="!isEdit" class="no-edit" [class.readonly]="readonly">
    <span
        *ngIf="!readonly"
        class="glyph-link"
        tabindex="0"
        (keypress)="onLinkIconKeyPress($event)"
        [dxyTooltip]="linkIconTooltip"
    ></span>
    <ng-container *ngIf="valueUrl">
        <a
            target="_blank"
            tabindex="-1"
            [href]="getUrl()"
            [dxyTooltip]="getUrl()"
            >{{ valueName || valueUrl }}</a
        >
        <span
            [ngClass]="copyIconClass"
            (click)="copyUrl($event)"
            [dxyTooltip]="copyIconTooltip"
        ></span>
    </ng-container>
    <span *ngIf="!valueUrl" class="no-url">{{ valueName }}</span>
</div>
<div class="inputs-wrapper" *ngIf="!readonly && isEdit">
    <div class="input-group input-group-name">
        <label *ngIf="nameLabel" [class]="inputsLabelClass">{{
            nameLabel
        }}</label>
        <input
            matInput
            class="input-name"
            [(ngModel)]="valueName"
            (keydown.enter)="onInputEnterKeyPress($event)"
            [placeholder]="'UI.HtmlLink.name' | translate"
        />
    </div>
    <div class="input-group input-group-url">
        <label [class]="inputsLabelClass">{{ urlLabel }}</label>
        <input
            matInput
            (keydown.enter)="onInputEnterKeyPress($event)"
            class="input-url"
            [(ngModel)]="valueUrl"
        />
    </div>
</div>
