import {
    DataProcessingType,
    EntityType,
    EntityTypeUtil,
    HierarchyDataDescriptor,
    IHierarchicalData,
    ModelType,
    PropertyType,
    ServerType,
    SoftwareElementType,
} from '@datagalaxy/dg-object-model';
import { environment } from '../../../environments/environment';
import { ImportMode } from '../../import/shared/ImportContext';
import { DataQualityResult } from '@datagalaxy/webclient/data-quality/data-access';
import {
    ColumnType,
    ContainerType,
    TableType,
} from '@datagalaxy/webclient/modeler/data-access';
import { DataUtil } from './DataUtil';
import {
    DgModule,
    DgModuleName,
    getModuleDefinition,
} from '@datagalaxy/shared/dg-module/domain';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { EntityLifecycleStatus } from '@datagalaxy/webclient/attribute/domain';
import { SpaceSecurityProfileType } from '@datagalaxy/webclient/workspace/domain';

/*
    DO NOT USE ANY UTIL HERE (Modify GlyphService instead, if needed)
*/

export class GlyphUtil {
    // @glyph-splitter: "\e8b0";
    public static readonly glyphCharSplitter = '\uea2b';

    // @glyph-object-preview: "\e9ba";
    public static readonly glyphCharPreview = '\ue9e7';

    // @glyph-golden-link: "\ue9d4"
    public static readonly glyphCharGoldenLink = '\ue99d';

    // @glyph-uncollapse: "\e7c3";
    public static readonly glyphCharExpanded = '\uea3d';

    //#region import
    public static getImportModeGlyphClass(mode: ImportMode) {
        switch (mode) {
            case ImportMode.DesktopConnector:
                return 'glyph-desktop';
            case ImportMode.OnlineConnector:
                return 'glyph-online';
            default:
                return '';
        }
    }
    //#endregion

    //#region modules
    /**
     * @deprecated use DgModuleDefinition or glyphService instead
     */
    public static getColorClassFromModule(dgModule: DgModule): string {
        return getModuleDefinition(DgModule[dgModule] as DgModuleName)
            .colorGlyphClass;
    }
    /**
     * @deprecated use DgModuleDefinition or glyphService instead
     */
    public static getModuleColoredGlyphClass(dgModule: DgModule) {
        return getModuleDefinition(DgModule[dgModule] as DgModuleName)
            .coloredGlyphClass;
    }
    //#endregion - modules

    //#region serverType/subtype

    public static getServerTypeGlyphClass(
        serverType: ServerType,
        subTypeName: string = null,
    ): string {
        switch (serverType) {
            case ServerType.Project:
                return 'glyph-project';
            case ServerType.Modeler:
                return 'glyph-catalog';
            case ServerType.Model:
                switch (ModelType[subTypeName]) {
                    case ModelType.Relational:
                        return 'glyph-database';
                    case ModelType.NonRelational:
                        return 'glyph-filestore';
                    case ModelType.NoSql:
                        return 'glyph-nosql';
                    case ModelType.TagBase:
                        return 'glyph-tagbase';
                    default:
                        return 'glyph-source';
                }
            case ServerType.Container:
                switch (ContainerType[subTypeName]) {
                    case ContainerType.Model:
                        return 'glyph-model';
                    case ContainerType.Directory:
                        return 'glyph-containerfolder';
                    case ContainerType.Equipment:
                        return 'glyph-equipment';
                    default:
                        return 'glyph-container';
                }
            case ServerType.Table:
                switch (TableType[subTypeName]) {
                    case TableType.Table:
                    default:
                        return 'glyph-table';
                    case TableType.View:
                        return 'glyph-view';
                    case TableType.Tag:
                        return 'glyph-tag';
                    case TableType.File:
                        return 'glyph-file';
                    case TableType.Document:
                        return 'glyph-document';
                    case TableType.SubStructure:
                        return 'glyph-sub-structure';
                }
            case ServerType.Column:
                switch (ColumnType[subTypeName]) {
                    case ColumnType.Column:
                    default:
                        return 'glyph-columnview';
                    case ColumnType.Field:
                        return 'glyph-datafield';
                }
            case ServerType.PrimaryKey:
                return 'glyph-pk';
            case ServerType.ForeignKey:
                return 'glyph-fk';
            case ServerType.DataProcessing:
                switch (DataProcessingType[subTypeName]) {
                    case DataProcessingType.DataProcessing:
                    default:
                        return 'glyph-dataprocessing';
                    case DataProcessingType.DataFlow:
                        return 'glyph-data-flow';
                }
            case ServerType.DataProcessingItem:
                return 'glyph-processItem';
            case ServerType.SoftwareElement:
                switch (SoftwareElementType[subTypeName]) {
                    case SoftwareElementType.Application:
                        return 'glyph-software-application';
                    case SoftwareElementType.Dashboard:
                        return 'glyph-software-dashboard';
                    case SoftwareElementType.Screen:
                        return 'glyph-software-screen';
                    case SoftwareElementType.Usage:
                    default:
                        return 'glyph-software';
                    case SoftwareElementType.DataSet:
                        return 'glyph-dataset';
                    case SoftwareElementType.OpenDataSet:
                        return 'glyph-opendataset';
                    case SoftwareElementType.Process:
                        return 'glyph-process';
                    case SoftwareElementType.Report:
                        return 'glyph-report';
                    case SoftwareElementType.Algorithm:
                        return 'glyph-algorithm';
                    case SoftwareElementType.Feature:
                        return 'glyph-features';
                    case SoftwareElementType.UsageField:
                        return 'glyph-usage-field';
                    case SoftwareElementType.UsageComponent:
                        return 'glyph-usage-component';
                }
            case ServerType.Property:
                switch (PropertyType[subTypeName]) {
                    case PropertyType.Concept:
                        return 'glyph-concept';
                    case PropertyType.BusinessTerm:
                        return 'glyph-term';
                    case PropertyType.Indicator:
                        return 'glyph-indicator';
                    case PropertyType.ReferenceData:
                        return 'glyph-reference-data';
                    case PropertyType.Dimension:
                        return 'glyph-dimension';
                    case PropertyType.BusinessDomain:
                        return 'glyph-domain';
                    case PropertyType.Universe:
                        return 'glyph-universe';
                    case PropertyType.IndicatorGroup:
                        return 'glyph-indicator-group';
                    case PropertyType.DimensionGroup:
                        return 'glyph-dimension-group';
                    case PropertyType.BusinessDomainGroup:
                        return 'glyph-domain-group';
                    case PropertyType.ReferenceDataValue:
                        return 'glyph-value';
                    default:
                        return 'glyph-glossary';
                }
            case ServerType.ClientTagReference:
                return 'glyph-domain1';
            case ServerType.ClientPersonReference:
            case ServerType.User:
                return 'glyph-user-unlock';
            case ServerType.Person:
                return 'glyph-profile';
            case ServerType.AllTypes:
                return 'glyph-cdp';
            case ServerType.FilteredView:
                return 'glyph-filter-empty';
            case ServerType.Diagram:
                return 'glyph-diagram1';
            case ServerType.DiagramNode:
                return 'glyph-note';
            default:
                return '';
        }
    }

    public static getHddDataGlyph(hdd: HierarchyDataDescriptor) {
        return (
            hdd &&
            GlyphUtil.getServerTypeGlyphClass(
                hdd.DataServerType,
                hdd.SubTypeName,
            )
        );
    }
    public static getHdDataGlyph(hd: IHierarchicalData) {
        return (
            hd &&
            GlyphUtil.getServerTypeGlyphClass(
                hd.DataServerType,
                EntityType[hd.EntityType],
            )
        );
    }

    public static getEntityDataGlyph(entity: EntityItem) {
        return (
            entity &&
            GlyphUtil.getServerTypeGlyphClass(
                entity.ServerType,
                entity.SubTypeName,
            )
        );
    }

    //#endregion - serverType/subtype

    //#region entityTypes

    public static getEntityTypeColoredGlyphClass(type: EntityType) {
        return type
            ? `${GlyphUtil.getEntityTypeColorClass(
                  type,
              )} ${GlyphUtil.getEntityTypeGlyphClass(type)}`
            : '';
    }

    public static getEntityTypeColorClass(type: EntityType) {
        const module = DataUtil.getModuleFromEntityType(type);
        return GlyphUtil.getColorClassFromModule(module);
    }

    public static getEntityTypeGlyphClass(entityType: EntityType) {
        if (entityType == EntityType.ForeignKey) {
            return GlyphUtil.getServerTypeGlyphClass(ServerType.ForeignKey);
        }
        const mapping = EntityTypeUtil.getMapping(entityType);
        return mapping
            ? GlyphUtil.getServerTypeGlyphClass(
                  mapping.serverType,
                  mapping.SubTypeName,
              )
            : '';
    }

    public static getEntityTypeColor(type: EntityType) {
        switch (type) {
            case EntityType.BusinessDomain:
                return 'hsl(274, 10%, 32%)';
            case EntityType.BusinessDomainGroup:
                return 'hsl(274, 86%, 32%)';
            case EntityType.ReferenceData:
                return 'hsl(334, 92%, 41%)';
            case EntityType.ReferenceDataValue:
                return 'hsl(334, 40%, 31%)';
            case EntityType.Concept:
                return 'hsl(15, 86%, 54%)';
            case EntityType.BusinessTerm:
                return 'hsl(83, 83%, 42%)';
            case EntityType.Indicator:
                return 'hsl(173, 20%, 35%)';
            case EntityType.Universe:
                return 'hsl(200, 100%, 40%)';
            case EntityType.Dimension:
                return 'hsl(241, 40%, 28%)';
            case EntityType.DimensionGroup:
                return 'hsl(241, 80%, 28%)';
            case EntityType.IndicatorGroup:
                return 'hsl(173, 100%, 35%)';
            case EntityType.Use:
                return 'hsl(15, 86%, 54%)';
            case EntityType.Application:
                return 'hsl(200, 100%, 40%)';
            case EntityType.Screen:
                return 'hsl(83, 83%, 42%)';
            case EntityType.Dashboard:
                return 'hsl(173, 100%, 35%)';
            case EntityType.DataSet:
                return 'hsl(274, 86%, 32%)';
            case EntityType.OpenDataSet:
                return 'hsl(334, 92%, 41%)';
            case EntityType.Report:
                return 'hsl(241, 80%, 28%)';
            case EntityType.Algorithm:
                return 'hsla(62, 100%, 46%, 0.84)';
            case EntityType.Process:
                return 'hsl(940, 80%, 28%)';
            case EntityType.DataProcessing:
                return 'hsl(200, 100%, 40%)';
            case EntityType.DataFlow:
                return 'hsl(83, 83%, 42%)';
            case EntityType.Feature:
                return 'hsl(40, 83%, 42%)';
            case EntityType.UsageField:
                return 'hsl(200, 80%, 65%)';
            case EntityType.Model:
            case EntityType.RelationalModel:
            case EntityType.NonRelationalModel:
            case EntityType.NoSqlModel:
            case EntityType.Directory:
            case EntityType.TagBase:
            case EntityType.Table:
            case EntityType.File:
            case EntityType.Document:
            case EntityType.Column:
            case EntityType.Field:
            case EntityType.Tag:
            default:
                return 'hsl(274, 10%, 32%)';
        }
    }

    public static getEntityTypeGlyphChar(entityType: EntityType) {
        // from: src/core/assets/fonts/datagalaxy/variables.scss

        switch (entityType) {
            case EntityType.BusinessTerm:
                // @glyph-term: "\ea37";
                return '\uea37';

            case EntityType.Concept:
                // @glyph-concept: "\e942";
                return '\ue942';

            case EntityType.IndicatorGroup:
                // @glyph-indicator-group: "\e9b3";
                return '\ue9b3';

            case EntityType.Indicator:
                // @glyph-indicator: "\e9b4";
                return '\ue9b4';

            case EntityType.ReferenceData:
                // @glyph-reference-data: "\ea06";
                return '\uea06';

            case EntityType.DataFlow:
                // @glyph-data-flow: "\e948";
                return '\ue948';

            case EntityType.DataProcessing:
                // @glyph-dataprocessing: "\e94b";
                return '\ue94b';

            case EntityType.Use:
                // @glyph-software: "\ea27";
                return '\uea27';

            case EntityType.DataSet:
                // @glyph-dataset: "\e94c";
                return '\ue94c';

            case EntityType.OpenDataSet:
                // @glyph-opendataset: "\e94c";
                return '\ue94c';

            case EntityType.Process:
                // @glyph-process: "\e9fd";
                return '\ue9fd';

            case EntityType.Report:
                // @glyph-report: "\e984";
                return '\ue984';

            case EntityType.Algorithm:
                // @glyph-algorithm: "\e907";
                return '\ue907';

            case EntityType.Application:
                // @glyph-software-application: "\ea24";
                return '\uea24';

            case EntityType.Screen:
                // @glyph-software-screen: "\ea26";
                return '\uea26';

            case EntityType.Dashboard:
                // @glyph-software-dashboard: "\ea25";
                return '\uea25';

            case EntityType.Feature:
                // @glyph-features: "\e96f";
                return '\ue96f';

            case EntityType.UsageField:
                // @glyph-usage-field: "\ea48";
                return '\uea48';

            case EntityType.UsageComponent:
                // @glyph-usage-component: "\ea47";
                return '\uea47';

            case EntityType.Table:
                // @glyph-table: "\e98a";
                return '\ue98a';

            case EntityType.Column:
                // @glyph-columnview: "\e981";
                return '\ue981';

            case EntityType.NonRelationalModel:
                // @glyph-filestore: "\e97c";
                return '\ue97c';

            case EntityType.RelationalModel:
                // @glyph-database: "\e949";
                return '\ue949';

            case EntityType.Dimension:
                // @glyph-dimension: "\e95c";
                return '\ue95c';

            case EntityType.DimensionGroup:
                // @glyph-dimension-group: "\e95c";
                return '\ue95c';

            case EntityType.File:
                // @glyph-file: "\e97a";
                return '\ue97a';

            case EntityType.Field:
                // @glyph-datafield: "\e982";
                return '\ue982';

            case EntityType.NoSqlModel:
                // @glyph-nosql: "\e9e1";
                return '\ue9e1';

            case EntityType.Document:
                // @glyph-document: "\e989";
                return '\ue989';

            case EntityType.BusinessDomain:
                // @glyph-domain: "\ea2f";
                return '\uea2f';

            case EntityType.BusinessDomainGroup:
                // @glyph-domain-group: "\ea2f";
                return '\uea2f';

            case EntityType.Universe:
                // @glyph-universe: "\ea40";
                return '\uea40';

            case EntityType.Model:
                // @glyph-model: "\e969";
                return '\ue969';

            case EntityType.Directory:
                // @glyph-containerfolder: "\e944";
                return '\ue944';

            case EntityType.Equipment:
                // @glyph-equipment: "\e968";
                return '\ue968';

            case EntityType.SubStructure:
                // @glyph-sub-structure: "\ea30";
                return '\uea30';

            case EntityType.TagBase:
                // @glyph-tagbase: "\ea33";
                return '\uea33';

            case EntityType.Tag:
                // @glyph-tag: "\e98b";
                return '\ue98b';

            case EntityType.View:
                // @glyph-view: "\ea53";
                return '\uea53';

            case EntityType.DataProcessingItem:
                // @glyph-processItem: "\e9fe";
                return '\ue9fe';

            case EntityType.ReferenceDataValue:
                // @glyph-value: "\ea4a";
                return '\uea4a';

            case EntityType.PhysicalDiagram:
                // @glyph-diagram: "\e988";
                return '\ue988';
            case EntityType.FreeDiagram:
                // $glyph-diagram1: "\e95b";
                return '\ue95b';

            case EntityType.DiagramNode:
                // @glyph-note: "\e9e3";
                return '\ue9e3';

            case EntityType.PrimaryKey:
                // @glyph-pk: "\e9f3";
                return '\ue9f3';

            case EntityType.ForeignKey:
                // @glyph-fk: "\e97f";
                return '\ue97f';

            case EntityType.FilteredView:
                // @glyph-filter-empty: "\e97d";
                return '\ue97d';

            default:
                if (!environment.production) {
                    console.warn('not implemented', EntityType[entityType]);
                }
                return '?';
        }
    }

    //#endregion - entityTypes

    //#region entity status
    public static getStatusGlyphClass(status: EntityLifecycleStatus): string {
        switch (status) {
            case EntityLifecycleStatus.InRevision:
                return 'glyph-inrevision';
            case EntityLifecycleStatus.InValidation:
                return 'glyph-invalidation';
            case EntityLifecycleStatus.Obsolete:
                return 'glyph-obsolete';
            case EntityLifecycleStatus.Proposed:
                return 'glyph-proposed';
            case EntityLifecycleStatus.Validated:
                return 'glyph-validated';
            default:
                return '';
        }
    }
    //#endregion
    // #region data quality
    public static getDataQualityGlyphClass(status: DataQualityResult): string {
        switch (status) {
            case DataQualityResult.Passed:
                return 'dxy-dq-glyph glyph-check-circle-filled';
            case DataQualityResult.Failed:
                return 'dxy-dq-glyph glyph-alert-critical';
            case DataQualityResult.Warning:
                return 'dxy-dq-glyph glyph-alert-warning';
            default:
                return 'dxy-dq-glyph glyph-alert-unknown';
        }
    }
    //#endregion

    //#region Space
    public static getSpaceSecurityProfileGlyphClass(
        securityProfileType: SpaceSecurityProfileType,
    ) {
        switch (securityProfileType) {
            case SpaceSecurityProfileType.Private:
                return 'glyph-lock';
            case SpaceSecurityProfileType.Limited:
                return 'glyph-user-unlock';
            case SpaceSecurityProfileType.Open:
                return 'glyph-person';
        }
    }
    //#endregion
}
