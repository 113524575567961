import { InjectionToken } from '@angular/core';

export interface LanguageOptions {
    /**
     * List of supported locales in the application
     * It should be in the format of language code
     * @default ['en']
     */
    locales: string[];
    /**
     * Default language to use when no language is set
     * @default 'en'
     */
    defaultLanguage: string;
    /**
     * Whether to use the default browser language when no language is set
     * It will be used only if it is part of the supported locales
     * @default true
     */
    useDefaultBrowserLanguage?: boolean;
    /**
     * Callback function to be called when the language is changed
     * It is convenient to set the lang for 3rd party libraries
     * @param lang
     */
    onChange?: (lang: string) => void;
}

export const LANGUAGE_OPTIONS = new InjectionToken<LanguageOptions>(
    'LANGUAGE_OPTIONS',
    {
        providedIn: 'root',
        factory: () => ({
            locales: ['en'],
            defaultLanguage: 'en',
            useDefaultBrowserLanguage: true,
        }),
    },
);
