import { EventEmitter, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { TranslationApiService } from '@datagalaxy/webclient/multilingual/data-access';
import {
    AttributeValueTranslation,
    TranslationOrigin,
    TranslationStatus,
} from '@datagalaxy/webclient/multilingual/domain';
import { IEntityIdentifier } from '@datagalaxy/dg-object-model';
import { EntityTranslationStore } from './entity-translation-store.service';
import { MultilingualStateService } from '../multilingual-state.service';
import {
    AttributeValueTranslationResult,
    mapAttributeValueTranslationToResult,
} from './attribute-value-translation-result';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { AttributeTranslationRegenerationService } from './attribute-translation-regeneration.service';
import { RichTextContent } from '@datagalaxy/core-ui';
import { LanguageService } from '@datagalaxy/webclient/translate';
import { CoreUtil } from '@datagalaxy/core-util';

@Injectable({ providedIn: 'root' })
export class AttributeValueTranslationService {
    public attributeTranslatedToSourceLanguage = new EventEmitter<{
        entity: EntityItem;
        attributePath: string;
        updatedValue: string;
    }>();
    public get userUsesClientSpaceSourceLanguage() {
        return (
            this.currentUserLanguageCode ===
            this.multilingualStateService.state?.settings?.sourceLanguageCode
        );
    }

    private get multilingualEnabled() {
        return this.multilingualStateService.state.settings?.enabled ?? false;
    }
    private get currentUserLanguageCode() {
        return this.languageService?.getLanguage() ?? 'en';
    }

    constructor(
        private languageService: LanguageService,
        private multilingualStateService: MultilingualStateService,
        private translationApiService: TranslationApiService,
        private attributeTranslationRegenerationService: AttributeTranslationRegenerationService,
        private store: EntityTranslationStore,
    ) {}

    public getAttributeValueTranslationResultFromEntityItem$(
        entity: EntityItem,
        attributePath: string,
    ): Observable<AttributeValueTranslationResult> {
        const attributeKey = attributePath.split('.').pop() ?? '';
        const sourceValue = entity.getAttributeValue(attributeKey) as string;

        return this.getAttributeValueTranslationResult$(
            entity,
            attributePath,
            sourceValue,
        );
    }

    public getAttributeValueTranslationResult$(
        entity: IEntityIdentifier,
        attributePath: string,
        defaultValue: string,
    ): Observable<AttributeValueTranslationResult> {
        return this.store
            .selectObjectAttributeTranslation(
                entity,
                this.currentUserLanguageCode,
                attributePath,
            )
            .pipe(
                map((t) => {
                    if (
                        !t ||
                        this.metaModelValueNotEqualsTranslationValueInClientSpaceSourceLanguage(
                            t,
                            defaultValue,
                        )
                    ) {
                        const result: AttributeValueTranslationResult = {
                            value: defaultValue,
                        };
                        return result;
                    }
                    return mapAttributeValueTranslationToResult(
                        t,
                        defaultValue,
                    );
                }),
            );
    }

    private metaModelValueNotEqualsTranslationValueInClientSpaceSourceLanguage(
        t: AttributeValueTranslation,
        defaultValue: string,
    ): boolean {
        return (
            RichTextContent.getRawText(t.value) !=
                RichTextContent.getRawText(defaultValue) &&
            this.currentUserLanguageCode ===
                this.multilingualStateService.state.settings.sourceLanguageCode
        );
    }

    public async updateAttributeTranslation(
        entity: EntityItem,
        attributePath: string,
        updatedValue: string,
        sourceLangValue?: string,
    ) {
        await this.translationApiService.updateObjectAttributeTranslation(
            entity,
            this.currentUserLanguageCode,
            {
                attributePath: attributePath,
                origin: TranslationOrigin.Human,
                value: updatedValue,
                status: TranslationStatus.Ok,
            },
        );
        if (this.userUsesClientSpaceSourceLanguage) {
            const updatedEntity = CoreUtil.clone(entity);
            updatedEntity.Attributes = CoreUtil.clone(entity.Attributes);
            this.attributeTranslatedToSourceLanguage.emit({
                entity: updatedEntity,
                attributePath,
                updatedValue,
            });
        }
        this.refreshStoredObjectTranslation(entity);

        if (!updatedValue) {
            this.attributeTranslationRegenerationService.askForDeletion(
                entity,
                attributePath,
                sourceLangValue,
            );
            return;
        }
        this.attributeTranslationRegenerationService.askForRegeneration(
            entity,
            attributePath,
            sourceLangValue,
        );
    }

    public displayTranslatedValues(attributePath: string | undefined) {
        if (!attributePath) {
            return false;
        }
        return (
            this.multilingualEnabled &&
            this.multilingualStateService.state.settings?.attributes?.some(
                (a) => a.attributePath === attributePath && a.enabled,
            )
        );
    }

    private async refreshStoredObjectTranslation(
        entityIdentifier: IEntityIdentifier,
    ) {
        const result =
            await this.translationApiService.getObjectTranslationForLanguage(
                entityIdentifier,
                this.currentUserLanguageCode,
            );
        this.store.upsertObjectTranslation(result);
    }
}
