<mat-radio-group
    #matRadioGroup
    [value]="value"
    (change)="onRadioChange($event)"
    [disabled]="disabled"
>
    <mat-radio-button
        *ngFor="let o of options"
        [value]="o.value"
        [disabled]="o.disabled"
        [dxyTooltip]="o.tooltipKey | translate"
        dxyDataTestId
        [dxyDataTestIdValue]="o.value"
        dxyTooltipPosition="below"
    >
        <div class="label-wrapper" [ngClass]="o.class">
            <ng-container *ngTemplateOutlet="o.templateRefLabel"></ng-container>
            <span
                *ngIf="o.glyphClass"
                class="glyph"
                [ngClass]="o.glyphClass"
            ></span>
            <span *ngIf="o.label"> {{ o.label }} </span>
            <span *ngIf="o.labelKey" [translate]="o.labelKey"></span>
        </div>
    </mat-radio-button>
</mat-radio-group>
