import { Injectable } from '@angular/core';
import {
    EntityCreatedEvent,
    EntityCreationOrigin,
    EntityCreator,
} from '@datagalaxy/webclient/entity/feature';
import { MultilingualStateService } from '../multilingual-state.service';
import { TranslationApiService } from '@datagalaxy/webclient/multilingual/data-access';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import {
    AttributeValueTranslation,
    TranslationOrigin,
    TranslationStatus,
} from '@datagalaxy/webclient/multilingual/domain';
import { filter } from 'rxjs';
import { LanguageService } from '@datagalaxy/webclient/translate';

/***
 * This service is responsible for translating the entity that was created.
 * It will be removed when a new entity creation route is implemented in the modular monolith.
 * It's a workaround to not having to modify in depth the entity creation process.
 */
@Injectable({ providedIn: 'root' })
export class EntityCreationTranslationService {
    private get shouldDoTranslation() {
        return (
            this.stateService.getEnabled() &&
            this.stateService.state.settings.targetLanguages
                ?.map((t) => t.languageCode)
                .includes(this.languageService.getLanguage() ?? '')
        );
    }

    constructor(
        entityCreator: EntityCreator,
        private stateService: MultilingualStateService,
        private languageService: LanguageService,
        private translationApiService: TranslationApiService,
    ) {
        entityCreator.entityCreatedEvent
            .pipe(
                filter(
                    (e) =>
                        !EntityCreationOrigin[
                            e.context.origin ?? EntityCreationOrigin.unknown
                        ].includes('Clone'),
                ),
            )
            .subscribe((entity) => {
                this.translateCreatedEntity(entity);
            });
    }
    private async translateCreatedEntity(
        entityCreatedEvent: EntityCreatedEvent,
    ) {
        if (!this.shouldDoTranslation) {
            return;
        }
        await this.translateDisplayName(entityCreatedEvent.entity);
        await this.translateSummary(entityCreatedEvent.entity);
    }

    private async translateDisplayName(entity: EntityItem) {
        const attributePath = 'DisplayName';
        if (!this.shouldTranslateAttribute(attributePath)) {
            return;
        }
        await this.translateAttribute(
            entity,
            attributePath,
            entity.DisplayName,
        );
    }

    private async translateSummary(entity: EntityItem) {
        const attributePath = 'Description';
        if (!this.shouldTranslateAttribute(attributePath)) {
            return;
        }
        const attributeKey = attributePath;
        const value = entity.getAttributeValue(attributeKey) as string;
        if (!value) {
            return;
        }
        await this.translateAttribute(entity, attributeKey, value);
    }

    private async translateAttribute(
        entity: EntityItem,
        attributePath: string,
        value: string,
    ) {
        const translation: AttributeValueTranslation = {
            attributePath,
            origin: TranslationOrigin.Human,
            status: TranslationStatus.Ok,
            value,
        };

        await this.translationApiService.updateObjectAttributeTranslation(
            entity,
            this.languageService.getLanguage() ?? '',
            translation,
        );
        await this.translationApiService.regenerateAttributeTranslations({
            from: this.languageService.getLanguage() ?? '',
            attributePath,
            entityIdentifier: entity,
        });
    }

    private shouldTranslateAttribute(attributePath: string) {
        return this.stateService.state.settings.attributes?.some(
            (a) => a.attributePath === attributePath && a.enabled,
        );
    }
}
