import { deserialize, deserializeAs, inheritSerialization } from 'cerialize';
import {
    BaseMasterData,
    IHas$type,
    IHasReferenceId,
    IIsData,
    ITypeMetadata,
} from '../master-data';
import { CoreUtil } from '@datagalaxy/core-util';
import { CDataType, DataTypeRef, IDataType } from '../data-type';
import { ServerType } from '@datagalaxy/dg-object-model';
import { asRefs, CRefData, IRef, subRef } from '../ref';

/** System-wide, or client-company configurations of DDL data types. */
export interface IDataTypeSettings extends IHasReferenceId {
    Types: IRef<IDataType>[];
}

/** DataTypeSettings legacy generic format adapter. For LoadSystemDataResult.SystemDataTypeSettings and ClientLoginResult.DataTypeSettings */
@inheritSerialization(CRefData)
export class CDataTypeSettings
    extends CRefData<DataTypeSettings>
    implements IDataTypeSettings, IIsData
{
    readonly ctorType = DataTypeSettings;
    readonly serverType = ServerType.DataTypeSettings;
    @deserializeAs(subRef(CDataType), 'Types') private cTypes!: CDataType[];

    //#region IDataTypeSettings
    get Types() {
        return asRefs(this.cTypes);
    }

    //#endregion
    asData() {
        return DataTypeSettings.from(this);
    }

    protected getProducers() {
        return [...this.cTypes];
    }
}

@inheritSerialization(BaseMasterData)
export class DataTypeSettings
    extends BaseMasterData
    implements IDataTypeSettings
{
    static readonly tmd: ITypeMetadata = {
        parentPropertyName: 'ClientDataTypeSettingsRef',
    };
    static readonly keys: (keyof IDataTypeSettings)[] = ['Types'];

    @deserialize public Types: DataTypeRef[] = [];

    public readonly ServerType = ServerType.DataTypeSettings;

    static from(o: IDataTypeSettings & Partial<IHas$type>) {
        return CoreUtil.buildPartial(
            DataTypeSettings,
            o,
            DataTypeSettings.keys,
            o?.ReferenceId,
            o?.$type,
        );
    }

    constructor(id?: string, $type?: string) {
        super(id, $type);
    }
}
