<ng-container *ngIf="!showRenderer">
    <div *ngIf="isTagData" class="option-item-tag">
        <dxy-color-point-cell
            [class.large]="!isMini"
            [color]="option.tagColor"
        ></dxy-color-point-cell>
    </div>

    <span *ngIf="isIconData" class="img-container" [ngClass]="iconClass">
        <img *ngIf="iconUrl" [src]="iconUrl" class="img-wrapper" />
        <span *ngIf="!iconUrl" class="img-wrapper placeholder"></span>
    </span>

    <div
        *ngIf="isGlyphVisible"
        class="option-item-glyph"
        [class.rich-layout-option]="hasRichLayout"
        [class.only-glyph]="!hasText"
    >
        <span
            class="glyph"
            [ngClass]="glyphClass"
            [class.rich-layout-glyph]="hasRichLayout"
            [style.color]="option.glyphColor"
        ></span>
    </div>

    <div
        *ngIf="showText"
        class="option-item-text"
        [class.rich-layout-option]="hasRichLayout"
    >
        <span
            *ngIf="hasText"
            class="object-name"
            [dxyEllipsisTooltip]="textTooltip"
            [dxyEllipsisTooltipPosition]="tooltipPosition"
            >{{ text }}</span
        >
        <span *ngIf="showEmptyText" class="object-name" translate
            >UI.Components.MultiSelect.empty</span
        >
        <span
            *ngIf="hasDescription"
            class="object-description"
            dxyEllipsisTooltip
            >{{ description }}</span
        >
    </div>

    <div *ngIf="hintGlyphClass" class="option-item-hint-glyph">
        <i
            [ngClass]="hintGlyphClass"
            [dxyTooltip]="hintGlyphTooltip"
            [dxyTooltipPosition]="tooltipPosition"
        ></i>
    </div>

    <span *ngIf="isHtmlContent" class="dynamic-content"></span>
</ng-container>

<dxy-renderer
    *ngIf="showRenderer"
    [isMini]="isMini"
    [renderer]="option.renderData?.renderer"
    [data]="data"
    [param]="option.renderData?.param"
    [forceUpdateDataOnChange]="true"
></dxy-renderer>
